function sortOrders(orders) {
  return orders.sort((a, b) => {
    if (a.priority === 'high' && b.priority === 'high') {
      return a.date_created - b.date_created
    }

    if (a.priority === 'high') {
      return -1
    }
    if (b.priority === 'high') {
      return 1
    }

    if (a.delivery_date && b.delivery_date) {
      return a.delivery_date - b.delivery_date
    }

    return a.date_created - b.date_created
  })
}

const mutations = {
  setOrders(state, orders) {
    orders.forEach(order => { order.show = true })
    state.orders = sortOrders(orders)
  },

  updateOrder(state, newOrder) {
    newOrder.show = true

    const index = state.orders.findIndex(order => order.id === newOrder.id)

    if (index >= 0) {
      newOrder.vendors = newOrder.vendors || state.orders[index].vendors

      if (!('accounting_success' in newOrder)) {
        if (newOrder.hasPurchaseOrder && 'accounting_success' in newOrder.pos[0].latest_change_order) {
          newOrder.accounting_success = newOrder.pos[0].latest_change_order.accounting_success
        }
        else {
          newOrder.accounting_success = state.orders[index].accounting_success
        }
      }

      state.orders[index] = newOrder

      if (newOrder.priority === 'high') {
        state.orders = sortOrders(state.orders)
      }
    }
    else if (!state.fetchingOrders) {
      const orders = state.orders
      orders.push(newOrder)
      state.orders = sortOrders(orders)
    }

    if (state.activeOrder && state.activeOrder.id === newOrder.id) {
      state.activeOrder = newOrder
    }
  },

  removeOrder(state, removedOrder) {
    state.orders = state.orders.filter(order => order.id !== removedOrder.id)
  },

  updateChangedOrders(state, orders) {
    const stateOrders = state.orders.filter(order1 => !orders.some(order2 => order1.id === order2.id))
    const updatedOrders = orders.filter(order => order.isArchived === false)
    updatedOrders.forEach(order => { order.show = true })
    state.orders = sortOrders(stateOrders.concat(updatedOrders))
  },

  activateOrder(state, order) {
    state.activeOrder = order
  },

  addOrder(state, order) {
    order.show = true

    const orders = state.orders
    orders.push(order)
    state.orders = sortOrders(orders)
  },

  pushOrders(state, orders) {
    orders.forEach(order => { order.show = true })
    state.orders = sortOrders(state.orders.concat(orders))
  },

  setUnassociatedEmails(state, emails) {
    state.unassociatedEmails = emails
  },

  removeUnassociatedEmail(state, emailToRemove) {
    state.unassociatedEmails = state.unassociatedEmails.filter(email => email.id !== emailToRemove.id)
  },

  setUnreadEmails(state, emails) {
    state.unreadEmails = emails
  },

  setUnreadGeneralInboxEmails(state, emails) {
    state.unreadGeneralInboxEmails = emails
  },

  removeUnreadEmail(state, emailToRemove) {
    if (emailToRemove.is_general_inbox) {
      state.unreadGeneralInboxEmails = state.unreadGeneralInboxEmails.filter(email => email.id !== emailToRemove.id)
    }
    else {
      state.unreadEmails = state.unreadEmails.filter(email => email.id !== emailToRemove.id)
    }
  },

  removeUnreadGeneralInboxEmail(state, emailToRemove) {
    state.unreadGeneralInboxEmails = state.unreadGeneralInboxEmails.filter(email => email.id !== emailToRemove.id)
  },

  setBouncedEmails(state, emails) {
    state.bouncedEmails = emails
  },

  removeBouncedEmail(state, emailToRemove) {
    state.bouncedEmails = state.bouncedEmails.filter(email => email.id !== emailToRemove.id)
  },

  markEmailsFetched(state) {
    state.emailsFetched = true
  },

  markOrdersFetched(state) {
    state.ordersFetched = true
  },

  setFetchingOrders(state, value) {
    state.fetchingOrders = value
  },

  setQmails(state, qmails) {
    state.qmails = qmails
  },

  setQmailsQuery(state, query) {
    state.qmailsQuery[query.name] = query.value
  },

  setOrderFetchCount(state, count) {
    state.orderFetchCount = count
  },

  showGlobalEmailViewer(state, email) {
    state.globalEmailViewer = { show: true, email }
  },

  hideGlobalEmailViewer(state) {
    state.globalEmailViewer = { show: false, email: null }
  },

  addAttachmentBoundingBox(state, boundingBox) {
    state.attachmentBoundingBoxes.push(boundingBox)
  },

  removeAttachmentBoundingBox(state, boundingBox) {
    state.attachmentBoundingBoxes = state.attachmentBoundingBoxes.filter(bb => (
      bb.position.Top !== boundingBox.position.Top
      && bb.position.Left !== boundingBox.position.Left
      && bb.position.Height !== boundingBox.position.Height
      && bb.position.Width !== boundingBox.position.Width
    ))
  },
}

export default mutations
