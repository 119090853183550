<template>
  <div>
    <qtm-input-label label="User who cancels the order" required>
      <qtm-select
        v-model="cancelledBy"
        dense
        :item-title="teamMemberName"
        :items="projectTeam"
        :loading="loading"
        persistent-hint
        @update:model-value="$emit('update-action', { cancelledBy: $event })"
      />
    </qtm-input-label>

    <qtm-input-label label="Why is the order being cancelled?">
      <v-radio-group
        v-model="cancellationReason"
        hide-details
        @update:model-value="$emit('update-action', { cancellationReason: $event })"
      >
        <qtm-radio
          v-for="reason in order.cancellationReasons"
          :key="reason"
          :label="reason"
          :model-value="reason"
          data-test="cancel-reason"
        />
      </v-radio-group>
    </qtm-input-label>

    <qtm-text-field
      v-if="cancellationReason === 'Other'"
      v-model="otherCancellationReason"
      class="mt-1"
      data-test="cancellation-reason-other"
      dense
      hide-details
      placeholder="Please enter your other reason"
      :maxlength="cancellationReasonMaxLength"
      @update:model-value="$emit('update-action', { cancellationReason: $event })"
    />

    <qtm-input-label label="Additional information" class="mt-4">
      <qtm-textarea
        v-model="cancellationMessage"
        auto-grow
        rows="2"
        hide-details
        placeholder="Leave a note for your team and your supplier..."
        data-test="cancellation-message"
        @update:model-value="$emit('update-action', { cancellationMessage: $event })"
      />
    </qtm-input-label>
  </div>
</template>

<script>
import { CANCELLATION_REASON_MAX_LENGTH } from '@/constants'

export default {
  name: 'admin-cancel-order-dialog',
  components: { },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['update-action'],
  data() {
    return {
      loading: true,
      projectTeam: [],
      cancellationMessage: '',
      cancellationReason: null,
      cancellationReasonMaxLength: CANCELLATION_REASON_MAX_LENGTH,
      cancelledBy: null,
      otherCancellationReason: null
    }
  },
  computed: {},
  async mounted() {
    this.loading = true
    try {
      this.projectTeam = await this.$api.v1.users.list({ jobsite: this.order.jobsite.id })
    }
    catch (error) {
      this.$error.report(error)
    }
    if (!this.projectTeam.some(member => member.id === this.order.owner.id)) {
      this.projectTeam.push(this.order.owner)
    }
    this.cancelledBy = this.order.owner.id
    this.$emit('update-action', { cancelledBy: this.cancelledBy })
    this.loading = false
  },
  methods: {
    teamMemberName(member) {
      let user = member

      if (typeof user === 'number') {
        user = this.projectTeam.find(m => m.id === member)
      }

      if (!user) {
        return ''
      }

      return `${user.first_name} ${user.last_name}`
    }
  }
}
</script>
