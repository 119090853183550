<template>
  <div v-if="!order.quotes.length">
    <v-card outlined class="pa-3">
      <qtm-input-label v-if="emailAttachments.length" label="Select attachments">
        <p class="text-caption">
          The selected attachments will be duplicated and associated to the
          {{ order.is_invoice_approval ? 'invoice' : 'quote' }}.
        </p>
        <order-attachments
          v-model="emailAttachments"
          v-model:selected-attachments="selectedAttachments"
          allow-preview
          allow-select
          no-upload
          no-delete
          remove-title
          @preview="$emit('preview', $event)"
        />
      </qtm-input-label>

      <qtm-input-label label="Search Suppliers">
        <supplier-search
          hint="Add a supplier to edit the order"
          persistent-hint
          style="max-width: 500px"
          @update:model-value="addVendor"
        />
      </qtm-input-label>
      <qtm-btn class="mt-5" :loading="loading" secondary @click="showCreateVendorDialog = true">
        <v-icon>mdi-plus</v-icon>
        Supplier
      </qtm-btn>
    </v-card>
    <create-vendor-dialog
      v-model="showCreateVendorDialog"
      contact-details-required
      vendor-details-required
      with-contact
      @vendor-created="addVendor"
    />
  </div>
  <quote-order-form
    v-else-if="localQuote && localOrder"
    ref="quoteOrderForm"
    :quote="localQuote"
    :order="localOrder"
    @preview="$emit('preview', $event)"
  />
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog'
import OrderAttachments from '@/components/orders/order-attachments'
import QuoteOrderForm from '@/components/quotes/quote-order-form'
import SupplierSearch from '@/components/search/supplier-search'

export default {
  name: 'edit-rfq-quote',
  components: { CreateVendorDialog, OrderAttachments, QuoteOrderForm, SupplierSearch },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['preview'],
  data() {
    return {
      emailAttachments: [],
      loading: false,
      localQuote: undefined,
      localOrder: undefined,
      selectedAttachments: [],
      showCreateVendorDialog: false,
    }
  },
  mounted() {
    if (this.order.quotes.length) {
      this.cloneObjects()
    }
    else {
      this.fetchEmailAttachments()
    }
  },
  methods: {
    async addVendor(vendor) {
      this.loading = true
      const vendorData = { vendor: vendor.id, contact: vendor.contact, attachments: this.selectedAttachments }

      try {
        const updatedOrder = await this.$api.v1.rfqs.vendors.add(this.order, vendorData)
        this.$store.commit('admin/updateOrder', updatedOrder)

        // Wait for the updated Order to propagate to the component
        await this.$nextTick()
        this.cloneObjects()
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    beforeTransition(action, abort) {
      if (this.$refs.quoteOrderForm && !this.order.cancelled) {
        if (!this.$refs.quoteOrderForm.isValid()) {
          abort()
          return undefined
        }
        return this.$refs.quoteOrderForm.submit(true)
      }
      return undefined
    },
    cloneObjects() {
      this.localOrder = cloneDeep(this.order)
      this.localQuote = this.localOrder.quotes.pop()
    },
    async fetchEmailAttachments() {
      this.loading = true
      try {
        const emails = await this.$api.v1.notifications.email.listByRfq(this.order, { emailType: 'attachments' })
        this.emailAttachments = emails.flatMap(email => email.attachments)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async refresh() {
      this.cloneObjects()

      if (this.$refs.quoteOrderForm) {
        // Wait for cloned objects to propagate before setting items
        await this.$nextTick()

        this.$refs.quoteOrderForm.setItems()
      }
    },
  }
}
</script>
