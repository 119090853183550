<template>
  <date-picker
    v-slot="{ dateString, dateValue, minDate }"
    v-bind="$attrs"
    ref="datePicker"
    v-model="localValue"
    :date-string-append="dateStringAppend"
    :format-string-append="formatStringAppend"
    hide-details="auto"
    :menu-props="menuProps"
    placeholder="Select date & time"
    @closed="cancel"
  >
    <div class="py-5 px-10 qtm-border bg-white overflow-y-auto">
      <expansion-block ref="dateBlock" button-color="interactive" color="white" padding-left="0">
        <template v-slot:title>
          <div class="d-flex align-center font-weight-bold text-interactive qtm-body">
            1
            <v-icon class="mx-2" color="interactive">
              mdi-calendar
            </v-icon>
            <span v-if="!dateString || localDeliveryTime === 'tbd'">
              Pick a Specific Date
            </span>
            <span v-else v-text="dateString" />
          </div>
        </template>
        <v-date-picker
          color="interactive"
          :min-date="minDate"
          :model-value="dateValue"
          @update:model-value="dateSelected"
        />
      </expansion-block>
      <expansion-block
        ref="timeBlock"
        button-color="interactive"
        class="mt-2"
        collapsed
        color="white"
        :disabled="!canSetTime"
        padding-left="0"
      >
        <template v-slot:title>
          <div class="d-flex align-center font-weight-bold text-interactive qtm-body">
            2
            <v-icon class="mx-2" color="interactive">
              mdi-clock-outline
            </v-icon>
            {{ timeString }}
          </div>
        </template>
        <order-delivery-time v-model="localValue" v-model:delivery-time="localDeliveryTime" :disabled="!canSetTime" />
      </expansion-block>
      <div class="my-4 separation-text">
        <span class="text-interactive">
          Or
        </span>
      </div>
      <div>
        <v-radio-group v-model="localDeliveryTime" hide-details @update:model-value="setDeliveryTBD">
          <qtm-radio :label="`I don't know the ${deliveryOrPickup} date yet`" value="tbd" />
        </v-radio-group>
      </div>
      <v-divider class="my-4" />
      <div class="text-right">
        <qtm-btn tertiary size="x-large" @click="cancel">
          Cancel
        </qtm-btn>
        <qtm-btn :disabled="!canApply" size="x-large" @click="apply">
          Apply
        </qtm-btn>
      </div>
    </div>
  </date-picker>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { DatePicker as VDatePicker } from 'v-calendar'
import DatePicker from '@/components/date-picker'
import ExpansionBlock from '@/components/expansion-block'
import OrderDeliveryTime from '@/components/orders/order-delivery-time'
import { DELIVERY_DATE_REQUIRED_VALUES } from '~/constants'

const timeStringFormat = 'h:mm A'

export default {
  name: 'order-delivery-date',
  components: { DatePicker, ExpansionBlock, OrderDeliveryTime, VDatePicker },
  props: {
    datePickerMenuProps: {
      type: Object,
      default: undefined
    },
    deliveryTime: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pickup: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      default: undefined
    },
  },
  emits: ['update:delivery-time', 'update:model-value'],
  data() {
    return {
      localDeliveryTime: this.deliveryTime,
      localValue: cloneDeep(this.modelValue),
    }
  },
  computed: {
    canApply() {
      return this.localDeliveryTime === 'tbd'
        || (this.localValue && DELIVERY_DATE_REQUIRED_VALUES.includes(this.localDeliveryTime))
    },
    canSetTime() {
      return !!this.localValue
    },
    dateStringAppend() {
      if (this.localValue && (this.localDeliveryTime === 'am' || this.localDeliveryTime === 'pm')) {
        return ` ${this.timeString}`
      }
      if (this.localDeliveryTime === 'tbd') {
        return "Don't know yet"
      }
      return ''
    },
    deliveryOrPickup() {
      return this.pickup ? 'pick-up' : 'delivery'
    },
    formatStringAppend() {
      if (this.deliveryTime === 'sp') {
        return `, ${timeStringFormat}`
      }
      return ''
    },
    menuProps() {
      return {
        closeOnContentClick: false,
        maxWidth: 525,
        minWidth: 525,
        ...this.datePickerMenuProps,
      }
    },
    timeString() {
      if (this.localValue) {
        if (this.localDeliveryTime === 'am') {
          return 'Morning'
        }

        if (this.localDeliveryTime === 'pm') {
          return 'Afternoon'
        }

        if (this.localDeliveryTime === 'sp') {
          return this.localValue.format(timeStringFormat)
        }
      }

      return 'Pick a Time'
    }
  },
  watch: {
    modelValue() {
      this.localValue = cloneDeep(this.modelValue)
    }
  },
  methods: {
    apply() {
      this.$emit('update:model-value', cloneDeep(this.localValue))
      this.$emit('update:delivery-time', this.localDeliveryTime)
      this.reset()
    },
    cancel() {
      this.localValue = cloneDeep(this.modelValue)
      this.localDeliveryTime = this.deliveryTime
      this.reset()
    },
    dateSelected(value) {
      this.$refs.datePicker.setValue(value)
      if (this.localDeliveryTime === 'tbd') {
        this.localDeliveryTime = ''
      }
      this.$refs.timeBlock.expand()
    },
    setDeliveryTBD() {
      this.localValue = null
      this.$refs.dateBlock.collapse()
      this.$refs.timeBlock.collapse()
    },
    reset() {
      this.$refs.datePicker.close()

      if (this.localDeliveryTime === 'tbd') {
        this.$refs.dateBlock.collapse()
      }
      else {
        this.$refs.dateBlock.expand()
      }

      if (!this.localValue) {
        this.$refs.timeBlock.collapse()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.separation-text {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
  text-align: center;
  height: 0.8em;
  margin-bottom: 0.5em;
}

.separation-text span {
  background: white;
  padding: 0 5px;
  font-weight: bold;
}
</style>
