<template>
  <qtm-dialog-card
    v-bind="$attrs"
    max-width="750"
    title="Change vendor"
    width="750"
    @update:model-value="close"
  >
    <p>Choose a supplier or create a new one</p>
    <v-row class="no-gutters">
      <v-col>
        <supplier-search :append-items="vendorItems" :model-value="vendor" @update:model-value="vendorSelected" />
      </v-col>
      <v-col cols="auto ml-2">
        <qtm-btn size="x-large" @click="showCreateVendorDialog = true">
          <v-icon>mdi-plus</v-icon>
        </qtm-btn>
      </v-col>
    </v-row>
    <create-vendor-dialog
      v-model="showCreateVendorDialog"
      vendor-details-required
      contact-details-required
      with-contact
      @vendor-created="vendorSelected"
    />
    <div v-if="vendor" class="mt-5">
      <p>Now, choose a contact or create a new one</p>
      <v-row class="no-gutters">
        <v-col>
          <vendor-contact-select
            :vendor="vendor"
            contact-details-required
            @new-contact="contactCreated"
          />
        </v-col>
        <v-col v-if="vendor.contact" cols="auto ml-2">
          <qtm-icon-btn icon="mdi-pencil" size="x-large" @click="openEditContactModal" />
        </v-col>
      </v-row>
    </div>
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" tertiary @click="close">
        Cancel
      </qtm-btn>
      <qtm-btn
        size="x-large"
        class="my-3"
        :loading="loading"
        :disabled="!vendorOrContactModified"
        @click="changeVendor"
      >
        Change vendor
      </qtm-btn>
    </template>
    <qtm-dialog-card
      v-model="showSendInvitationEmailModal"
      title="Send invitation email"
    >
      <p>
        Send the invitation email to the new vendor?
        (Email address: {{ currentContact ? currentContact.email : 'None' }})
      </p>
      <p v-if="quote.date_invited">
        An invitation email for this quote was sent on {{ dateYear(quote.date_invited) }}
      </p>
      <template v-slot:actions>
        <qtm-btn :loading="loading" tertiary @click="showSendInvitationEmailModal = false">
          Cancel
        </qtm-btn>
        <v-spacer />
        <qtm-btn :loading="loading" secondary @click="saveAndClose(false)">
          No
        </qtm-btn>
        <qtm-btn :loading="loading" @click="saveAndClose(true)">
          Yes
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <qtm-dialog-card
      v-model="showEditContactModal"
      max-width="600"
      title="Edit contact"
      width="600"
    >
      <contact-form ref="contactForm" :contact="contactCopy" :vendor="vendor" email-required phone-required />
      <template v-slot:actions>
        <qtm-btn :loading="loading" tertiary @click="showEditContactModal = false">
          Cancel
        </qtm-btn>
        <v-spacer />
        <qtm-btn :loading="loading" @click="patchContact">
          Save
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </qtm-dialog-card>
</template>

<script>
import { dateYear } from '~/models/filters'
import ContactForm from '@/components/vendors/contact-form'
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog'
import SupplierSearch from '@/components/search/supplier-search'
import VendorContactSelect from '@/components/vendors/vendor-contact-select'

export default {
  name: 'change-vendor-dialog',
  components: { ContactForm, CreateVendorDialog, SupplierSearch, VendorContactSelect },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    quote: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:model-value'],
  data() {
    return {
      contactCopy: null,
      loading: false,
      showCreateVendorDialog: false,
      showEditContactModal: false,
      showSendInvitationEmailModal: false,
      vendor: null,
      vendorItems: [],
    }
  },
  computed: {
    currentContact() {
      return this.vendor?.contact?.id
        ? this.vendor?.contact
        : this.vendor?.contacts?.find(contact => contact.id === this.vendor?.contact)
    },
    vendorOrContactModified() {
      return this.vendor?.id !== this.quote?.vendor?.id || this.vendor?.contact !== this.quote?.salesperson?.id
    }
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    changeVendor() {
      if (!this.vendor || !this.vendor.contact) {
        this.$toast.error('Please choose a vendor and a contact.')
        return
      }
      if (this.order.isRFQ && this.order.state.can_manually_invite_vendor) {
        this.showSendInvitationEmailModal = true
      }
      else {
        this.saveAndClose(false)
      }
    },
    async close() {
      this.resetForm()
      this.$emit('update:model-value', false)
    },
    contactCreated(contact) {
      this.vendor.contacts.push(contact)
      this.vendor.contact = contact
    },
    dateYear(value) {
      return dateYear(value)
    },
    openEditContactModal() {
      this.contactCopy = { ...this.currentContact }
      this.showEditContactModal = true
    },
    async patchContact() {
      if (!this.$refs.contactForm.isValid()) {
        this.$toast.error('Please correct the errors')
        return
      }

      this.loading = true
      const data = {
        email: this.contactCopy.email,
        first_name: this.contactCopy.first_name,
        last_name: this.contactCopy.last_name,
        phone_extension: this.contactCopy.phone_extension,
        phone_number: this.contactCopy.phone_number,
      }
      try {
        const contact = await this.$api.v1.vendors.updateContact(this.contactCopy.id, data)
        const index = this.vendor.contacts.findIndex(c => c.id === contact.id)

        this.vendor.contacts[index] = contact

        if (this.quote.salesperson.id === contact.id) {
          this.quote.salesperson = contact
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
      this.showEditContactModal = false
    },
    async resetForm() {
      this.vendorItems = [this.quote.vendor]
      this.vendor = this.quote.vendor
      if (this.quote.salesperson && !this.quote.vendor.contacts.length) {
        this.vendor.contacts.push(this.quote.salesperson)
      }
      this.vendor.contact = this.quote.salesperson?.id || undefined
    },
    async saveAndClose(sendInvitationEmail = false) {
      this.loading = true
      if (this.vendor && this.vendor.contact) {
        try {
          const vendorId = this.vendor.id
          const contactId = this.vendor.contact?.id ? this.vendor.contact.id : this.vendor.contact

          const updatedQuote = await this.$api.v1.quotes.changeVendor(
            this.quote.id,
            { vendor_id: vendorId, contact_id: contactId, send_invitation_email: sendInvitationEmail }
          )

          this.quote.vendor = updatedQuote.vendor
          this.quote.salesperson = updatedQuote.salesperson

          this.$emit('update:model-value', false)
        }
        catch (error) {
          this.$error.report(error)
        }
      }
      else {
        this.$toast.error('Please choose a vendor and a contact.')
      }
      this.loading = false
    },
    async vendorSelected(vendor) {
      this.loading = true
      try {
        const fullVendor = await this.$api.v1.vendors.get(vendor.id)
        this.vendorItems = [fullVendor]
        this.vendor = fullVendor
        if (this.vendor.contacts.length === 1) {
          this.vendor.contact = this.vendor.contacts[0]
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
  },
}
</script>
