<template>
  <qtm-content-block collapsible title="Order Items">
    <template v-slot:title:append>
      <span class="pl-5">
        <span class="font-weight-bold text-secondary">
          {{ items.length }} total
        </span>
        <span v-if="items.length">
          ({{ purchaseItems.length }} purchases, {{ rentalItems.length }} rental items)
        </span>
      </span>
    </template>
    <cart-items-table
      ref="purchaseCart"
      :cost-codes="costCodes"
      data-test="purchase-items"
      :force-cost-code-select="forceCostCodeSelect"
      :items="purchaseItems"
      :make-adjustments="makeAdjustments"
      :read-only-taxes="readOnlyTaxes"
      :require-cost-codes="requireCostCodes"
      :taxes="taxes"
      :total-price-helper="totalPriceHelper"
      :units="units"
      :validate-units="validateUnits"
      :with-cost-codes="withCostCodes"
      :with-prices="withPrices"
      @cost-code-changed="updateCostCodes"
      @remove="removeItem"
      @tax-changed="updateDefaultTax"
    />
    <qtm-btn
      v-if="canAddItems"
      class="mb-8 mt-2"
      data-test="add-purchase-item-btn"
      tertiary
      @click="addPurchaseItem"
    >
      <v-icon size="large" location="left">
        mdi-plus-circle
      </v-icon>
      ADD PURCHASE ITEM
    </qtm-btn>
    <div v-else-if="purchaseItems.length" class="mb-8" />

    <cart-items-table
      ref="rentalCart"
      :cost-codes="costCodes"
      data-test="rental-items"
      :force-cost-code-select="forceCostCodeSelect"
      :items="rentalItems"
      :make-adjustments="makeAdjustments"
      :read-only-taxes="readOnlyTaxes"
      rental
      :require-cost-codes="requireCostCodes"
      :taxes="taxes"
      :total-price-helper="totalPriceHelper"
      :units="units"
      :validate-units="validateUnits"
      :with-cost-codes="withCostCodes"
      :with-prices="withPrices"
      @cost-code-changed="updateCostCodes"
      @remove="removeItem"
      @tax-changed="updateDefaultTax"
    />
    <qtm-btn v-if="canAddItems" class="mt-2" data-test="add-rental-item-btn" tertiary @click="addRentalItem">
      <v-icon size="large" location="left">
        mdi-plus-circle
      </v-icon>
      ADD RENTAL ITEM
    </qtm-btn>

    <order-freight-charges
      v-if="deliveryRequired"
      ref="orderFreightCharges"
      class="mt-8"
      :cost-code="freightCostCode"
      :cost-code-required="freightCostCodeRequired"
      :cost-codes="costCodes"
      :delivery-charge="deliveryCharge"
      :force-cost-code-select="forceCostCodeSelect"
      :hide-charges="!withPrices"
      :make-adjustments="makeAdjustments"
      :pickup-charge="pickupCharge"
      :tax="freightTax"
      :taxes="taxes"
      :with-cost-code="withCostCodes"
      @update:cost-code="$emit('update:freight-cost-code', $event)"
      @update:delivery-charge="$emit('update:delivery-charge', $event)"
      @update:pickup-charge="$emit('update:pickup-charge', $event)"
      @update:tax="$emit('update:freight-tax', $event)"
    />
  </qtm-content-block>
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import useVuelidate from '@vuelidate/core'
import CartItemsTable from '@/components/orders/cart-items-table'
import OrderFreightCharges from '@/components/orders/order-freight-charges'

const itemDefaults = {
  comment: '',
  cost_code: '',
  day_rate: null,
  description: '',
  has_rental_duration: false,
  isNew: true,
  month_rate: null,
  quantity: 1,
  rental_duration: null,
  rental_duration_unit: null,
  unit: 'Each',
  unit_price: null,
  week_rate: null,
}

export default {
  name: 'order-cart',
  components: { CartItemsTable, OrderFreightCharges },
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    deliveryCharge: {
      type: [Number, String],
      default: undefined
    },
    deliveryRequired: {
      type: Boolean,
      default: false
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    freightCostCodeRequired: {
      type: Boolean,
      default: false
    },
    freightTax: {
      type: Number,
      default: undefined
    },
    itemDefaults: {
      type: Object,
      default: () => itemDefaults
    },
    items: {
      type: Array,
      required: true
    },
    makeAdjustments: {
      type: Boolean,
      default: false
    },
    orderBy: {
      type: String,
      default: undefined
    },
    pickupCharge: {
      type: [Number, String],
      default: undefined
    },
    readOnlyTaxes: {
      type: Boolean,
      default: false
    },
    requireCostCodes: {
      type: Boolean,
      default: false
    },
    taxes: {
      type: Array,
      default: () => []
    },
    totalPriceHelper: {
      type: Boolean,
      default: false
    },
    units: {
      type: Array,
      default: () => []
    },
    validate: {
      type: Boolean,
      default: true
    },
    validateUnits: {
      type: Boolean,
      default: true
    },
    withCostCodes: {
      type: Boolean,
      default: true
    },
    withPrices: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:delivery-charge',
    'update:freight-cost-code',
    'update:freight-tax',
    'update:items',
    'update:pickup-charge',
  ],
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    items() {
      return this.hasItems
    },
  },
  data() {
    return {
      costCode: undefined,
      defaultTax: undefined,
    }
  },
  computed: {
    ...mapGetters('auth', ['canCreatePO']),
    canAddItems() {
      return !this.makeAdjustments || this.canCreatePO
    },
    hasItems() {
      return this.purchaseItems.length || this.rentalItems.length
    },
    purchaseItems() {
      return this.sortedItems.filter(item => !item.rental_duration_unit)
    },
    rentalItems() {
      return this.sortedItems.filter(item => item.rental_duration_unit)
    },
    sortedItems() {
      const items = this.items

      if (this.orderBy) {
        items.sort((a, b) => a[this.orderBy] - b[this.orderBy])
      }

      return items
    },
  },
  methods: {
    addItem(rental = false) {
      const item = {
        ...itemDefaults,
        cost_code: this.costCode,
        has_rental_duration: rental,
        id: uuidv4(),
        rental_duration: rental ? 1 : null,
        rental_duration_unit: rental ? 'days' : null,
      }

      if (this.units.length) {
        item.unit = this.units.find(unit => unit.is_default)?.code
      }

      if (this.taxes.length === 1) {
        item.tax = this.taxes[0].id
      }
      else {
        item.tax = this.defaultTax
      }

      if (this.orderBy) {
        item[this.orderBy] = this.items.length
      }

      this.$emit('update:items', this.items.concat([item]))
    },
    addPurchaseItem() {
      this.addItem()
    },
    addRentalItem() {
      this.addItem(true)
    },
    isValid() {
      if (!this.validate) {
        return true
      }

      this.v$.$touch()

      const toValidate = [
        this.$refs.purchaseCart,
        this.$refs.rentalCart,
        this.$refs.orderFreightCharges,
      ]

      return toValidate.filter(c => c).map(c => c.isValid()).concat([!this.v$.$invalid]).every(x => x)
    },
    removeItem(item) {
      this.$emit('update:items', this.items.filter(i => i.id !== item.id))
    },
    updateCostCodes(costCode) {
      this.costCode = costCode

      this.items.filter(item => !item.cost_code).forEach(item => {
        item.cost_code = costCode
      })
    },
    updateDefaultTax(tax) {
      this.defaultTax = tax
    }
  }
}
</script>
