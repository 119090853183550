<template>
  <div class="text-mid-dark-grey my-3">
    Can't find the supplier you are looking for? Add a new one
    <qtm-link bold @click="showCreateVendor = true">
      HERE
    </qtm-link>
    <create-vendor-dialog
      v-model="showCreateVendor"
      v-bind="createVendorOptions"
      :country-code="country"
      @vendor-created="$emit('created', $event)"
    />
  </div>
</template>

<script>
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog'

export default {
  name: 'vendor-create-line',
  components: { CreateVendorDialog },
  props: {
    company: {
      type: Object,
      default: undefined
    },
    createVendorOptions: {
      type: Object,
      default: undefined
    },
  },
  emits: ['created'],
  data() {
    return {
      showCreateVendor: false
    }
  },
  computed: {
    country() {
      return this.company?.country
    },
    integrated() {
      return !!this.company?.accounting_integration
    },
  }
}
</script>
