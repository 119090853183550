<script>
export default {
  name: 'fetch-units-mixin',
  data() {
    return {
      units: []
    }
  },
  computed: {
    $_fetchUnits_companyId() {
      if (this.jobsite && this.jobsite.company) {
        return this.jobsite.company
      }
      
      if (this.order && this.order.jobsite && this.order.jobsite.company) {
        return this.order.jobsite.company
      }

      if (this.order && this.order.owner && this.order.owner.company && this.order.owner.company.id) {
        return this.order.owner.company.id
      }

      if (this.po && this.po.contractor && this.po.contractor.id) {
        return this.po.contractor.id
      }

      return undefined
    }
  },
  watch: {
    $_fetchUnits_companyId() {
      this.$_fetchUnits_fetchUnits()
    }
  },
  mounted() {
    this.$_fetchUnits_fetchUnits()
  },
  methods: {
    async $_fetchUnits_fetchUnits() {
      if (!this.$_fetchUnits_companyId) {
        this.units = []
        return
      }
      try {
        this.units = await this.$api.v1.companies.units(this.$_fetchUnits_companyId)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
  }
}
</script>
