<template>
  <order-summary-card
    v-bind="$attrs"
    v-model:freight-cost-code="revision.freight_cost_code"
    :attachments="invoice.attachments"
    :comments="invoice.comments"
    :contact="invoice.contact"
    :delivery-price="deliveryPrice"
    :freight-tax="revision.freight_tax"
    :items="items"
    :ordered-by="order.owner"
    :pickup-price="pickupPrice"
    :readonly="isApproved"
    :subtitle="`${order.reference_name} for ${order.jobsite.name}`"
    :summary-items="summaryItems"
    :supplier="invoice.vendor"
    :title="`Invoice ${invoice.invoice_number}`"
    :total="totalPrice"
    :total-tax="totalTax"
  >
    <template v-slot:title-append>
      <invoice-status class="ml-3" :invoice="invoice" />
    </template>
    <template v-if="canApprove" v-slot:actions>
      <slot name="actions-prepend" />
      <qtm-btn v-if="!isApproved" :loading="loading" @click="approve">
        Approve
      </qtm-btn>
    </template>
    <template v-slot:summary-item-po>
      <span v-if="order.requiresAdjustments || order.requiresInvoiceApproval" class="text-mid-grey">
        No PO Issued
      </span>
      <span v-else class="cursor-pointer text-interactive" @click="$emit('po-click')">
        {{ po.po_number }}
      </span>
    </template>
  </order-summary-card>
</template>

<script>
import { dateYear } from '~/models/filters'
import InvoiceStatus from '@/components/invoices/invoice-status'
import OrderSummaryCard from '@/components/orders/order-summary-card'

export default {
  name: 'invoice-card',
  components: { InvoiceStatus, OrderSummaryCard },
  props: {
    canApprove: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    po: {
      type: Object,
      required: true
    },
  },
  emits: ['approved', 'po-click'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    deliveryPrice() {
      return this.revision.delivery_charge
    },
    isApproved() {
      return !!this.invoice?.date_approved
    },
    items() {
      if (this.readItemsFromInvoice) {
        return this.invoice.line_items
      }

      return this.po.poskus
    },
    pickupPrice() {
      return this.revision.pickup_charge
    },
    readItemsFromInvoice() {
      return !!this.invoice.line_items.length
    },
    revision() {
      return this.readItemsFromInvoice ? this.invoice.revisions[0] : this.po.latest_change_order
    },
    summaryItems() {
      return [
        {
          label: 'Sent Date',
          value: dateYear(this.invoice.date_issued),
        },
        {
          label: 'Due Date',
          value: dateYear(this.invoice.date_due),
        },
        {
          label: 'PO',
        }
      ]
    },
    totalPrice() {
      if (this.readItemsFromInvoice) {
        return this.invoice.total_price
      }

      return this.po.total_price
    },
    totalTax() {
      if (this.readItemsFromInvoice) {
        return this.invoice.total_tax
      }

      return this.po.total_tax
    },
  },
  methods: {
    async approve() {
      if (this.order.state.can_approve_invoice) {
        this.loading = true
        try {
          await this.$api.v1.invoices.patch(
            this.invoice.id,
            {
              revision: {
                freight_cost_code: this.freightCostCode,
                poskus: this.items
              }
            }
          )
          await this.$api.v1.invoices.approve(this.invoice.id)
          this.$emit('approved', this.order)
          this.$toast.success('Invoice approved')
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
      else {
        if (this.items.some(item => !item.cost_code) || this.freightCostCodeRequired) {
          this.$toast.error('Cost codes are required')
          return
        }

        this.loading = true
        try {
          await this.$api.v1.purchaseOrders.update(
            this.po.id,
            {
              latest_change_order: {
                freight_cost_code: this.freightCostCode,
                poskus: this.items
              }
            }
          )
          const order = await this.$api.v1.rfqs.transition({ id: this.order.id }, 'invoice_approved')
          this.$emit('approved', order)
          this.$toast.success('Invoice approved')
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
    },
  }
}
</script>
