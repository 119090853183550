<template>
  <v-menu
    v-model="showProjects"
    :close-on-content-click="false"
    content-class="qtm-border"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        color="secondary"
        data-test="change-project-btn"
        icon="mdi-pencil"
        :loading="loading"
        size="small"
        variant="text"
      />
    </template>

    <qtm-autocomplete
      v-model="altProject"
      autofocus
      hide-details
      :items="filteredProjects"
      :item-title="projectText"
      return-object
      @update:model-value="onChange"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'change-project-btn',
  props: {
    project: {
      type: Object,
      default: undefined
    },
    user: {
      type: Object,
      default: undefined
    },
  },
  emits: ['change'],
  data() {
    return {
      altProject: undefined,
      loading: false,
      projects: [],
      showProjects: false,
    }
  },
  computed: {
    filteredProjects() {
      return this.projects.filter(project => project.id !== this.project?.id)
    },
  },
  mounted() {
    this.fetchProjects()
  },
  methods: {
    async fetchProjects() {
      this.loading = true
      try {
        this.projects = await this.$api.v1.jobsites.list({ user: this.user })
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async onChange(project) {
      this.$emit('change', project)
      this.showProjects = false

      // Clear the input
      await this.$nextTick()
      this.altProject = undefined
    },
    projectText(site) {
      return `${site.project_number} - ${site.name} - ${site.address}, ${site.city}, ${site.province}, ${site.country}`
    },
  }
}
</script>
