<template>
  <qtm-content-block collapsible title="Change Orders">
    <div v-if="!po.change_orders || po.change_orders.length < 2">
      No change orders have been issued for this PO.
    </div>
    <div v-else class="text--primary change-order-section">
      <div v-for="(changeOrder, index) in po.change_orders" :key="index">
        <div class="subtitle-2 change-order-title">
          <span v-if="index === 0">Initial Purchase Order (Purchase Order Revision 0)</span>
          <span v-else>Change Order {{ index }} (Purchase Order Revision {{ index }})</span>
          -
          {{ dateTime(changeOrder.timestamp) }}
        </div>

        <change-order-table :change-order="changeOrder" />
      </div>
    </div>
  </qtm-content-block>
</template>

<script>
import { dateTime } from '~/models/filters'
import ChangeOrderTable from '@/components/purchase-orders/change-order-table'

export default {
  name: 'change-orders-list',
  components: { ChangeOrderTable },
  props: {
    po: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateTime(value) {
      return dateTime(value)
    },
  }
}
</script>
