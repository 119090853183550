<template>
  <v-menu :content-class="fullContentClass" max-width="300" offset="10" open-on-hover>
    <template v-slot:activator="props">
      <slot name="activator" v-bind="props" />
    </template>

    <v-card>
      <v-card-text class="text-dark-grey">
        <div class="qtm-body">
          <div class="font-weight-bold" v-text="name" />
          <div v-if="mobile">
            Mobile: <qtm-phone-number :phone="mobile" />
          </div>
          <div v-if="phone">
            Office: <qtm-phone-number :phone="phone" :ext="ext" />
          </div>
          <div>
            <a :href="`mailto:${email}`" class="text-decoration-none" v-text="email" />
          </div>
        </div>
        <slot />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'contact-menu-card',
  props: {
    contentClass: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    ext: {
      type: String,
      default: undefined
    },
    mobile: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: undefined
    },
  },
  computed: {
    fullContentClass() {
      return `elevation-5 qtm-border rounded-lg ${this.contentClass}`
    },
  },
}
</script>
