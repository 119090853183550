<template>
  <qtm-content-block v-if="po" title="Purchase order">
    <template v-slot:title>
      <div class="d-flex flex-wrap pl-10">
        <div class="mr-2 qtm-h1 text-secondary">
          PURCHASE ORDER
        </div>
        <ref-number :cancelled="order.cancelled" color="light-grey" :number="po.po_number" text-color="secondary" />
      </div>
    </template>
    <template v-slot:top-right>
      <sync-status v-if="showSync" class="mr-3" :order="order" :po="po" />
      <slot name="action" />
    </template>
    <purchase-order-detail-info :po="po" />
    <div class="d-flex my-3 qtm-h3 text-secondary">
      ORDER ITEMS
      <div v-if="!order.cancelled" class="pl-2 pt-1 qtm-body">
        <span v-if="partialReceipt">
          <pie-chart-icon />
          Partially Received
        </span>
        <span v-else-if="receivedInFull">
          <v-icon color="interactive">
            mdi-check-circle
          </v-icon>
          Order received in full
        </span>
      </div>
    </div>
    <purchase-order-items-table
      :delivery-charge="po.latest_change_order.delivery_charge"
      :freight-cost-code="po.latest_change_order.freight_cost_code"
      :pickup-charge="po.latest_change_order.pickup_charge"
      :items="po.poskus"
      :tax="po.total_tax"
      :total-price="po.total_price"
      :with-received="partialReceipt || receivedInFull"
    />
  </qtm-content-block>
</template>

<script>
import PieChartIcon from '@/components/pie-chart-icon'
import PurchaseOrderDetailInfo from '@/components/purchase-orders/purchase-order-detail-info'
import PurchaseOrderItemsTable from '@/components/purchase-orders/purchase-order-items-table'
import RefNumber from '@/components/ref-number'
import SyncStatus from '@/components/purchase-orders/sync-status'

export default {
  name: 'purchase-order-detail',
  components: {
    PieChartIcon,
    PurchaseOrderDetailInfo,
    PurchaseOrderItemsTable,
    RefNumber,
    SyncStatus,
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    po: {
      type: Object,
      required: true
    },
  },
  computed: {
    partialReceipt() {
      return this.po.poskus.some(item => item.received_quantity)
        && this.po.poskus.some(item => Number(item.received_quantity) !== Number(item.quantity))
    },
    receivedInFull() {
      return this.po.poskus.every(item => Number(item.received_quantity) === Number(item.quantity))
    },
    showSync() {
      return !!this.po.jobsite?.accounting_id && this.order.showAccountingSync
    },
  },
}
</script>
