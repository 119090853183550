<template>
  <qtm-autocomplete
    ref="searchInput"
    v-bind="$attrs"
    v-model:search="searchText"
    hide-details="auto"
    no-filter
    return-object
    :items="items"
    :loading="loading"
    :placeholder="placeholderText"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
    <template v-slot:no-data>
      <v-list-item class="missing-item-row">
        <v-label v-if="!loading && searchText && searchText.length > 2">
          No matches found
        </v-label>
        <v-label v-else>
          Start typing to begin search
        </v-label>
      </v-list-item>
    </template>
    <template v-if="results.length" v-slot:append-item>
      <div class="my-5 text-center">
        <qtm-btn v-if="!maxSearchResultsReached" tertiary @click="getMoreSearchResults">
          Load More Results
        </qtm-btn>
        <div v-else class="text-mid-light-grey">
          No more search results found
        </div>
      </div>
    </template>
  </qtm-autocomplete>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'document-search',
  props: {
    appendItems: {
      type: Array,
      default: () => []
    },
    document: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      default: undefined
    },
    limit: {
      type: Number,
      default: 10
    },
    maxWidth: {
      type: [Number, String],
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      loading: false,
      offset: 0,
      results: [],
      searchText: '',
    }
  },
  computed: {
    endpoint() {
      return `${this.document}s`
    },
    items() {
      return this.results.concat(this.appendItems)
    },
    maxSearchResultsReached() {
      return (this.results.length !== 0
        && (this.results.length !== (this.offset + this.limit))
      )
    },
    placeholderText() {
      return this.placeholder || `${this.document.charAt(0).toUpperCase()}${this.document.slice(1)} Search`
    },
  },
  watch: {
    searchText() {
      this.debounceSearch()
    },
  },
  created() {
    this.debounceSearch = debounce(this.search.bind(this), 350)
  },
  methods: {
    getQueryParams() {
      const params = {
        pagination: { limit: this.limit, offset: this.offset },
        search: this.searchText,
        ...this.filters,
      }

      return params
    },
    search() {
      if (!this.isValidSearch()) {
        return
      }
      this.offset = 0
      this.getSearchResults()
    },
    getMoreSearchResults() {
      this.offset += this.limit
      this.getSearchResults(this.offset)
    },
    async getSearchResults() {
      this.loading = true
      try {
        const results = await this.$api.v1.search[this.endpoint](this.getQueryParams())

        if (this.offset) {
          this.results = this.results.concat(results)
        }
        else {
          this.results = results
        }
      }
      catch (error) {
        this.$error.report(error)
      }

      this.loading = false
    },
    isValidSearch() {
      if (!this.searchText || this.searchText.length < 3) {
        this.results = []
        return false
      }
      return true
    },
  }
}
</script>
