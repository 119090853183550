<template>
  <v-autocomplete
    ref="vAutocomplete"
    v-bind="$attrs"
    :append-inner-icon="appendIcon"
    :bg-color="backgroundColor"
    clear-icon="mdi-close"
    color="interactive"
    item-color="interactive"
    :item-title="itemTitle"
    :item-value="itemValue"
    :menu-props="menuProps"
    single-line
    variant="outlined"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'qtm-autocomplete',
  props: {
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down'
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    itemTitle: {
      type: [Function, String],
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'id'
    }
  },
  computed: {
    menuProps() {
      return { contentClass: 'qtm-border', maxHeight: 300, ...this.$attrs['menu-props'] }
    }
  },
  methods: {
    blur() {
      this.$refs.vAutocomplete.blur()
    },
  }
}
</script>
