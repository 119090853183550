// https://github.com/nuxt-community/sentry-module/issues/358#issuecomment-1016983543

import * as Sentry from '@sentry/vue'

export const sentry = {
  addBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
  captureException: (exception) => Sentry.captureException(exception),
  setContext: (n, context) => Sentry.setContext(n, context),
  setUser: (user) => Sentry.setUser(user),
  setTag: (tagName, value) => Sentry.setTag(tagName, value),
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: [vueApp],
    dsn: config.SENTRY_DSN,
    logErrors: false,
    tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE || 1.0,
    debug: config.SENTRY_ENABLE_DEBUG || false,
    environment: config.ENV_TYPE || 'dev',
    enabled: config.ENV_TYPE !== 'dev' || config.FORCE_SENTRY,
    release: `qtm-app-front-end@${config.NPM_PACKAGE_VERSION}`,
  })

  nuxtApp.provide('sentry', sentry)
})
