<template>
  <div>
    <div v-if="label">
      <span class="qtm-h4 text-secondary text-uppercase">
        <slot name="label">
          {{ label }}
        </slot>
        <span v-if="required" class="text-primary">
          *
        </span>
      </span>
      <slot name="label-append" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'input-label',
  props: {
    label: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>
