<template>
  <div class="order-form">
    <order-info ref="order_info" :order="order" />
    <order-scope-of-work v-model="order.scope_of_work" />
    <order-cart
      ref="order_cart"
      v-model:freight-cost-code="order.freight_cost_code"
      v-model:freight-tax="order.freight_tax"
      v-model:items="order.skus"
      :cost-codes="costCodes"
      :delivery-required="order.delivery_required"
      :force-cost-code-select="forceCostCodeSelect"
      :taxes="taxes"
      :units="units"
    />
    <order-delivery ref="order_delivery" :order="order" />
    <order-attachments
      ref="order_attachments"
      v-model="order.attachments"
      content-type="rfqs.order"
      :object-id="order.id"
    />
    <order-comment v-model="order.comment" :placeholder="commentPlaceholder" />

    <v-divider class="my-5" />

    <div class="text-center">
      <slot name="action-buttons" />
    </div>
  </div>
</template>

<script>
import FetchCostCodesMixin from '@/mixins/fetch-cost-codes-mixin'
import FetchTaxesMixin from '@/mixins/fetch-taxes-mixin'
import FetchUnitsMixin from '@/mixins/fetch-units-mixin'
import OrderAttachments from '@/components/orders/order-attachments'
import OrderCart from '@/components/orders/order-cart'
import OrderComment from '@/components/orders/order-comment'
import OrderDelivery from '@/components/orders/order-delivery'
import OrderInfo from '@/components/orders/order-info'
import OrderScopeOfWork from '@/components/orders/order-scope-of-work'

export default {
  name: 'order-form',
  components: {
    OrderAttachments,
    OrderCart,
    OrderComment,
    OrderDelivery,
    OrderInfo,
    OrderScopeOfWork,
  },
  mixins: [FetchCostCodesMixin, FetchTaxesMixin, FetchUnitsMixin],
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    commentPlaceholder() {
      return 'Additional comments relating to this request that you would like to share with relevant suppliers'
    },
    forceCostCodeSelect() {
      return !!this.order.jobsite?.accounting_id
    },
  },
  methods: {
    isValid() {
      // list components in vertical order, from top to bottom
      // so the user scrolls up to the highest component with errors
      const components = [
        this.$refs.order_info,
        this.$refs.order_cart,
        this.$refs.order_delivery,
        this.$refs.order_attachments,
      ]
      const componentValidations = []
      let hasScrolled = false

      components.forEach(c => {
        const isValid = c.isValid()

        componentValidations.push(isValid)
        if (!hasScrolled && !isValid) {
          c.$el.scrollIntoView({ behavior: 'smooth' })
          hasScrolled = true
        }
      })

      return componentValidations.every(x => x)
    },
  }
}
</script>

<style lang="scss" scoped>
.order-form > * {
  margin-bottom: 1rem;
}
</style>
