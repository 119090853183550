<template>
  <div class="overflow-x-auto">
    <table v-show="!hide" class="cart-items-table">
      <thead class="cart-items-table-header qtm-h4 text-secondary text-no-wrap text-uppercase">
        <tr>
          <th v-for="(header, i) in headers" :key="i" class="pa-2" v-text="header" />
          <th />
        </tr>
      </thead>
      <thead style="border: none">
        <tr>
          <th class="pb-1" :colspan="headers.length + 1" />
        </tr>
      </thead>
      <cart-items-row
        v-for="item in items"
        :key="item.id"
        ref="cartItemsRow"
        :comment-col-span="headers.length - 1"
        :cost-codes="costCodes"
        :force-cost-code-select="forceCostCodeSelect"
        :item="item"
        :make-adjustments="makeAdjustments"
        :read-only-taxes="readOnlyTaxes"
        :rental="rental"
        :require-cost-codes="requireCostCodes"
        :taxes="taxes"
        :units="units"
        :validate-units="validateUnits"
        :with-cost-code="withCostCodes"
        :with-prices="withPrices"
        :with-total-price-helper="includeTotalPriceHelper"
        @cost-code-changed="$emit('cost-code-changed', $event)"
        @tax-changed="$emit('tax-changed', $event)"
        @remove="$emit('remove', $event)"
      />
    </table>
  </div>
</template>

<script>
import CartItemsRow from '@/components/orders/cart-items-row'

export default {
  name: 'cart-items-table',
  components: { CartItemsRow },
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true,
    },
    makeAdjustments: {
      type: Boolean,
      default: false
    },
    readOnlyTaxes: {
      type: Boolean,
      default: false
    },
    rental: {
      type: Boolean,
      default: false,
    },
    requireCostCodes: {
      type: Boolean,
      default: false
    },
    taxes: {
      type: Array,
      default: () => []
    },
    totalPriceHelper: {
      type: Boolean,
      default: false
    },
    units: {
      type: Array,
      default: () => []
    },
    validateUnits: {
      type: Boolean,
      default: false
    },
    withCostCodes: {
      type: Boolean,
      default: false
    },
    withPrices: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cost-code-changed', 'remove', 'tax-changed'],
  computed: {
    headers() {
      let headers = [
        this.rental ? 'Rental Items' : 'Purchase Items',
        'Qty',
        this.rental ? 'Duration' : 'U/M',

      ]

      if (this.withPrices) {
        if (this.rental) {
          headers = headers.concat(['Day', 'Week', 'Month Rate'])
        }
        else {
          headers.push('Unit Price')
        }
      }

      if (this.withCostCodes) {
        headers.push('Cost Code')
      }

      if (this.includeTotalPriceHelper) {
        headers.push('Total')
      }

      return headers
    },
    hide() {
      return this.makeAdjustments && !this.items.length
    },
    includeTotalPriceHelper() {
      return !this.rental && this.withPrices && this.totalPriceHelper
    },
  },
  methods: {
    isValid() {
      // An empty cart is considered valid
      return !this.$refs.cartItemsRow
        || !this.$refs.cartItemsRow.length
        || this.$refs.cartItemsRow.every(row => row.isValid())
    },
  }
}
</script>

<style lang="scss" scoped>
.cart-items-table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

.cart-items-table-header {
  background-color: rgb(var(--v-theme-background));
  border: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
