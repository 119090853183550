<template>
  <easy-data-table
    v-bind="$attrs"
    body-item-class-name="pl-0 py-1"
    table-class-name="qtm-data-table"
    :class="`bg-${backgroundColor}`"
    :header-item-class-name="headerClassWithColor"
    :headers="headers"
    :hide-footer="!showDefaultFooter"
    :rows-per-page="itemsPerPage"
    :show-select="showSelect"
  >
    <template v-if="showSelect" v-slot:header.data-table-select="{ props }">
      <qtm-checkbox v-bind="props" data-test="qtm-data-table-checkbox" />
    </template>
    <template v-if="showSelect" v-slot:item.data-table-select="{ item }">
      <qtm-checkbox :val="item" :model-value="modelValue" @update:model-value="$emit('update:model-value', $event)" />
    </template>
    <template v-slot:loading>
      <v-progress-circular color="secondary" indeterminate />
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </easy-data-table>
</template>

<script>
import 'vue3-easy-data-table/dist/style.css'
import EasyDataTable from 'vue3-easy-data-table'

export default {
  name: 'qtm-data-table',
  components: { EasyDataTable },
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    headerClass: {
      type: String,
      default: 'font-weight-bold text-no-wrap text-uppercase'
    },
    headers: {
      type: Array,
      default: () => []
    },
    showDefaultFooter: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: [Number, String],
      default: 10000
    },
    modelValue: {
      type: Array,
      default: () => []
    },
  },
  emits: ['update:model-value'],
  computed: {
    headerClassWithColor() {
      return `${this.headerClass} text-uppercase pl-0`
    },
  }
}
</script>

<style lang="scss" scoped>
.qtm-data-table {
  --easy-table-border: none;
  --easy-table-row-border: 1px solid rgb(var(--v-theme-light-grey));

  --easy-table-header-font-size: 1rem;
  --easy-table-header-font-color: rgb(var(--v-theme-secondary));

  --easy-table-body-row-font-size: 0.875rem;
  --easy-table-body-row-font-color: rgb(var(--v-theme-dark-grey));
  --easy-table-body-row-height: 50px;

  overflow-wrap: anywhere;
  word-break: normal;
}
</style>
