<template>
  <div v-if="hasAttachments" class="text-secondary">
    <v-icon color="interactive" dense>
      mdi-check-circle
    </v-icon>
    <v-dialog v-model="showAttachments" width="430">
      <template v-slot:activator="{ props }">
        <a v-bind="props" class="show-attachments-link text-secondary">
          Quote & Attachments
        </a>
      </template>
      <v-card>
        <v-btn position="absolute" color="interactive" icon location="right" @click="showAttachments = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <div class="supplier-name">
          {{ quote.vendor.name }}
        </div>
        <v-card-text>
          <v-list>
            <template v-for="(attachment, i) in quote.attachments" :key="i">
              <v-list-item class="attachment-item">
                <template v-slot:prepend>
                  <v-icon color="black">
                    mdi-file-document-outline
                  </v-icon>
                </template>
                <v-list-item-title>
                  <a class="text-interactive" :href="attachment.url" target="_blank">
                    {{ attachment.name }}
                  </a>
                </v-list-item-title>
              </v-list-item>
              <v-divider v-if="lastAttachmentIndex !== i" />
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="text-mid-grey">
    <v-icon color="mid-grey" dense>
      mdi-close-circle
    </v-icon>
    No Attachments
  </div>
</template>

<script>
export default {
  name: 'quote-attachments-link',
  props: {
    quote: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAttachments: false
    }
  },
  computed: {
    hasAttachments() {
      return this.quote.attachments && this.quote.attachments.length
    },
    lastAttachmentIndex() {
      return this.hasAttachments ? this.quote.attachments.length - 1 : undefined
    },
  }
}
</script>

<style lang="scss">
.attachment-item .v-list-item__icon {
  margin-right: 8px !important;
}

.show-attachments-link {
  text-decoration: underline;
}

.supplier-name {
  font-weight: bold;
  overflow: hidden;
  padding: 1rem 0.5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-dialog {
  border-radius: 0px !important;
}
</style>
