<template>
  <v-hover v-slot="{ isHovering, props }">
    <qtm-icon-btn
      v-bind="{ ...$attrs, ...props }"
      :color="isHovering ? 'error' : 'mid-light-grey'"
      :icon="icon"
    />
  </v-hover>
</template>

<script>
export default {
  name: 'delete-btn',
  props: {
    icon: {
      type: String,
      default: 'mdi-trash-can-outline'
    },
  },
}
</script>
