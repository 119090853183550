<template>
  <qtm-dialog-card v-bind="$attrs" :title="title">
    <slot>
      Are you sure you want to take this action?
    </slot>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn data-test="confirmation-cancel-button" :loading="loading" tertiary @click="close">
        {{ cancelButtonText }}
      </qtm-btn>
      <qtm-btn class="my-2" data-test="confirmation-ok-button" :loading="loading" @click="$emit('confirm')">
        {{ okButtonText }}
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
export default {
  name: 'confirmation-dialog',
  props: {
    title: {
      type: String,
      default: 'Confirm this Action',
    },
    okButtonText: {
      type: String,
      default: 'OK',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  methods: {
    close() {
      this.$emit('close')
      this.$emit('update:model-value', false)
    }
  }
}
</script>

<!--<style scoped lang="scss">-->
<!--</style>-->
