<script>
export default {
  name: 'fetch-taxes-mixin',
  data() {
    return {
      taxes: []
    }
  },
  computed: {
    $_fetchTaxes_companyId() {
      if (this.jobsite && this.jobsite.company) {
        return this.jobsite.company
      }
      
      if (this.order && this.order.jobsite && this.order.jobsite.company) {
        return this.order.jobsite.company
      }

      if (this.order && this.order.owner && this.order.owner.company && this.order.owner.company.id) {
        return this.order.owner.company.id
      }

      if (this.po && this.po.contractor && this.po.contractor.id) {
        return this.po.contractor.id
      }

      return undefined
    }
  },
  watch: {
    $_fetchTaxes_companyId() {
      this.$_fetchTaxes_fetchTaxes()
    }
  },
  mounted() {
    this.$_fetchTaxes_fetchTaxes()
  },
  methods: {
    async $_fetchTaxes_fetchTaxes() {
      if (!this.$_fetchTaxes_companyId) {
        this.taxes = []
        return
      }
      try {
        this.taxes = await this.$api.v1.companies.taxes(this.$_fetchTaxes_companyId)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
  }
}
</script>
