<template>
  <v-textarea
    v-bind="$attrs"
    :bg-color="backgroundColor"
    clear-icon="mdi-close"
    :color="color"
    :error-messages="activeErrors"
    variant="outlined"
    @blur="showErrors = true"
    @focus="hideErrors"
  />
</template>

<script>
export default {
  name: 'qtm-textarea',
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'interactive'
    },
    errorMessages: {
      type: [Array, String],
      default: undefined
    },
  },
  data() {
    return {
      showErrors: true,
    }
  },
  computed: {
    activeErrors() {
      return this.showErrors ? this.errorMessages : undefined
    }
  },
  methods: {
    hideErrors() {
      if (!this.errorMessages) {
        this.showErrors = false
      }
    },
  }
}
</script>
