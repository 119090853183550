<template>
  <span class="px-2 q-label status white-space-nowrap" :class="colors" v-text="status" />
</template>

<script>
export default {
  name: 'invoice-status',
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    colors() {
      return this.isApproved
        ? ['bg-light-teal', 'text-secondary']
        : ['bg-primary-lighten-1', 'text-primary-darken-1']
    },
    isApproved() {
      return !!this.invoice.date_approved
    },
    status() {
      return this.isApproved ? 'APPROVED' : 'AWAITING APPROVAL'
    },
  }
}
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;
  line-height: 1.5rem;
}
</style>
