<template>
  <div class="quote-order-form">
    <order-attachments
      v-if="order.is_invoice_approval"
      v-model="invoice.attachments"
      allow-preview
      content-type="rfqs.invoice"
      :object-id="invoice.id"
      @preview="$emit('preview', $event)"
    >
      <template v-slot:action="{ attachment }">
        <attachment-analyzer
          v-model:items="items"
          v-model:supplier-reference="quote.supplier_reference"
          :attachment="attachment"
          :company="orderCompany"
          :units="units"
          @analyzing="$emit('preview', $event)"
          @update:items="itemsUpdated"
        />
      </template>
    </order-attachments>
    <order-attachments
      v-else
      v-model="quote.attachments"
      allow-preview
      content-type="rfqs.quote"
      :object-id="quote.id"
      @preview="$emit('preview', $event)"
    >
      <template v-slot:action="{ attachment }">
        <attachment-analyzer
          v-model:items="items"
          v-model:supplier-reference="quote.supplier_reference"
          :attachment="attachment"
          :company="orderCompany"
          :units="units"
          @analyzing="$emit('preview', $event)"
          @update:items="itemsUpdated"
        />
      </template>
    </order-attachments>

    <order-info ref="orderInfo" :order="order" />

    <qtm-content-block v-if="order.is_invoice_approval" collapsible title="Invoice info">
      <invoice-information ref="invoiceInformation" :invoice="invoice" :order="order" />
    </qtm-content-block>

    <quote-vendor-info
      ref="quoteVendorInfo"
      v-model:contact="quote.salesperson"
      :order="order"
      :quote="quote"
      :vendor="quote.vendor"
    />

    <quote-order-info v-if="!order.is_invoice_approval" :order="order" :quote="quote" />

    <order-scope-of-work
      v-if="!order.is_invoice_approval"
      v-model="quote.scope_of_work"
      title="Order Description (Quote)"
    />

    <order-cart
      ref="orderCart"
      v-model:delivery-charge="quote.delivery_charge"
      v-model:freight-cost-code="order.freight_cost_code"
      v-model:freight-tax="order.freight_tax"
      v-model:items="items"
      v-model:pickup-charge="quote.pickup_charge"
      :cost-codes="costCodes"
      :delivery-required="order.delivery_required"
      :force-cost-code-select="forceCostCodeSelect"
      order-by="sort_order"
      :taxes="taxes"
      total-price-helper
      :units="units"
      :validate="validateCart"
      validate-units
      with-prices
    />

    <div class="text-right">
      <order-total-price-estimate
        :delivery-charge="quote.delivery_charge"
        :delivery-required="order.delivery_required"
        :freight-tax="order.freight_tax"
        :pickup-charge="quote.pickup_charge"
        :skus="items"
        :model-value="quote.total_price"
      />
    </div>

    <order-delivery ref="orderDelivery" :order="order" />

    <qtm-content-block collapsible title="Comment">
      <qtm-textarea
        v-model="quote.comments"
        auto-grow
        :maxlength="commentMaxLength"
        placeholder="Comments from the supplier"
        rows="1"
      />
    </qtm-content-block>

    <v-divider class="my-5" />

    <div class="text-center">
      <qtm-btn :loading="loading" secondary @click="previewPO = true">
        <v-icon location="left">
          mdi-text-box-search-outline
        </v-icon>
        Preview PO
      </qtm-btn>
      <qtm-btn :loading="loading" @click="submit(false)">
        Submit Changes
      </qtm-btn>
    </div>

    <preview-po-dialog
      :id="quote.id"
      v-model="previewPO"
      :close-btn-options="{ color: 'primary' }"
      close-btn-text="Ok"
      quote
      refresh-on-open
    />
  </div>
</template>

<script>
import AttachmentAnalyzer from '@/components/attachments/attachment-analyzer'
import FetchCostCodesMixin from '@/mixins/fetch-cost-codes-mixin'
import FetchTaxesMixin from '@/mixins/fetch-taxes-mixin'
import FetchUnitsMixin from '@/mixins/fetch-units-mixin'
import InvoiceInformation from '@/components/invoices/invoice-information'
import OrderAttachments from '@/components/orders/order-attachments'
import OrderCart from '@/components/orders/order-cart'
import OrderDelivery from '@/components/orders/order-delivery'
import OrderInfo from '@/components/orders/order-info'
import OrderScopeOfWork from '@/components/orders/order-scope-of-work'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate'
import PreviewPoDialog from '@/components/purchase-orders/preview-po-dialog'
import QuoteOrderInfo from '@/components/quotes/quote-order-info'
import QuoteVendorInfo from '@/components/quotes/quote-vendor-info'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'

import { MAX_TEXTAREA_LENGTH } from '@/constants'

export default {
  name: 'quote-order-form',
  components: {
    AttachmentAnalyzer,
    InvoiceInformation,
    OrderAttachments,
    OrderCart,
    OrderDelivery,
    OrderInfo,
    OrderScopeOfWork,
    OrderTotalPriceEstimate,
    PreviewPoDialog,
    QuoteOrderInfo,
    QuoteVendorInfo,
  },
  mixins: [FetchCostCodesMixin, FetchTaxesMixin, FetchUnitsMixin, ValidationErrorsMixin],
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['preview'],
  data() {
    return {
      commentMaxLength: MAX_TEXTAREA_LENGTH,
      items: [],
      loading: false,
      previewPO: false,
    }
  },
  computed: {
    invoice() {
      return this.order.invoices[0]
    },
    forceCostCodeSelect() {
      return !!this.order.jobsite?.accounting_id
    },
    orderCompany() {
      return this.order.owner?.company
    },
    validateCart() {
      return this.quote.response_received === 'price given'
    },
  },
  watch: {
    'order.delivery_required': {
      handler() {
        if (!this.order.delivery_required) {
          this.quote.delivery_charge = null
          this.quote.pickup_charge = null
        }
      }
    }
  },
  mounted() {
    this.setItems()
  },
  methods: {
    getRFQSKUField(quotesku, field) {
      if (quotesku.rfqsku) {
        return quotesku.rfqsku[field]
      }
      return undefined
    },
    isValid() {
      const components = [
        this.$refs.orderInfo,
        this.$refs.invoiceInformation,
        this.$refs.quoteVendorInfo,
        this.$refs.orderCart,
        this.$refs.orderDelivery,
      ]
      const componentValidations = []
      let hasScrolled = false

      components.filter(c => c).forEach(c => {
        const isValid = c.isValid()

        componentValidations.push(isValid)
        if (!hasScrolled && !isValid) {
          c.$el.scrollIntoView({ behavior: 'smooth' })
          hasScrolled = true
        }
      })

      const valid = componentValidations.every(isValid => isValid)

      if (!valid) {
        this.$toast.error('Please correct the errors')
      }
      return valid
    },
    setItems() {
      this.items = this.quote.skus.map(sku => ({
        ...sku,
        cost_code: this.getRFQSKUField(sku, 'cost_code'),
        has_rental_duration: this.getRFQSKUField(sku, 'has_rental_duration'),
        sku: this.getRFQSKUField(sku, 'sku'),
      }))
    },
    async submit(throwError = false) {
      if (!this.isValid()) {
        return
      }
      const updateData = this.quote.getSubmitData()

      updateData.customer_pickup = this.order.customer_pickup
      updateData.delivery_date = this.order.delivery_date
      updateData.delivery_location = this.order.delivery_location
      updateData.delivery_time = this.order.delivery_time
      updateData.freight_cost_code = this.order.freight_cost_code
      updateData.freight_tax = this.order.freight_tax
      updateData.jobsite = this.order.jobsite.id
      updateData.location = this.order.location
      updateData.owner = this.order.owner.id
      updateData.reference_name = this.order.reference_name
      updateData.salesperson = updateData.salesperson_id
      updateData.site_contact = this.order.site_contact
      updateData.skus = this.items

      if (this.order.invoices && this.order.invoices.length) {
        updateData.invoice_number = this.order.invoices[0].invoice_number
        updateData.date_due = this.order.invoices[0].date_due
        updateData.date_issued = this.order.invoices[0].date_issued
      }

      this.loading = true
      try {
        await this.$api.v1.quotes.updateOrder(this.quote.id, updateData)

        const updatedOrder = await this.$api.v1.rfqs.get(this.order.id)
        this.$store.commit('admin/updateOrder', updatedOrder)
        this.$toast.success('Changes submitted')
      }
      catch (error) {
        if (throwError) {
          this.loading = false
          throw error
        }
        else {
          this.$error.report(error)
        }
      }
      this.loading = false
    },
    itemsUpdated() {
      this.$refs.orderCart.$el.scrollIntoView({ behavior: 'smooth' })
    },
  }
}
</script>

<style lang="scss" scoped>
.quote-order-form > * {
  margin-bottom: 1rem;
}
</style>
