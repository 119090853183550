import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {
  QtmAddress: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-address.vue" /* webpackChunkName: "components/qtm-address" */).then(c => c.default || c)),
  QtmAutocomplete: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-autocomplete.vue" /* webpackChunkName: "components/qtm-autocomplete" */).then(c => c.default || c)),
  QtmBadge: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-badge.vue" /* webpackChunkName: "components/qtm-badge" */).then(c => c.default || c)),
  QtmBtn: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-btn.vue" /* webpackChunkName: "components/qtm-btn" */).then(c => c.default || c)),
  QtmCheckbox: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-checkbox.vue" /* webpackChunkName: "components/qtm-checkbox" */).then(c => c.default || c)),
  QtmCombobox: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-combobox.vue" /* webpackChunkName: "components/qtm-combobox" */).then(c => c.default || c)),
  QtmContentBlock: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-content-block.vue" /* webpackChunkName: "components/qtm-content-block" */).then(c => c.default || c)),
  QtmDataTable: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-data-table.vue" /* webpackChunkName: "components/qtm-data-table" */).then(c => c.default || c)),
  QtmDialogCard: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-dialog-card.vue" /* webpackChunkName: "components/qtm-dialog-card" */).then(c => c.default || c)),
  QtmIconBtn: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-icon-btn.vue" /* webpackChunkName: "components/qtm-icon-btn" */).then(c => c.default || c)),
  QtmInputLabel: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-input-label.vue" /* webpackChunkName: "components/qtm-input-label" */).then(c => c.default || c)),
  QtmLink: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-link.vue" /* webpackChunkName: "components/qtm-link" */).then(c => c.default || c)),
  QtmNumberField: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-number-field.vue" /* webpackChunkName: "components/qtm-number-field" */).then(c => c.default || c)),
  QtmPagination: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-pagination.vue" /* webpackChunkName: "components/qtm-pagination" */).then(c => c.default || c)),
  QtmPasswordField: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-password-field.vue" /* webpackChunkName: "components/qtm-password-field" */).then(c => c.default || c)),
  QtmPhoneNumber: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-phone-number.vue" /* webpackChunkName: "components/qtm-phone-number" */).then(c => c.default || c)),
  QtmRadioGroup: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-radio-group.vue" /* webpackChunkName: "components/qtm-radio-group" */).then(c => c.default || c)),
  QtmRadio: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-radio.vue" /* webpackChunkName: "components/qtm-radio" */).then(c => c.default || c)),
  QtmSelect: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-select.vue" /* webpackChunkName: "components/qtm-select" */).then(c => c.default || c)),
  QtmSkeleton: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-skeleton.vue" /* webpackChunkName: "components/qtm-skeleton" */).then(c => c.default || c)),
  QtmTextField: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-text-field.vue" /* webpackChunkName: "components/qtm-text-field" */).then(c => c.default || c)),
  QtmTextarea: defineAsyncComponent(() => import("/opt/build/repo/base-components/qtm-textarea.vue" /* webpackChunkName: "components/qtm-textarea" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
