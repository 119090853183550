<template>
  <v-card tile>
    <div class="mb-1 text-right" :class="{ 'position-absolute': scrolled }">
      <qtm-icon-btn color="mid-light-grey" icon="mdi-close" @click="$emit('close')" />
    </div>
    <v-card-title
      class="d-flex align-center justify-space-between pb-0 pt-2 px-8"
      :class="{ 'pb-2': scrolled, 'qtm-border-bottom': scrolled }"
    >
      <div class="d-flex align-center">
        <span class="qtm-title-medium text-secondary">{{ title }}</span>
        <slot name="title-append" />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </v-card-title>

    <v-card-text class="pb-12 pt-0 px-8 qtm-scrollbar" @scroll="handleScroll">
      <v-row dense>
        <v-col cols="7">
          <div class="qtm-body-large text-mid-grey" v-text="subtitle" />
        </v-col>
      </v-row>
      <v-row class="mt-12" dense>
        <v-col cols="7">
          <div v-if="supplier" class="d-flex align-center mb-4">
            <contact-menu-card
              content-class="ml-16"
              :email="contactEmail"
              :ext="contactExt"
              :mobile="contactMobile"
              :name="contactName"
              :phone="contactPhone"
            >
              <template v-slot:activator="{ props }">
                <div v-bind="props" class="d-flex align-center">
                  <vendor-avatar :vendor="supplier" />
                  <div class="ml-4">
                    <div class="qtm-overline text-mid-grey text-uppercase">
                      Supplier
                    </div>
                    <div class="text-dark-grey mb-2 qtm-body" v-text="supplier.name" />
                  </div>
                </div>
              </template>
              <qtm-address
                :address="supplier.address"
                :city="supplier.city"
                :province="supplier.province"
              />
            </contact-menu-card>
          </div>
          <div v-if="orderedBy" class="d-flex align-center">
            <contact-menu-card
              content-class="ml-16"
              :email="orderedBy.email"
              :ext="orderedBy.phone_extension"
              :mobile="orderedBy.mobile_phone"
              :name="`${orderedBy.first_name} ${orderedBy.last_name}`"
              :phone="orderedBy.phone"
            >
              <template v-slot:activator="{ props }">
                <div v-bind="props" class="d-flex align-center">
                  <user-avatar color="mid-light-grey" dark size="xl" :user="orderedBy" />
                  <div class="ml-4">
                    <div class="qtm-overline text-mid-grey text-uppercase">
                      Ordered By
                    </div>
                    <div class="text-dark-grey qtm-body" v-text="`${orderedBy.first_name} ${orderedBy.last_name}`" />
                  </div>
                </div>
              </template>
            </contact-menu-card>
          </div>
        </v-col>
        <v-col cols="5">
          <div v-for="item in summaryItems" :key="item.label" class="d-flex justify-space-between mb-1 qtm-body">
            <div class="text-mid-grey" v-text="item.label" />
            <slot :name="`summary-item-${item.label.toLowerCase().replace(' ', '-')}`">
              <div class="text-dark-grey" v-text="item.value" />
            </slot>
          </div>
          <div class="heavy-border my-4" />
          <div class="text-dark-grey d-flex font-weight-bold justify-space-between">
            <div>
              <span class="font-weight-bold qtm-body-large">Total</span>
              <span v-if="!totalTax">(excl. tax)</span>
            </div>
            <div class="font-weight-bold qtm-body-large">
              {{ currency(totalWithTax) }}
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="text-dark-grey qtm-body">
        <div class="heavy-border mb-2 mt-12" />

        <cost-summary-table
          :cost-codes="costCodes"
          :delivery-price="deliveryPrice"
          :force-total-spacer="forceTotalSpacer"
          :freight-cost-code="freightCostCode"
          :freight-tax="freightTax"
          :items="items"
          :pickup-price="pickupPrice"
          :readonly="readonly"
          :total-price="total"
          :total-tax="totalTax"
          :with-received="withReceived"
          @update:freight-cost-code="$emit('update:freight-cost-code', $event)"
        >
          <template v-slot:summary-costs-append="props">
            <slot name="summary-costs-append" v-bind="props" />
          </template>
          <template v-slot:total-cells>
            <slot name="total-cells" />
          </template>
        </cost-summary-table>
      </div>
      <div class="text-dark-grey d-flex">
        <div>
          <attachment-preview-download
            v-for="attachment in attachments"
            :key="attachment.id"
            class="mr-3"
            :name="attachment.name"
            :src="attachment.url"
            @click="$emit('preview', attachment)"
          />
        </div>
        <div v-if="comments" class="comments">
          <div class="font-weight-bold text-secondary text-uppercase">
            Comments
          </div>
          <div v-text="comments" />
        </div>
      </div>
    </v-card-text>
    <div class="smooth-bottom" />
  </v-card>
</template>

<script>
import { currency } from '~/models/filters'
import AttachmentPreviewDownload from '@/components/attachments/attachment-preview-download'
import ContactMenuCard from '@/components/contact-menu-card'
import CostSummaryTable from '@/components/orders/cost-summary-table'
import UserAvatar from '@/components/users/user-avatar'
import VendorAvatar from '@/components/vendors/vendor-avatar'

export default {
  name: 'order-summary-card',
  components: {
    AttachmentPreviewDownload, ContactMenuCard, CostSummaryTable, UserAvatar, VendorAvatar
  },
  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    comments: {
      type: String,
      default: ''
    },
    contact: {
      type: Object,
      default: undefined
    },
    costCodes: {
      type: Array,
      default: () => []
    },
    deliveryPrice: {
      type: [Number, String],
      default: 0
    },
    forceTotalSpacer: {
      type: Boolean,
      default: false
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    freightTax: {
      type: Number,
      default: undefined
    },
    items: {
      type: Array,
      default: () => []
    },
    orderedBy: {
      type: Object,
      default: undefined
    },
    pickupPrice: {
      type: [Number, String],
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: '',
    },
    summaryItems: {
      type: Array,
      default: () => []
    },
    supplier: {
      type: Object,
      default: undefined
    },
    title: {
      type: String,
      default: '',
    },
    total: {
      type: [Number, String],
      default: 0
    },
    totalTax: {
      type: [Number, String],
      default: undefined
    },
    withReceived: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'update:freight-cost-code', 'preview'],
  data() {
    return {
      scrolled: false,
    }
  },
  computed: {
    contactEmail() {
      return this.contact?.email
    },
    contactExt() {
      return this.contact?.phone_extension
    },
    contactMobile() {
      return this.contact?.phone_cell
    },
    contactName() {
      if (this.contact) {
        return `${this.contact?.first_name} ${this.contact?.last_name}`
      }
      return undefined
    },
    contactPhone() {
      return this.contact?.phone_number || this.contact?.phone
    },
    totalWithTax() {
      return this.total + (this.totalTax || 0)
    },
  },
  methods: {
    currency(value) {
      return currency(value)
    },
    handleScroll(e) {
      this.scrolled = e.target.scrollTop > 0
    },
  }
}
</script>

<style lang="scss" scoped>
.comments {
  margin-left: 5%;
  min-width: 60%;
}

.heavy-border {
  border-bottom: 4px solid rgb(var(--v-theme-light-grey));
}

.smooth-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

.position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
</style>
