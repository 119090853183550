<template>
  <v-btn
    v-bind="$attrs"
    class="py-8"
    :color="color"
    :dark="active"
    :variant="!active ? 'outlined' : 'flat'"
    @click="$emit('update:model-value', val)"
  >
    <v-icon v-if="icon" :color="iconColor">
      {{ icon }}
    </v-icon>
    <span :class="{ 'text-mid-grey': !active }" v-text="label" />
  </v-btn>
</template>

<script>
export default {
  name: 'toggle-btn',
  props: {
    icon: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    val: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: undefined
    }
  },
  emits: ['update:model-value'],
  computed: {
    active() {
      return this.modelValue === this.val
    },
    color() {
      return this.active ? 'interactive' : 'light-grey'
    },
    iconColor() {
      return this.active ? 'white' : 'primary'
    },
  }
}
</script>
