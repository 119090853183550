<template>
  <v-radio v-bind="$attrs" color="interactive">
    <template v-slot:label>
      <slot name="label">
        <span class="text-secondary">
          {{ label }}
          <span
            v-if="hint"
            class="text-mid-light-grey"
          >
            ({{ hint }})
          </span>
        </span>
      </slot>
    </template>
  </v-radio>
</template>

<script>
export default {
  name: 'qtm-radio',
  props: {
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: undefined,
    },
  }
}
</script>
