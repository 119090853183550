<template>
  <div>
    <div
      v-for="bb in boundingBoxes"
      :key="`${bb.position.Top}-${bb.position.Left}-${bb.position.Width}-${bb.position.Height}`"
      class="bounding-box"
      :style="getBoundingBoxStyle(bb)"
    >
      <div class="confidence" :style="`background-color:${getColorByConfidence(bb.confidence)}`">
        <span>{{ bb.confidence }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'bounding-box',
  components: {},
  computed: {
    ...mapState('admin', {
      attachmentBoundingBoxes: 'attachmentBoundingBoxes'
    }),
    boundingBoxes() {
      return this.attachmentBoundingBoxes.filter(bb => !!bb.position)
    },
  },
  methods: {
    getBoundingBoxStyle(bb) {
      const { Width: width, Height: height, Top: top, Left: left } = bb.position
      const color = this.getColorByConfidence(bb.confidence)
      return `width: ${width * 100}%;
        height: ${height * 100}%;
        top: ${top * 100}%;
        left: ${left * 100}%;
        border-color: ${color}`
    },
    getColorByConfidence(confidence) {
      if (confidence >= 95) return 'green'
      if (confidence >= 90) return 'orange'
      return 'red'
    }
  }
}
</script>

<style lang="scss">
.bounding-box {
  position: absolute;
  border: 2px solid orange;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 5px 5px 5px #777;
}
.confidence {
  right: -2px;
  position: absolute;
  bottom: -14px;
  background-color: orange;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 12px;
  height: 12px;
  width: 42px;
  box-shadow: 5px 5px 5px #777;
}
.confidence span {
  top: -5px;
  right: 4px;
  position: absolute;
  color: white;
}
</style>
