<template>
  <span class="px-3 qtm-h3" :class="`bg-${color} text-${textColor}`">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'qtm-badge',
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    textColor: {
      type: String,
      default: 'white'
    },
  },
}
</script>
