<script>
import download from 'downloadjs'

export default {
  name: 'attachments-mixin',
  data() {
    return {
      downloading: false
    }
  },
  computed: {
    $_attachments_src() {
      return this.src?.toLowerCase() || ''
    },
    isImage() {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(this.$_attachments_src)
    },
    isPDF() {
      return this.$_attachments_src.endsWith('pdf')
    },
  },
  methods: {
    async download(name) {
      this.downloading = true
      try {
        const file = await fetch(this.src, { cache: 'no-cache' }).then(response => response.blob())

        download(file, name)
      }
      catch (error) {
        this.$error.report(error)
        this.$toast.error('Failed to download file')
      }
      this.downloading = false
    },
  }
}
</script>
