<template>
  <div class="invite-vendor-preview">
    <qtm-skeleton v-if="loading" />
    <iframe ref="previewIFrame" :class="{loading}" :src="url" />
    <p>{{ text }} (All Order Attachments will be attached to the email)</p>
  </div>
</template>

<script>
export default {
  name: 'invite-vendor-preview',
  props: {
    order: {
      type: Object,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    url() {
      return this.$api.v1.getPreviewEmailURL(this.order, 'request_quotes')
    }
  },
  mounted() {
    const iframe = this.$refs.previewIFrame
    iframe.addEventListener('load', () => {
      this.loading = false
    })
  }
}
</script>

<style scoped lang="scss">
iframe {
  border: none;
  height: 500px;
  margin: 0;
  padding: 0;
  width: 790px;

  &.loading {
    display: none;
  }
}
</style>
