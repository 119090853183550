<template>
  <v-card class="owner-information qtm-border" variant="flat">
    <v-card-title>
      <div class="d-flex align-center justify-space-between project-title">
        <div>
          Project
        </div>
        <change-project-btn
          v-if="order.owner && order.state.can_edit_project"
          :project="project"
          :user="order.owner"
          @change="updateProject"
        />
      </div>
    </v-card-title>
    <v-card-text v-if="project">
      <div>
        <nuxt-link target="_blank" :to="{ name: 'projects-id', params: { id: project.id } }">
          {{ project.project_number }}
        </nuxt-link>
      </div>
      <div v-text="project.name" />
      <div v-text="deliveryAddress" />
    </v-card-text>
    <v-card-title>
      Customer
    </v-card-title>
    <v-card-text>
      <div>
        <nuxt-link target="_blank" :to="{ name: 'users-id-edit', params: { id: order.owner.id } }">
          {{ order.owner.first_name }} {{ order.owner.last_name }}
        </nuxt-link>
      </div>
      <div v-text="order.owner.email" />
      <div v-if="order.owner.mobile_phone">
        M: {{ order.owner.mobile_phone }}
      </div>
      <div v-if="order.owner.phone">
        O: {{ order.owner.phone }}
      </div>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
import ChangeProjectBtn from '@/components/jobsites/change-project-btn'

export default {
  name: 'project-information',
  components: { ChangeProjectBtn },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      altProject: undefined,
      loading: false,
    }
  },
  computed: {
    deliveryAddress() {
      const keys = ['address', 'city', 'province']
      const prefix = this.order.hasPurchaseOrder ? 'shipto_' : ''
      const subject = this.order.hasPurchaseOrder ? this.order.pos[0] : this.order

      return keys.map(key => subject[`${prefix}${key}`]).join(', ')
    },
    project() {
      return this.order.jobsite
    },
  },
  methods: {
    async updateProject(project) {
      const data = { jobsite: project.id, location: null }

      this.loading = true
      let order
      try {
        if (this.order.hasPurchaseOrder) {
          await this.updatePOProject(data)
          order = await this.$api.v1.rfqs.get(this.order.id)
        }
        else {
          order = await this.updateOrderProject(data)
        }

        if (order) {
          this.$store.commit('admin/updateOrder', order)
          this.$toast.success('Project updated')
        }
        else {
          this.$toast.erorr('No order...')
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    updateOrderProject(payload) {
      payload.id = this.order.id
      payload.freight_cost_code = ''
      payload.skus = this.order.skus.map(item => ({ ...item, cost_code: '' }))

      return this.$api.v1.rfqs.update(payload)
    },
    updatePOProject(payload) {
      const po = this.order.pos[0]

      payload.latest_change_order = {
        freight_cost_code: '',
        poskus: po.poskus.map(item => ({ ...item, cost_code: '' })),
      }

      return this.$api.v1.purchaseOrders.update(po.id, payload)
    },
  }
}
</script>

<style lang="scss" scoped>
.project-title {
  width: 100%;
}
</style>
