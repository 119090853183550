<template>
  <div>
    <v-expansion-panels v-model="openPanels" multiple>
      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title class="font-weight-bold">
          {{ documentName }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <invoice-review v-if="showInvoiceReview" :order="order" @preview="$emit('preview', $event)" />
          <quotes-review v-else :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title class="font-weight-bold">
          Invited Vendors
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <enter-vendor-quotes-table :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="qtm-border" elvation="0">
        <v-expansion-panel-title class="font-weight-bold">
          Order
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <order-detail :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { OrderState } from '@quotetome/materials-api'
import EnterVendorQuotesTable from '@/components/vendors/enter-vendor-quotes-table'
import InvoiceReview from '@/components/quotes/invoice-review'
import QuotesReview from '@/components/quotes/quotes-review'
import OrderDetail from '@/components/orders/order-detail'

export default {
  name: 'enter-quotes',
  components: { EnterVendorQuotesTable, InvoiceReview, QuotesReview, OrderDetail },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['preview'],
  data() {
    return {
      openPanels: [0],
    }
  },
  computed: {
    documentName() {
      return this.order.is_invoice_approval ? 'Invoice' : 'Quotes'
    },
    showInvoiceReview() {
      return this.order.is_invoice_approval && this.order.invoices.length
    },
  },
  mounted() {
    if (this.order.state.id === OrderState.WAITING_FOR_QUOTES.id) {
      this.openPanels.push(1)
    }
    else {
      this.openPanels.push(0)
    }
  },
}
</script>
