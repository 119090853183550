<template>
  <div class="d-flex">
    <qtm-select
      v-if="vendor.contacts && vendor.contacts.length"
      v-model="vendor.contact"
      v-bind="$attrs"
      :item-title="contactName"
      :items="vendor.contacts"
    />
    <qtm-btn class="add-contact-button" size="x-large" @click="showCreateContactDialog = true">
      <v-icon>mdi-plus</v-icon>
      <span v-if="!vendor.contacts.length">Add Contact</span>
    </qtm-btn>
    <create-contact-dialog
      :contact-details-required="contactDetailsRequired"
      :show="showCreateContactDialog"
      :vendor="vendor"
      @close="showCreateContactDialog = false"
      @new-contact="$emit('new-contact', $event)"
    />
  </div>
</template>

<script>
import CreateContactDialog from '@/components/vendors/create-contact-dialog'

export default {
  name: 'vendor-contact-select',
  components: { CreateContactDialog },
  props: {
    contactDetailsRequired: {
      type: Boolean,
      default: false
    },
    vendor: {
      type: Object,
      required: true
    }
  },
  emits: ['new-contact'],
  data() {
    return {
      showCreateContactDialog: false,
    }
  },
  methods: {
    contactName(item) {
      let contact = item

      if (typeof contact === 'number') {
        contact = this.vendor.contacts.find(c => c.id === item)
      }

      if (contact?.first_name) {
        return `${contact.first_name} ${contact.last_name}`
      }

      return ''
    }
  },
}
</script>

<style lang="scss" scoped>
.add-contact-button {
  margin-left: 0.5rem;
}
</style>
