<template>
  <qtm-badge v-bind="$attrs">
    <span v-if="cancelled" class="font-weight-bold">
      CANCELLED
    </span>
    {{ number }}
  </qtm-badge>
</template>

<script>
export default {
  name: 'ref-number',
  props: {
    cancelled: {
      type: Boolean,
      default: false
    },
    number: {
      type: String,
      required: true
    },
  }
}
</script>
