<template>
  <v-row>
    <v-col v-if="!hideCharges" cols="12" md="3">
      <qtm-input-label label="Delivery Charge">
        <qtm-number-field
          :model-value="v$.deliveryCharge.$model"
          :error-messages="errors.deliveryCharge"
          :maxlength="chargeMaxLength"
          prepend-inner-icon="mdi-currency-usd"
          @update:model-value="updateCharge('delivery', $event)"
        />
      </qtm-input-label>
    </v-col>
    <v-col v-if="!hideCharges" cols="12" md="3">
      <qtm-input-label label="Pickup Charge">
        <qtm-number-field
          :model-value="v$.pickupCharge.$model"
          :error-messages="errors.pickupCharge"
          :maxlength="chargeMaxLength"
          prepend-inner-icon="mdi-currency-usd"
          @update:model-value="updateCharge('pickup', $event)"
        />
      </qtm-input-label>
    </v-col>
    <v-col v-if="showTax" cols="12" md="3">
      <qtm-input-label label="Tax">
        <tax-select :taxes="taxes" :model-value="tax" @update:model-value="$emit('update:tax', $event)" />
      </qtm-input-label>
    </v-col>
    <v-col v-if="withCostCode" cols="12" md="3">
      <qtm-input-label label="Freight Cost Code" :required="costCodeRequired">
        <cost-code-select
          :model-value="costCode"
          :cost-codes="costCodes"
          :error-messages="errors.costCode"
          :force-select="forceCostCodeSelect"
          @update:model-value="$emit('update:cost-code', $event)"
        />
      </qtm-input-label>
    </v-col>
  </v-row>
</template>

<script>
import { decimal, minValue, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import CostCodeSelect from '@/components/cost-codes/cost-code-select'
import TaxSelect from '@/components/orders/tax-select'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'
import { PRICE_MAX_LENGTH } from '@/constants'
import { decimalPlaces } from '@/validators'

export default {
  name: 'order-freight-charges',
  components: { CostCodeSelect, TaxSelect },
  mixins: [ValidationErrorsMixin],
  props: {
    costCode: {
      type: String,
      default: ''
    },
    costCodeRequired: {
      type: Boolean,
      default: false
    },
    costCodes: {
      type: Array,
      default: () => []
    },
    deliveryCharge: {
      type: [Number, String],
      default: undefined
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    hideCharges: {
      type: Boolean,
      default: false
    },
    makeAdjustments: {
      type: Boolean,
      default: false
    },
    pickupCharge: {
      type: [Number, String],
      default: undefined
    },
    tax: {
      type: Number,
      default: undefined
    },
    taxes: {
      type: Array,
      default: () => []
    },
    withCostCode: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:cost-code', 'update:delivery-charge', 'update:pick-charge', 'update:tax'],
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    costCode: {
      required: requiredIf(function () {
        return this.costCodeRequired
      })
    },
    deliveryCharge: {
      decimal,
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
    },
    pickupCharge: {
      decimal,
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
    },
  },
  data() {
    return {
      chargeMaxLength: PRICE_MAX_LENGTH,
    }
  },
  computed: {
    errorsByKey() {
      const decimalError = 'Must be a positive number with no more than 2 decimal places'

      return {
        deliveryCharge: decimalError,
        pickupCharge: decimalError,
      }
    },
    showTax() {
      return this.taxes.length && this.withCostCode && !this.makeAdjustments
    },
  },
  methods: {
    updateCharge(field, value) {
      this.$emit(`update:${field}-charge`, value)
    },
  }
}
</script>
