<template>
  <qtm-autocomplete
    v-bind="$attrs"
    data-test="jobsite-select"
    hide-details="auto"
    :item-title="itemName"
    :items="items"
    :loading="loading"
    placeholder="Select Project"
    return-object
    @update:model-value="$emit('update:model-value', $event)"
  />
</template>

<script>
export default {
  name: 'jobsite-select',
  props: {
    params: {
      type: Object,
      default: undefined
    },
    user: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      items: [],
      loading: true,
    }
  },
  watch: {
    user(newUser, oldUser) {
      if ((newUser && !oldUser) || (newUser && newUser.id !== oldUser.id)) {
        this.$emit('update:model-value', null)
      }

      this.fetchJobsites()
    },
  },
  mounted() {
    this.fetchJobsites()
  },
  methods: {
    add(jobsite) {
      this.items.push(jobsite)
    },
    async fetchJobsites() {
      this.loading = true
      try {
        this.items = await this.$api.v1.jobsites.list({ ...this.params, user: this.user })
        this.items.sort((a, b) => a.name.localeCompare(b.name))
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    itemName(site) {
      return `${site.project_number} - ${site.name} - ${site.address}, ${site.city}, ${site.province}, ${site.country}`
    },
    remove(jobsite) {
      this.items = this.items.filter(item => item.id !== jobsite.id)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
