import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import app_45redirect_45global from "/opt/build/repo/middleware/app-redirect.global.js";
import authenticated_45global from "/opt/build/repo/middleware/authenticated.global.js";
import setup_45mode_45global from "/opt/build/repo/middleware/setup-mode.global.js";
import track_45page_45visit_45global from "/opt/build/repo/middleware/track-page-visit.global.js";
export const globalMiddleware = [
  validate,
  app_45redirect_45global,
  authenticated_45global,
  setup_45mode_45global,
  track_45page_45visit_45global
]
export const namedMiddleware = {
  "can-create-po": () => import("/opt/build/repo/middleware/can-create-po.js"),
  "can-get-quotes": () => import("/opt/build/repo/middleware/can-get-quotes.js"),
  "can-manage-company": () => import("/opt/build/repo/middleware/can-manage-company.js"),
  "can-manage-users": () => import("/opt/build/repo/middleware/can-manage-users.js"),
  "clear-auth-token": () => import("/opt/build/repo/middleware/clear-auth-token.js"),
  staff: () => import("/opt/build/repo/middleware/staff.js")
}