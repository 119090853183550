<template>
  <v-menu
    :close-on-content-click="false"
    content-class="qtm-border"
    max-width="450"
    min-width="450"
    offset-y
    :model-value="menu"
    @update:model-value="toggleMenu"
  >
    <template v-slot:activator="{ props: menuProps }">
      <v-tooltip :disabled="!description" location="top">
        <template v-slot:activator="{ props: tooltipProps }">
          <span v-if="disabled" v-bind="tooltipProps" class="text-mid-grey" v-text="modelValue" />
          <span v-else v-bind="{ ...menuProps, ...tooltipProps }" class="cursor-pointer text-interactive">
            {{ modelValue || 'Add Cost Code' }}
            <v-icon v-if="modelValue" color="interactive">
              {{ icon }}
            </v-icon>
          </span>
        </template>
        {{ description }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <qtm-text-field
          v-model="search"
          clearable
          hide-details
          placeholder="Search Cost Codes"
          prepend-inner-icon="mdi-magnify"
        />
      </v-card-title>
      <v-card-text class="cost-code-radios qtm-border-bottom">
        <qtm-radio-group
          v-model="valueProxy"
          hide-details
          item-key="id"
          item-value="cost_code"
          :items="filteredCostCodes"
        />
      </v-card-text>
      <v-card-actions class="py-5">
        <qtm-checkbox v-model="applyToAll" label="Apply to all" />
        <v-spacer />
        <qtm-btn tertiary @click="toggleMenu(false)">
          Cancel
        </qtm-btn>
        <qtm-btn @click="apply">
          Apply
        </qtm-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'cost-code-menu',
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
  },
  emits: ['all', 'update:model-value'],
  data() {
    return {
      applyToAll: false,
      menu: false,
      search: '',
      valueProxy: this.modelValue,
    }
  },
  computed: {
    costCodesWithLabel() {
      return this.costCodes.map(c => ({ ...c, label: `${c.cost_code} - ${c.description}` }))
    },
    description() {
      if (this.modelValue) {
        return this.costCodes.find(costCode => costCode.cost_code === this.modelValue)?.description
      }

      return undefined
    },
    filteredCostCodes() {
      const search = this.search?.trim().toLowerCase()

      if (search) {
        return this.costCodesWithLabel.filter(c => c.label.toLowerCase().includes(search))
      }

      return this.costCodesWithLabel
    },
    icon() {
      return this.menu ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
  },
  methods: {
    apply() {
      if (this.applyToAll) {
        this.$emit('all', this.valueProxy)
      }
      else {
        this.$emit('update:model-value', this.valueProxy)
      }

      this.toggleMenu(false)
    },
    toggleMenu(open) {
      this.applyToAll = false
      this.valueProxy = this.modelValue
      this.menu = open
    },
  }
}
</script>

<style lang="scss" scoped>
.cost-code-radios {
  max-height: 300px;
  overflow-y: auto;
}
</style>
