<template>
  <v-card v-bind="$attrs" class="pa-2">
    <div class="viewer-buttons">
      <qtm-icon-btn
        icon="mdi-download"
        :href="src"
        size="x-small"
        target="_blank"
        title="Download"
      />
      <span class="text-caption">{{ name }}</span>
      <qtm-icon-btn
        v-if="numberOfPages > 1"
        :disabled="!hasPreviousPage"
        icon="mdi-arrow-left"
        size="x-small"
        title="Previous page"
        @click="goToPreviousPage"
      />
      <qtm-icon-btn
        v-if="numberOfPages > 1"
        :disabled="!hasNextPage"
        icon="mdi-arror-right"
        size="x-small"
        title="Next page"
        @click="goToNextPage"
      />
      <qtm-icon-btn
        icon="mdi-close-circle"
        size="x-small"
        title="Close document viewer"
        @click="$emit('close')"
      />
    </div>
    <v-card-text ref="el">
      <div v-if="isPDF" class="pdf-container">
        <vue-pdf-embed
          ref="pdf"
          disable-annotation-layer
          :disable-text-layer="!selectTextLayer"
          :source="src"
          :page="currentPage"
          :width="componentWidth"
          @loaded="pdfLoaded"
          @rendered="onRender"
        />
        <bounding-box />
      </div>
      <div v-else-if="isImage">
        <v-img
          max-width="100%"
          :alt="name"
          :src="src"
        />
        <bounding-box />
      </div>
      <div v-else class="mt-16 pt-16 qtm-h1 text-center">
        <div>
          Cannot view file
        </div>
        <v-btn
          :loading="downloading"
          title="Download"
          size="x-large"
          variant="text"
          @click="download(name)"
        >
          <v-icon color="interactive" location="left">
            mdi-download
          </v-icon>
          {{ name }}
        </v-btn>
      </div>
    </v-card-text>
    <div v-if="numberOfPages > 1" class="viewer-buttons bottom">
      <qtm-icon-btn
        :disabled="!hasPreviousPage"
        icon="mdi-arrow-left"
        size="x-small"
        title="Previous page"
        @click="goToPreviousPage"
      />
      <qtm-icon-btn
        :disabled="!hasNextPage"
        icon="mdi-arrow-right"
        size="x-small"
        title="Next page"
        @click="goToNextPage"
      />
    </div>
  </v-card>
</template>

<script>
import { ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { toast } from '@/plugins/toast'
import VuePdfEmbed from 'vue-pdf-embed'
import AttachmentsMixin from '@/mixins/attachments-mixin'
import BoundingBox from '@/components/admin/bounding-box'

const improveCopiedText = (textToImprove) => {
  const text = textToImprove.trim()
  if (!/[a-zA-Z]/.test(text)) {
    return text.replace(/[^\d./-]/g, '')
  }
  return text
}

export default {
  name: 'attachment-viewer',
  components: { BoundingBox, VuePdfEmbed },
  mixins: [AttachmentsMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    selectTextLayer: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      required: true
    },
  },
  emits: ['close'],
  setup(props) {
    const el = ref(null)
    const componentWidth = ref(500)
    const pendingWidth = ref(null)
    const renderComplete = ref(false)
    const onRender = () => {
      if (pendingWidth.value) {
        componentWidth.value = pendingWidth.value
        pendingWidth.value = null
        return
      }

      renderComplete.value = true

      if (props.selectTextLayer) {
        Array.from(document.getElementsByClassName('textLayer')).forEach(elem => {
          Array.from(elem.children).forEach(span => {
            span.addEventListener('mouseup', (event) => {
              let selectedText = ''

              if (window.getSelection) {
                selectedText = window.getSelection().toString()
              }

              let text = selectedText || event.target.innerHTML

              text = improveCopiedText(text)
              navigator.clipboard.writeText(text)
              toast.toast(`📋 ${text}`, { color: 'darkgrey' })
            }, false)
          })
        })
      }
    }

    useResizeObserver(el, (entries) => {
      const entry = entries[0]
      let { width } = entry.contentRect

      width = Math.trunc(width)

      if (width && width !== componentWidth.value) {
        if (!renderComplete.value) {
          pendingWidth.value = width
        }
        else {
          renderComplete.value = false
          componentWidth.value = width
        }
      }
    })

    return {
      el,
      componentWidth,
      onRender,
      pendingWidth,
      renderComplete,
    }
  },
  data() {
    return {
      currentPage: 1,
      numberOfPages: 0,
    }
  },
  computed: {
    hasNextPage() {
      return this.currentPage < this.numberOfPages
    },
    hasPreviousPage() {
      return this.currentPage > 1
    },
  },
  watch: {
    src() {
      this.numberOfPages = 0
      this.currentPage = 1
    }
  },
  methods: {
    goToNextPage() {
      this.currentPage += 1
    },
    goToPreviousPage() {
      this.currentPage -= 1
    },
    pdfLoaded(pdfProxy) {
      this.numberOfPages = pdfProxy.numPages
    },
  }
}
</script>

<style lang="scss">
.textLayer {
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;

  ::selection {
    background-color: rgb(var(--v-theme-primary)) !important;
  }
}

.textLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.textLayer > span:hover {
  background-color: yellow;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.pdf-container {
  position: relative;
}

.viewer-buttons {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 1px 4px 3px 4px;
  position: absolute;
  right: 10px;
  z-index: 999;
}

.viewer-buttons.bottom {
  bottom: 5px;
  z-index: 998;
}
</style>
