<template>
  <span v-text="formattedAddress" />
</template>

<script>
export default {
  name: 'qtm-address',
  props: {
    address: {
      type: String,
      default: undefined
    },
    city: {
      type: String,
      default: undefined
    },
    postalCode: {
      type: String,
      default: undefined
    },
    province: {
      type: String,
      default: undefined
    },
  },
  computed: {
    formattedAddress() {
      return [this.address, this.city, this.province, this.postalCode].filter(value => value).join(', ')
    }
  }
}
</script>
