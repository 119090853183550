<template>
  <v-hover
    v-slot="{ isHovering, props }"
    open-delay="200"
  >
    <v-snackbar
      v-bind="props"
      v-model="showSnackbar"
      class="rex-container"
      :color="rexColors"
      content-class="rex"
      location="top"
      min-height="40px"
      min-width="0"
      max-width="inherit"
      rounded="t-0 b-lg"
      timeout="-1"
    >
      <span class="qtm-h4">{{ mainText }}</span>
      <span v-if="collapsed || isHovering">
        <span v-if="order.requiresInvoiceApproval" class="ml-4">
          No PO issued. Approving will send accounting a matching PO.
        </span>
        <span v-else>
          <span class="ml-4">
            <v-icon :class="iconColors" small>{{ poOk ? 'mdi-check' : 'mdi-close' }}</v-icon>
            {{ poText }}
          </span>
          <span class="ml-4">
            <v-icon :class="iconColors" small>{{ receivedOk ? 'mdi-check' : 'mdi-close' }}</v-icon>
            {{ receivedText }}
          </span>
        </span>
      </span>
    </v-snackbar>
  </v-hover>
</template>

<script>
import { currency, monthDay } from '~/models/filters'

export default {
  name: 'reconciliator-snackbar',
  components: {},
  mixins: [],
  props: {
    canApprove: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    currentInvoice: {
      type: Object,
      required: true,
    },
    invoices: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      required: true,
    },
    po: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    partiallyReceived() {
      return this.po.poskus.some(item => item.received_quantity)
        && this.po.poskus.some(item => Number(item.received_quantity) !== Number(item.quantity))
    },
    poBalance() {
      const poTotalPrice = this.po.total_price + (this.po.total_tax || 0)
      return poTotalPrice - this.invoices.reduce(
        (sum, invoice) => sum + invoice.total_price + (invoice.total_tax || 0),
        0
      )
    },
    receivedInFull() {
      return this.po.poskus.every(item => Number(item.received_quantity) === Number(item.quantity))
    },
    colors() {
      return this.warningCount
        ? ['primary-lighten-1', 'text-primary-darken-1']
        : ['interactive', 'text-white']
    },
    iconColors() {
      return [...this.colors, 'rex-icon'].join(' ')
    },
    rexColors() {
      return [...this.colors, 'rex-main'].join(' ')
    },
    mainText() {
      return this.warningCount
        ? `${this.warningCount} Approval Warning${this.warningCount === 1 ? '' : 's'}`
        : 'Ready to Pay'
    },
    warningCount() {
      if (this.order.requiresInvoiceApproval) {
        return 1
      }
      return (this.poOk ? 0 : 1) + (this.receivedOk ? 0 : 1)
    },
    poOk() {
      return this.poBalance === 0
    },
    poText() {
      if (this.poOk) {
        return 'Invoice and PO totals match'
      }

      let invoiceIs = 'Invoice is'
      if (this.invoices.length > 1) {
        invoiceIs = `1 of ${this.invoices.length} invoices. Invoices are`
      }
      const difference = currency(Math.abs(this.poBalance))
      const underOrOver = this.poBalance > 0 ? 'under' : 'over'
      return `${invoiceIs} ${difference} ${underOrOver} the PO`
    },
    receivedOk() {
      return this.receivedInFull
    },
    receivedText() {
      if (this.receivedInFull) {
        return 'Order received'
      }

      if (this.partiallyReceived) {
        return 'Order partially received'
      }

      return this.po?.delivery_date
        ? `Order not received (exp. ${monthDay(this.po.delivery_date, '')})`
        : 'Order not received'
    },
    showSnackbar() {
      return this.canApprove && this.show && this.currentInvoice && !this.currentInvoice.approved_by
    }
  },
}
</script>

<style lang="scss">
.rex-main {
  margin-top: 0 !important;
  max-width: inherit !important;
  min-height: 40px !important;
  min-width: 0 !important;
}
.rex-main>div {
  padding: 0 24px;
  width: max-content;
}
.rex-icon {
  margin-top: -3px;
}
</style>
