import mixpanel from 'mixpanel-browser'
import store from '~/store'

export const tracker = {
  track: () => {},
  logIn: () => {},
  logOut: () => {},
}

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  if (config.MIXPANEL_TOKEN) {
    mixpanel.init(config.MIXPANEL_TOKEN)

    tracker.track = (eventName, eventProperties) => {
      const properties = eventProperties || {}
      const user = store.state.auth.user

      if (user) {
        properties.distinct_id = user.id
      }

      if (user && user.company) {
        const companyId = user.company.id
        mixpanel.track_with_groups(eventName, properties, { company_id: companyId })
      }
      else {
        mixpanel.track(eventName, properties)
      }
    }
    tracker.login = (userId) => {
      mixpanel.identify(userId)
    }
    tracker.logOut = () => {
      mixpanel.reset()
    }
  }

  nuxtApp.provide('tracker', tracker)
})
