<template>
  <qtm-content-block collapsible title="Quote Information">
    <div class="quote-info-content">
      <qtm-input-label label="Quote Status">
        <qtm-select v-model="quote.response_received" item-value="value" :items="responseOptions" />
      </qtm-input-label>
      <qtm-input-label label="Quote Valid Until">
        <date-picker v-model="quote.valid_until" />
      </qtm-input-label>
      <qtm-input-label label="Supplier Reference">
        <qtm-text-field
          v-model="quote.supplier_reference"
          :maxlength="supplierRefMaxLength"
          @blur="checkForDuplicates"
        />
        <order-reference-duplicates ref="duplicates" :order-id="order.id" :supplier-id="quote.supplier_id" />
      </qtm-input-label>
    </div>
  </qtm-content-block>
</template>

<script>
import { SUPPLIER_REFERENCE_MAX_LENGTH } from '@/constants'
import DatePicker from '@/components/date-picker'
import OrderReferenceDuplicates from '@/components/orders/order-reference-duplicates'

export default {
  name: 'quote-order-info',
  components: { DatePicker, OrderReferenceDuplicates },
  props: {
    order: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      responseOptions: [
        { value: 'awaiting price', text: 'Awaiting Price' },
        { value: 'declined', text: 'Declined' },
        { value: 'price given', text: 'Price Given' },
      ],
      supplierRefMaxLength: SUPPLIER_REFERENCE_MAX_LENGTH,
    }
  },
  methods: {
    checkForDuplicates() {
      this.$refs.duplicates.checkForDuplicates(this.quote.supplier_reference)
    },
  }
}
</script>

<style lang="scss" scoped>
.quote-info-content {
  max-width: 500px;
}
</style>
