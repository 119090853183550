<template>
  <span class="text-no-wrap">
    {{ phone }}
    <span v-if="ext">
      ext. {{ ext }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'qtm-phone-number',
  props: {
    ext: {
      type: String,
      default: undefined
    },
    phone: {
      type: String,
      default: ''
    },
  },
}
</script>
