<template>
  <span class="px-2 q-label status" :class="colors" v-text="status" />
</template>

<script>
export default {
  name: 'received-status',
  props: {
    po: {
      type: Object,
      required: true,
    },
  },
  computed: {
    colors() {
      return this.receivedInFull
        ? ['bg-light-teal', 'text-secondary']
        : ['bg-primary-lighten-1', 'text-primary-darken-1']
    },
    partialReceipt() {
      return this.po.poskus.some(item => item.received_quantity)
        && this.po.poskus.some(item => Number(item.received_quantity) !== Number(item.quantity))
    },
    receivedInFull() {
      return this.po.poskus.every(item => Number(item.received_quantity) === Number(item.quantity))
    },
    status() {
      if (this.receivedInFull) {
        return 'RECEIVED'
      }

      if (this.partialReceipt) {
        return 'PARTIALLY RECEIVED'
      }

      return 'NOT RECEIVED'
    },
  }
}
</script>

<style lang="scss" scoped>
.status {
  line-height: 1.5rem;
}
</style>
