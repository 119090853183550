<template>
  <div>
    <v-row dense>
      <v-col>
        <qtm-input-label label="Company Name" required>
          <qtm-text-field
            v-model="v$.vendor.name.$model"
            :error-messages="errors.vendor.name"
            hide-details="auto"
            :maxlength="vendorNameMaxLength"
            style="max-width: 320px"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <qtm-input-label label="Street Address" :required="detailsRequired">
          <qtm-text-field
            v-model="v$.vendor.address.$model"
            :error-messages="errors.vendor.address"
            hide-details="auto"
            :maxlength="addressMaxLength"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <qtm-input-label label="Country" :required="detailsRequired">
          <qtm-select
            v-model="vendor.country"
            hide-details="auto"
            :items="countries"
            :error-messages="errors.vendor.country"
            :maxlength="addressMaxLength"
            :disabled="readonlyCountry"
            @update:model-value="countryChanged"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="12" md="6">
        <qtm-input-label :label="provinceLabel" :required="detailsRequired">
          <province-select
            v-model="v$.vendor.province.$model"
            :country="vendor.country"
            hide-details="auto"
            :error-messages="errors.vendor.province"
            :maxlength="addressMaxLength"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <qtm-input-label label="City" :required="detailsRequired">
          <qtm-text-field
            v-model="v$.vendor.city.$model"
            :error-messages="errors.vendor.city"
            hide-details="auto"
            :maxlength="addressMaxLength"
            @update:model-value="cityChanged"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="12" md="6">
        <qtm-input-label :label="postalCodeLabel" :required="detailsRequired">
          <postal-code-input
            v-model="v$.vendor.postal_code.$model"
            :country="vendor.country"
            :error-messages="errors.vendor.postal_code"
            hide-details="auto"
            @update:model-value="postalCodeChanged"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'
import { ADDRESS_MAX_LENGTH, VENDOR_NAME_MAX_LENGTH } from '@/constants'
import PostalCodeInput from '@/components/postal-code-input'
import ProvinceSelect from '@/components/province-select'

export default {
  name: 'vendor-form',
  components: { PostalCodeInput, ProvinceSelect },
  mixins: [ValidationErrorsMixin],
  props: {
    countryCode: {
      type: String,
      required: false,
      default: 'CA',
    },
    detailsRequired: {
      type: Boolean,
      default: false
    },
    readonlyCountry: {
      type: Boolean,
      default: false
    },
    vendor: {
      type: Object,
      required: true
    },
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    vendor: {
      name: { required },
      address: {
        required: requiredIf(function () {
          return this.detailsRequired
        })
      },
      city: {
        required: requiredIf(function () {
          return this.detailsRequired
        })
      },
      country: {
        required: requiredIf(function () {
          return this.detailsRequired
        })
      },
      province: {
        required: requiredIf(function () {
          return this.detailsRequired
        })
      },
      postal_code: {
        required: requiredIf(function () {
          return this.detailsRequired
        }),
      }
    },
  },
  data() {
    return {
      addressMaxLength: ADDRESS_MAX_LENGTH,
      countries: [
        {
          id: 'CA',
          text: 'Canada',
        },
        {
          id: 'US',
          text: 'United States'
        },
      ],
      vendorNameMaxLength: VENDOR_NAME_MAX_LENGTH,
    }
  },
  computed: {
    isCanada() {
      return this.vendor.country !== 'US'
    },
    postalCodeLabel() {
      return this.isCanada ? 'Postal code' : 'Zipcode'
    },
    provinceLabel() {
      return this.isCanada ? 'Province' : 'State'
    }
  },
  created() {
    if (!this.vendor.country) {
      this.vendor.country = this.countryCode
    }
  },
  methods: {
    cityChanged() {
      this.vendor.city = this.vendor.city.charAt(0).toUpperCase() + this.vendor.city.slice(1)
    },
    async countryChanged() {
      await this.$nextTick()
      this.vendor.postal_code = ''
      this.v$.vendor.postal_code.$reset()
      this.vendor.province = ''
      this.v$.vendor.province.$reset()
    },
    postalCodeChanged() {
      if (!this.vendor.postal_code.length) {
        this.v$.vendor.postal_code.$reset()
      }
    },
    resetValidation() {
      this.v$.$reset()
    },
  }
}
</script>
