<template>
  <v-text-field
    v-bind="$attrs"
    ref="textfield"
    :bg-color="backgroundColor"
    clear-icon="mdi-close"
    :color="color"
    :error-messages="activeErrors"
    variant="outlined"
    @blur="showErrors = true"
    @focus="hideErrors"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'qtm-text-field',
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'interactive'
    },
    errorMessages: {
      type: [Array, String],
      default: undefined
    },
  },
  data() {
    return {
      showErrors: true,
    }
  },
  computed: {
    activeErrors() {
      return this.showErrors ? this.errorMessages : undefined
    }
  },
  methods: {
    focus() {
      this.$refs.textfield.focus()
    },
    hideErrors() {
      if (!this.errorMessages) {
        this.showErrors = false
      }
    },
  }
}
</script>
