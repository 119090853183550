<template>
  <qtm-autocomplete
    v-bind="$attrs"
    item-title="name"
    item-value="isoCode"
    :items="provinces"
    @update:model-value="$emit('update:model-value', $event)"
  />
</template>

<script>
import { Country, State } from 'country-state-city'

export default {
  name: 'province-select',
  props: {
    country: {
      type: String,
      default: 'CA',
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      provinces: [],
    }
  },
  watch: {
    country: {
      immediate: true,
      handler(val, old) {
        if (val === old) {
          return
        }

        this.provinces = []
        const country = Country.getAllCountries().find(c => c.isoCode === this.country)

        if (country) {
          this.provinces = State.getStatesOfCountry(country.isoCode)
        }

        if (
          this.$attrs['model-value']
          && !this.provinces.some(province => province.isoCode === this.$attrs['model-value'])
        ) {
          this.$emit('update:model-value', '')
        }
      }
    }
  },
}
</script>
