<template>
  <qtm-dialog-card :model-value="show" persistent width="500">
    <template v-slot:title>
      Add a contact for &nbsp;<span class="vendor-name">{{ vendor.name }}</span>
    </template>
    <div class="qtm-h3 text-secondary">
      Add a New Contact
    </div>
    <p>
      Fill in the information below and click Save. Your new contact will be added to this vendor.
    </p>
    <p>
      <span class="text-red">*</span>
      Indicates required field
    </p>
    <contact-form
      ref="contactForm"
      :contact="contact"
      :vendor="vendor"
      :email-required="contactDetailsRequired"
      :phone-required="contactDetailsRequired"
    />
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary @click="close">
        Cancel
      </qtm-btn>
      <qtm-btn secondary @click="saveAndClean">
        Save and Add Another
      </qtm-btn>
      <qtm-btn @click="saveAndClose">
        Save
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import ContactForm from '@/components/vendors/contact-form'

export default {
  name: 'create-contact-dialog',
  components: { ContactForm },
  props: {
    contactDetailsRequired: {
      type: Boolean,
      default: false
    },
    vendor: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'new-contact'],
  data() {
    return {
      loading: false,
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        phone_extension: '',
        phone_number: '',
      }
    }
  },
  methods: {
    clean() {
      this.contact = {
        first_name: '',
        last_name: '',
        email: '',
        phone_extension: '',
        phone_number: '',
      }
    },
    close() {
      this.$emit('close')
    },
    async saveAndClean(close = false) {
      if (!this.$refs.contactForm.isValid()) {
        this.$toast.error('Please correct the errors')
        return
      }
      this.loading = true
      try {
        this.contact.organization = this.vendor.id
        const contact = await this.$api.v1.vendors.addContact(this.contact)
        this.$emit('new-contact', contact)
        this.clean()
        if (close) {
          this.close()
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    saveAndClose() {
      this.saveAndClean(true)
    }
  },
}
</script>
