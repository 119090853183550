<template>
  <document-search
    v-bind="$attrs"
    data-test="supplier-search-input"
    document="supplier"
    :filters="{ country }"
    :item-title="itemText"
  />
</template>

<script>
import DocumentSearch from '@/components/search/document-search'

export default {
  name: 'supplier-search',
  components: { DocumentSearch },
  props: {
    country: {
      type: String,
      default: undefined
    },
  },
  methods: {
    itemText(item) {
      if (item?.name) {
        return `${item.name} - ${item.city}`
      }
      return ''
    },
  }
}
</script>
