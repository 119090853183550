<template>
  <tbody class="attachments-row">
    <tr>
      <td class="pt-1 qtm-label text-uppercase white-space-nowrap" :rowspan="labelRowSpan">
        <slot name="label">
          {{ label }}
        </slot>
      </td>
    </tr>
    <tr v-for="attachment in visibleAttachments" :key="attachment.id">
      <td class="py-1">
        <a
          class="attachment-link text-decoration-none"
          :download="attachment.name"
          :href="attachment.url"
          target="_blank"
          v-text="attachment.name"
        />
      </td>
      <td class="py-1 text-no-wrap">
        {{ dateUploaded(attachment) }}
      </td>
    </tr>
    <tr v-if="expandable">
      <td>
        <span class="attachment-link expand-btn text-interactive" @click="expanded = ! expanded">
          {{ expandText }}
          <v-icon color="interactive">
            {{ expandIcon }}
          </v-icon>
        </span>
      </td>
      <td />
    </tr>
    <tr>
      <td>
        <slot />
      </td>
      <td />
    </tr>
    <tr v-if="bottomDivider">
      <td colspan="3">
        <v-divider class="my-1" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import { dateYear } from '~/models/filters'

export default {
  name: 'attachments-row',
  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    bottomDivider: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    visibleCutoff: {
      type: [Number, String],
      default: 2
    },
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    expandable() {
      // Add one so that more than a single item will be hidden
      return this.attachments.length > this.visibleCutoff + 1
    },
    expandIcon() {
      return this.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    expandText() {
      return `Show ${this.expanded ? 'Less' : 'More'}`
    },
    labelRowSpan() {
      let additionalSpan = 2

      if (this.expandable) {
        additionalSpan += 1
      }

      return this.visibleAttachments.length + additionalSpan
    },
    sortedAttachments() {
      const attachments = this.attachments

      attachments.sort((a, b) => b.date_uploaded - a.date_uploaded)
      return attachments
    },
    visibleAttachments() {
      if (this.expandable && !this.expanded) {
        return this.sortedAttachments.slice(0, this.visibleCutoff)
      }
      return this.sortedAttachments
    },
  },
  methods: {
    dateUploaded(attachment) {
      return dateYear(attachment.date_uploaded)
    },
  }
}
</script>

<style lang="scss" scoped>
.attachment-link {
  font-weight: 500;
}

.attachments-row {
  vertical-align: top;
}

.expand-btn {
  cursor: pointer;
  vertical-align: middle;
}
</style>
