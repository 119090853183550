<template>
  <v-dialog v-bind="$attrs" scrollable :width="width" @update:model-value="$emit('update:model-value', $event)">
    <v-card color="background">
      <v-card-title class="overflow-force-visible pt-6 px-6 qtm-h4 text-secondary text-uppercase" :class="titleClasses">
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>
      <qtm-icon-btn
        v-if="showCloseBtn"
        class="close-btn"
        icon="mdi-close"
        size="large"
        @click="$emit('update:model-value', false)"
      />
      <v-card-subtitle class="overflow-force-visible text-mid-grey" :class="subtitleClasses">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </v-card-subtitle>
      <v-card-text class="pb-6 px-6 text-mid-dark-grey" :class="paddingClass">
        <slot />
      </v-card-text>
      <v-card-actions class="bg-white px-6" :class="`${paddingClass} ${actionsClass}`">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'qtm-dialog-card',
  props: {
    actionsClass: {
      type: String,
      default: ''
    },
    padding: {
      type: [Number, String],
      default: undefined
    },
    subtitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    titleCentered: {
      type: Boolean,
      default: false
    },
    headerBackground: {
      type: String,
      default: 'background'
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 400
    }
  },
  emits: ['update:model-value'],
  computed: {
    paddingClass() {
      return this.padding ? `px-${this.padding}` : ''
    },
    titleClasses() {
      const classes = []
      if (this.padding) {
        classes.push(`px-${this.padding}`)
      }
      if (this.titleCentered) {
        classes.push('justify-center')
      }
      if (this.headerBackground) {
        classes.push(`bg-${this.headerBackground}`)
      }
      return classes
    },
    subtitleClasses() {
      const classes = []
      if (this.padding) {
        classes.push(`px-${this.padding}`)
      }
      if (this.headerBackground) {
        classes.push(`bg-${this.headerBackground}`)
      }
      return classes
    }
  }
}
</script>

<style scoped>
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
