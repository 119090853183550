<template>
  <qtm-content-block collapsible :title="title">
    <qtm-textarea
      v-bind="$attrs"
      auto-grow
      :maxlength="maxScopeOfWorkLength"
      placeholder="A summary of the work to be done."
      rows="1"
    />
  </qtm-content-block>
</template>

<script>
import { MAX_TEXTAREA_LENGTH } from '@/constants'

export default {
  name: 'order-scope-of-work',
  components: {},
  props: {
    maxScopeOfWorkLength: {
      type: Number,
      default: MAX_TEXTAREA_LENGTH,
    },
    title: {
      type: String,
      default: 'Order Description'
    },
  },
}
</script>
