<template>
  <div>
    <qtm-dialog-card v-model="showSuccessDialog" title="Accounting Sync Successful" width="500">
      <div class="mb-5 text-secondary">
        <div v-for="(message, i) in messages" :key="i" v-text="message" />
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn size="x-large" @click="$emit('update:show-dialog', false)">
          Ok
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <qtm-dialog-card v-model="showErrorDialog" title="Accounting Sync Failed" width="500">
      <div class="mb-5 qtm-h1 text-uppercase">
        Requires Your action to resolve the issue
      </div>
      <div class="mb-5">
        <span v-if="receiptLogId" class="text-interactive">
          Receipt Log for
        </span>
        <span class="text-interactive">
          PO {{ poNumber }}-{{ orderReferenceName }}-{{ revisionNumber }}
        </span>
        submitted by {{ ownerName }} failed to Sync with your accounting software due to the following error message:
      </div>
      <div class="mb-5 qtm-h4 text-secondary">
        <div v-if="currentProblem">
          {{ currentProblem.message }}
        </div>
        <div v-else>
          <div v-for="(error, i) in messages" :key="i" v-text="error" />
        </div>
      </div>
      <div v-if="currentProblem">
        Click below to resolve the issue.
      </div>
      <div v-else>
        Please take the necessary action and sync again.
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" tertiary size="x-large" @click="$emit('update:show-dialog', false)">
          Close
        </qtm-btn>
        <qtm-btn v-if="currentProblem" :loading="loading" size="x-large" @click="showResolutionDialog = true">
          Resolve
        </qtm-btn>
        <qtm-btn v-else :loading="loading" size="x-large" @click="syncAgain">
          Sync Again
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <qtm-dialog-card v-model="showPendingDialog" title="Accounting Sync Pending" width="500">
      <div class="mb-5 text-secondary">
        <p>
          This {{ revisionNumber ?'change order':'PO' }} has been issued and
          is now being sent to your accounting software.
          This may take a few minutes.
        </p>
        <p>Further change orders cannot be issued until the sync is successfully completed.</p>
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn size="x-large" @click="$emit('update:show-dialog', false)">
          Ok
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <resolve-config-problem-dialog
      v-if="showResolutionDialog"
      :problem="currentProblem"
      @update:model-value="showResolutionDialog = $event"
      @resolved="resolved"
    />
  </div>
</template>

<script>
import ResolveConfigProblemDialog from '@/components/accounting/resolve-config-problem-dialog'

export default {
  name: 'sync-dialog',
  components: { ResolveConfigProblemDialog },
  props: {
    accountingMessage: {
      type: String,
      required: true
    },
    accountingSuccess: {
      type: Boolean,
      required: true
    },
    orderOwner: {
      type: String,
      required: true
    },
    orderReferenceName: {
      type: String,
      required: true
    },
    poId: {
      type: Number,
      required: true
    },
    poNumber: {
      type: String,
      required: true
    },
    receiptLogId: {
      type: Number,
      default: null
    },
    revisionId: {
      type: Number,
      required: true
    },
    revisionNumber: {
      type: Number,
      required: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:accounting-success', 'update:show-dialog'],
  data() {
    return {
      problems: [],
      loading: false,
      showResolutionDialog: false,
    }
  },
  computed: {
    currentProblem() {
      return this.problems[0]
    },
    messages() {
      return this.accountingMessage.split('\n')
    },
    ownerName() {
      return this.orderOwner
    },
    revision() {
      return this.revisionNumber
    },
    success() {
      return this.accountingSuccess
    },
    showSuccessDialog: {
      get() {
        return this.showDialog && this.success === true
      },
      set(value) {
        this.$emit('update:show-dialog', value)
      }
    },
    showErrorDialog: {
      get() {
        return this.showDialog && this.success === false
      },
      set(value) {
        this.$emit('update:show-dialog', value)
      }
    },
    showPendingDialog: {
      get() {
        return this.showDialog && this.success === null
      },
      set(value) {
        this.$emit('update:show-dialog', value)
      }
    }
  },
  watch: {
    poId: {
      handler(newPoId, oldPoId) {
        if (newPoId && (newPoId !== oldPoId)) {
          this.fetchConfigProblems()
        }
      }
    }
  },
  mounted() {
    this.fetchConfigProblems()
  },
  methods: {
    async fetchConfigProblems() {
      if (!this.poId) return

      this.loading = true
      try {
        this.problems = await this.$api.v1.accountingConfigProblems.list({ purchase_order: this.poId })
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    resolved(problem) {
      this.problems = this.problems.filter(p => p.id !== problem.id)

      if (this.problems.length === 0) {
        this.$emit('update:accounting-success', null)
        this.$toast.success('Sync attempt in progress')
        this.$emit('update:show-dialog', false)
      }
    },
    async syncAgain() {
      this.loading = true
      try {
        if (this.receiptLogId) {
          await this.$api.v1.receipts.resolveAccountingFailure(this.receiptLogId, {})
        }
        else {
          await this.$api.v1.purchaseOrders.resolveAccountingFailure(this.poId, { change_order: this.revisionId })
        }
        this.$emit('update:accounting-success', null)
        this.$toast.success('Sync attempt in progress')
      }
      catch (error) {
        this.$error.report(error)
      }
      this.$emit('update:show-dialog', false)
      this.loading = false
    },
  }
}
</script>
