<template>
  <v-img
    alt="Pie Chart Icon"
    class="pie-chart-icon"
    contain
    :height="size"
    :width="size"
    :src="pieChart"
  />
</template>

<script>
import pieChart from '@/assets/img/pie-chart.svg'

export default {
  name: 'pie-chart-icon',
  props: {
    size: {
      type: [Number, String],
      default: 24
    },
  },
  created() {
    this.pieChart = pieChart
  },
}
</script>

<style lang="scss" scoped>
.pie-chart-icon {
  display: inline-block;
  vertical-align: bottom;
}
</style>
