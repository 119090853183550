<template>
  <v-dialog v-model="localValue" max-width="60rem" scrollable>
    <v-card>
      <v-card-title />
      <v-card-text>
        <printable-po-preview v-if="isAlive" v-bind="$attrs" ref="poPreview" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <qtm-btn
          v-bind="closeBtnAttrs"
          class="ml-3"
          tertiary
          @click="localValue = false"
        >
          {{ closeBtnText }}
        </qtm-btn>
        <span class="ml-3">
          <slot />
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrintablePoPreview from '@/components/purchase-orders/printable-po-preview'

export default {
  name: 'preview-po-dialog',
  components: { PrintablePoPreview },
  props: {
    closeBtnOptions: {
      type: Object,
      default: () => {}
    },
    closeBtnText: {
      type: String,
      default: 'Close'
    },
    refreshOnOpen: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      required: true
    },
  },
  emits: ['update:model-value'],
  computed: {
    closeBtnAttrs() {
      return { ...this.closeBtnOptions }
    },
    isAlive() {
      return !this.refreshOnOpen || this.modelValue
    },
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    }
  },
  methods: {
    refreshPdf() {
      if (this.$refs.poPreview) {
        this.$refs.poPreview.fetchPdf()
      }
    }
  }
}
</script>
