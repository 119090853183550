<template>
  <qtm-select
    v-bind="$attrs"
    clearable
    hide-details
    :item-title="formatTax"
    :items="taxes"
  />
</template>

<script>

export default {
  name: 'tax-select',
  props: {
    taxes: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    formatTax(item) {
      let tax = item

      if (typeof item === 'number') {
        tax = this.taxes.find(t => t.id === item) || item
      }

      const rate = tax.displayable_rate ?? (tax.rate * 100)
      return `${tax.label} (${rate}%)`
    },
  }
}
</script>
