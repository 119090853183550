<template>
  <qtm-content-block class="mb-4" collapsible title="Attachments">
    <table class="attachments-table">
      <tr>
        <th>
          CATEGORIES
        </th>
        <th>
          FILE NAME
        </th>
        <th>
          FILE DATE
        </th>
      </tr>
      <attachments-row
        v-for="(element, i) in attachmentsByCategory"
        :key="i"
        bottom-divider
        :attachments="element.attachments"
        :label="element.label"
        :visible-cutoff="element.visibleCutoff"
      />
      <invoice-attachments-row
        v-if="invoices && invoices.length"
        bottom-divider
        :invoices="invoices"
        :order="order"
        :po="po"
      />
      <attachments-row :attachments="otherAttachments">
        <template v-slot:label>
          Other Supporting<br>Documents
        </template>
        <order-attachments
          v-if="!po.isOpen"
          v-model="newAttachments"
          class="mr-10"
          content-type="rfqs.purchaseorder"
          hide-attachments
          :object-id="po.id"
          remove-title
        />
      </attachments-row>
    </table>
  </qtm-content-block>
</template>

<script>
import AttachmentsRow from '@/components/attachments/attachments-row'
import InvoiceAttachmentsRow from '@/components/invoices/invoice-attachments-row'
import OrderAttachments from '@/components/orders/order-attachments'

const PO_CATEGORY = 2

export default {
  name: 'purchase-order-attachments',
  components: { AttachmentsRow, InvoiceAttachmentsRow, OrderAttachments },
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      required: true
    },
    po: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newAttachments: []
    }
  },
  computed: {
    attachmentsByCategory() {
      const attachments = [
        { label: 'Request', attachments: this.po.attachments.rfq_attachments },
        { label: 'Supplier Quote', attachments: this.po.attachments.quote_attachments },
        {
          label: 'Purchase Order',
          attachments: this.po.attachments.po_attachments.filter(attachment => attachment.category === PO_CATEGORY),
          visibleCutoff: 1,
        },
        { label: 'Receiving', attachments: this.po.attachments.receipt_attachments },
      ]

      return attachments
    },
    otherAttachments() {
      return this.po.attachments.po_attachments
        .filter(attachment => attachment.category !== PO_CATEGORY)
        .concat(this.newAttachments)
    },
  },
}
</script>

<style lang="scss" scoped>
.attachments-table {
  width: 100%;
}

.attachments-table th {
  color: rgb(var(--v-theme-secondary));
  padding-bottom: 0.5rem;
  text-align: left;
}
</style>
