<template>
  <qtm-input-label label="Notification Recipient">
    <qtm-select
      v-model="recipient"
      density="compact"
      :item-title="teamMemberName"
      :items="projectTeam"
      :loading="loading"
      persistent-hint
      placeholder="Select the recipient of the Notification"
      @update:model-value="$emit('update-action', { actions: { send_quotes_ready: { ADDRESSEE: $event } } })"
    />
  </qtm-input-label>
</template>

<script>
export default {
  name: 'recipient-selection',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['update-action'],
  data() {
    return {
      loading: true,
      projectTeam: [],
      recipient: undefined,
    }
  },
  async mounted() {
    this.loading = true
    try {
      this.projectTeam = await this.$api.v1.users.list({ jobsite: this.order.jobsite.id })
    }
    catch (error) {
      this.$error.report(error)
    }
    if (!this.projectTeam.some(member => member.id === this.order.owner.id)) {
      this.projectTeam.push(this.order.owner)
    }
    this.recipient = this.order.owner.id
    this.$emit('update-action', { actions: { send_quotes_ready: { ADDRESSEE: this.recipient } } })
    this.loading = false
  },
  methods: {
    teamMemberName(item) {
      let member = item

      if (typeof member === 'number') {
        member = this.projectTeam.find(m => m.id === item)
      }

      if (member?.first_name) {
        return `${member.first_name} ${member.last_name}`
      }

      return ''
    }
  }
}
</script>
