function userHasPerm(user, permCodename) {
  return user && user.qtm_permissions.some(perm => perm.codename === permCodename)
}

export default {
  allowCustomCostCodes(state) {
    return state.user?.company?.allow_custom_cost_codes
  },

  canCreateJobsite(state) {
    return userHasPerm(state.user, 'qtm_can_create_jobsite')
  },

  canCreatePO(state) {
    return userHasPerm(state.user, 'qtm_can_create_purchase_order')
  },

  canGetQuotes(state) {
    return userHasPerm(state.user, 'qtm_can_get_quotes')
  },

  canManageCompany(state) {
    return userHasPerm(state.user, 'qtm_can_manage_company')
  },

  canManageUsers(state) {
    return userHasPerm(state.user, 'qtm_can_manage_users')
  },

  inSetupMode(state) {
    return state.user?.company?.setup_mode
  },

  isAuthenticated(state) {
    return !!state.authToken
  },

  isStaff(state) {
    return state.user && state.user.is_staff
  },

  purchasingEmail(state) {
    if (state.user && state.user.company && state.user.company.purchasing_email) {
      return state.user.company.purchasing_email
    }

    return ''
  },

  userCompany(state) {
    return state.user ? state.user.company : undefined
  },
}
