<template>
  <qtm-text-field v-bind="$attrs" @update:model-value="clean" />
</template>

<script>
export default {
  name: 'qtm-number-field',
  emits: ['update:model-value'],
  methods: {
    clean(value) {
      const cleanValue = value.replace(/[^0-9.-]/g, '')

      // Let any onChange handlers handle the first event before issuing a new event
      this.$emit('update:model-value', cleanValue)
    },
  }
}
</script>
