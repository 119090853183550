<template>
  <v-pagination
    v-if="numPages > 1 || showIfEmpty"
    v-bind="$attrs"
    color="secondary"
    :length="numPages"
    :model-value="modelValue"
  />
</template>

<script>
export default {
  name: 'qtm-pagination',
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    showIfEmpty: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    numPages() {
      return Math.ceil(this.count / this.perPage)
    },
  }
}
</script>

<style lang="scss">
.v-pagination__item,
.v-pagination__navigation {
  border: 1px solid rgb(var(--v-theme-light-grey));
  box-shadow: none;
}
</style>
