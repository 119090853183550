<template>
  <div>
    <div class="mb-3 qtm-h3">
      <span class="text-mid-grey" v-text="label" />
      {{ deliveryDate }}
    </div>
    <div class="qtm-label" v-text="deliveryText" />
    <div v-if="order.delivery_required" v-text="address" />
  </div>
</template>

<script>
import { objectDeliveryDate } from '~/models/filters'

export default {
  name: 'order-delivery-info',
  props: {
    address: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Arrives'
    },
    order: {
      type: Object,
      required: true
    },
  },
  computed: {
    deliveryDate() {
      return objectDeliveryDate(this.order)
    },
    deliveryText() {
      if (this.order.customer_pickup) {
        return 'HOLD FOR PICK-UP'
      }

      if (!this.order.delivery_required) {
        return 'NO DELIVERY REQUIRED'
      }

      return `DELIVER TO ${this.order.delivery_location.toUpperCase()}`
    }
  },
}
</script>
