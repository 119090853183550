<template>
  <qtm-content-block collapsible title="Order Info">
    <qtm-input-label v-if="isStaff" label="Order Owner" required>
      <qtm-autocomplete
        v-model="v$.order.owner.$model"
        class="mb-2"
        clearable
        :error-messages="ownerErrors"
        hide-details="auto"
        :item-title="userText"
        :items="users"
        :loading="loading"
        placeholder="Select an owner for this order"
        return-object
      />
    </qtm-input-label>

    <qtm-input-label label="Order Name" required>
      <qtm-text-field
        v-model="v$.order.reference_name.$model"
        class="mb-2"
        data-test="reference-name-input"
        :error-messages="errors.order.reference_name"
        hide-details="auto"
        maxlength="48"
        placeholder="Reference name for this order"
      />
    </qtm-input-label>

    <qtm-input-label label="Project" required>
      <jobsite-select v-model="v$.order.jobsite.$model" :error-messages="jobsiteError" :user="user" />
    </qtm-input-label>
  </qtm-content-block>
</template>

<script>
import { required, requiredIf, maxLength } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import JobsiteSelect from '@/components/jobsites/jobsite-select'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'

export default {
  name: 'order-info',
  components: { JobsiteSelect },
  mixins: [ValidationErrorsMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    order: {
      jobsite: { required },
      owner: {
        required: requiredIf(function () {
          return this.$store.getters['auth/isStaff']
        })
      },
      reference_name: {
        required,
        maxLength: maxLength(48),
      },
    }
  },
  data() {
    return {
      users: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isStaff']),
    jobsiteError() {
      return typeof this.errors.order.jobsite === 'string' ? this.errors.order.jobsite : undefined
    },
    ownerErrors() {
      if (this.v$.order.owner.$dirty) {
        if (!this.v$.order.owner.required) {
          return 'Owner is required'
        }
      }
      return undefined
    },
    user() {
      if (this.$store.getters['auth/isStaff']) {
        return this.order.owner
      }
      return undefined
    },
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers() {
      if (!this.$store.getters['auth/isStaff']) {
        return
      }

      this.loading = true
      try {
        this.users = await this.$api.v1.users.list()
        this.users.sort(
          (a, b) => {
            if (a.company && b.company) {
              return a.company.name.localeCompare(b.company.name)
              || a.first_name.localeCompare(b.first_name)
              || a.last_name.localeCompare(b.last_name)
            }

            return a.first_name.localeCompare(b.first_name)
              || a.last_name.localeCompare(b.last_name)
          }
        )
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    userText(user) {
      if (user.company) {
        return `${user.company.name} - ${user.first_name} ${user.last_name}`
      }
      return `${user.first_name} ${user.last_name}`
    },
  }
}
</script>
