<template>
  <nuxt-link
    v-bind="$attrs"
    :class="classes"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: 'qtm-link',
  props: {
    color: {
      type: String,
      default: 'interactive',
    },
    bold: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    classes() {
      const classes = [
        `text-${this.color}`,
        'text-decoration-none'
      ]
      if (this.bold) {
        classes.push('font-weight-bold')
      }

      return classes
    }
  }
}
</script>

<!--<style scoped lang="scss">-->
<!--</style>-->
