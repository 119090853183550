<template>
  <qtm-data-table :headers="columns" :items="skus">
    <template v-slot:item-name="item">
      {{ itemName(item) }}
    </template>

    <template v-slot:item-quantity="item">
      {{ Number(item.quantity) }}
      <span class="unit">
        {{ item.unit }}
      </span>
    </template>

    <template v-slot:item-rental_duration="item">
      <span class="text-capitalize">
        {{ rentalDuration(item) }}
      </span>
    </template>

    <template v-slot:item-cost_code="item">
      {{ item.cost_code ? item.cost_code : '&mdash;' }}
    </template>
  </qtm-data-table>
</template>

<script>
export default {
  name: 'sku-table',
  props: {
    order: {
      type: Object,
      required: true,
    },
    rental: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    skus() {
      return this.rental ? this.order.rentalSkus : this.order.purchaseSkus
    },

    columns() {
      return this.rental ? this.rentalHeaders : this.purchaseHeaders
    },

    purchaseHeaders() {
      return [
        { text: 'Item', value: 'name' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Cost Code', value: 'cost_code' },
        { text: 'Comment', value: 'comment' },
      ]
    },

    rentalHeaders() {
      return [
        { text: 'Item', value: 'name' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Rental Duration', value: 'rental_duration' },
        { text: 'Cost Code', value: 'cost_code' },
        { text: 'Comment', value: 'comment' },
      ]
    }
  },
  methods: {
    itemName(item) {
      return item.sku ? item.sku.name : item.description
    },

    rentalDuration(item) {
      if (!item.rental_duration) {
        return '—'
      }

      return `${item.rental_duration} ${item.rental_duration_unit}`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
