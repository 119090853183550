<template>
  <qtm-content-block collapsible title="Supplier">
    <qtm-input-label label="Supplier Name" required>
      <supplier-search
        append-icon=""
        :append-items="appendVendors"
        clearable
        :country="country"
        :error-messages="vendorError"
        :model-value="vendor"
        @update:model-value="selectVendor"
      />
      <vendor-create-line :create-vendor-options="{ vendorDetailsRequired: true }" @created="selectAndAppendVendor" />
    </qtm-input-label>

    <v-row dense>
      <v-col v-for="field in vendorAddressFields" :key="field.value" cols="12" :md="field.md">
        <qtm-input-label :label="field.label">
          <div v-text="vendor[field.value]" />
        </qtm-input-label>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <qtm-input-label label="Supplier Contact">
          <qtm-autocomplete
            v-model="salespersonProxy"
            clearable
            hide-details
            :item-title="contactText"
            :items="contacts"
            :loading="loading"
            placeholder="Search Existing Contacts"
            return-object
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="field in contactFields" :key="field.key" :cols="field.cols" :md="field.md">
        <qtm-input-label :label="field.label">
          <div v-if="!allowNewContact" v-text="field.text" />
          <qtm-text-field
            v-else-if="field.validated"
            v-model="v$.salesperson[field.key].$model"
            :disabled="verifiedSalesperson"
            :error-messages="field.errorMessages"
            hide-details="auto"
            :maxlength="field.maxLength"
          />
          <qtm-text-field
            v-else
            v-model="salesperson[field.key]"
            :disabled="verifiedSalesperson"
            hide-details
            :maxlength="field.maxLength"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script>
import { email, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import SupplierSearch from '@/components/search/supplier-search'
import VendorCreateLine from '@/components/vendors/vendor-create-line'

const blankSalesperson = {
  id: undefined,
  email: '',
  first_name: '',
  last_name: '',
  phone_extension: '',
  phone_number: ''
}
const vendorFields = [
  'id',
  'name',
  'address',
  'city',
  'province',
  'postal_code',
  'lat',
  'lon',
  'point',
  'contacts',
  'isNew',
]

export default {
  name: 'order-supplier',
  components: { SupplierSearch, VendorCreateLine },
  props: {
    allowNewContact: {
      type: Boolean,
      default: false
    },
    country: {
      type: String,
      default: undefined
    },
    salesperson: {
      type: Object,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    salesperson: {
      email: { email },
    },
    vendor: {
      id: { required },
    },
  },
  data() {
    return {
      contacts: [],
      appendVendors: [],
      loading: false,
    }
  },
  computed: {
    contactFields() {
      const fields = [
        {
          cols: 12, md: 6, key: 'first_name', label: 'First Name', maxLength: 50
        },
        {
          cols: 12, md: 6, key: 'last_name', label: 'Last Name', maxLength: 50
        },
        {
          cols: 12,
          md: 6,
          key: 'email',
          label: 'Email',
          errorMessages: this.v$.salesperson.email.$error ? 'Must be a valid email address' : '',
          validated: true,
        },
        {
          cols: 8, md: 4, key: 'phone_number', label: 'Phone', maxLength: 30
        },
      ]

      if (this.allowNewContact) {
        fields.push({
          cols: 4, md: 2, key: 'phone_extension', label: 'Phone Ext.', maxLength: 30
        })
      }
      else {
        fields.forEach(field => {
          if (field.key === 'phone_number') {
            field.text = this.salesperson.phone_number

            if (this.salesperson.phone_extension) {
              field.text += ` ext. ${this.salesperson.phone_extension}`
            }
          }
          else {
            field.text = this.salesperson[field.key]
          }
        })
      }

      return fields
    },
    salespersonProxy: {
      get() {
        return this.salesperson
      },
      set(value) {
        const salesperson = value || blankSalesperson

        Object.keys(salesperson).forEach(key => {
          this.salesperson[key] = salesperson[key]
        })
      }
    },
    vendorAddressFields() {
      if (!this.vendor.id) {
        return []
      }

      return [
        { value: 'address', label: 'Address' },
        { value: 'city', label: 'City', md: '4' },
        { value: 'province', label: 'State/Province', md: 'md' },
        { value: 'postal_code', label: 'ZIP/Postal', md: '4' },
      ]
    },
    vendorError() {
      if (this.v$.vendor.id.$dirty && this.v$.vendor.id.$invalid) {
        return 'A supplier is required'
      }

      return undefined
    },
    verifiedSalesperson() {
      return !!this.salesperson.id
    },
  },
  mounted() {
    if (this.vendor.id) {
      this.appendVendors = [{ ...this.vendor }]
      this.fetchContacts()
    }
  },
  methods: {
    clearVendor() {
      vendorFields.forEach(field => {
        this.vendor[field] = undefined
      })

      this.contacts = []
      this.salespersonProxy = undefined
    },
    contactText(contact) {
      if (contact?.first_name) {
        return `${contact.first_name} ${contact.last_name} (${contact.email})`
      }
      return ''
    },
    async fetchContacts() {
      this.loading = true
      try {
        const vendor = await this.$api.v1.vendors.get(this.vendor.id)

        this.contacts = vendor.contacts
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    isValid() {
      this.v$.$touch()
      return !this.v$.$invalid
    },
    selectAndAppendVendor(vendor) {
      this.appendVendors.push(vendor)
      this.selectVendor(vendor)
    },
    selectVendor(vendor) {
      if (!vendor) {
        this.clearVendor()
        return
      }

      vendorFields.forEach(field => {
        this.vendor[field] = vendor[field]
      })

      this.contacts = vendor.contacts || []

      if (!this.contacts.some(contact => contact.id === this.salesperson.id)) {
        this.salespersonProxy = undefined
        this.fetchContacts()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
