<template>
  <div>
    <div class="file-dropper-border">
      <div
        class="align-center d-flex file-dropper justify-center pa-2"
        :class="innerClass"
        @dragleave="dragLeave"
        @dragover="dragOver"
        @drop="drop"
      >
        <transition mode="out-in" name="fade">
          <div class="text-mid-grey text-center">
            <div v-if="loading">
              <v-progress-circular color="secondary" :indeterminate="!progress" :model-value="progress" />
              <div>
                <slot name="progress.append" />
              </div>
            </div>
            <div v-else>
              <label class="file-dropper-text" @click="selectFile">
                <span class="font-weight-bold">
                  <span class="text-interactive">{{ fileInputLinkText }}</span>
                  {{ fileDropText }}
                </span>
                <span class="file-type-details">{{ fileTypeDetailText }}</span>
              </label>
              <div>
                <slot />
              </div>
            </div>
          </div>
        </transition>
      </div>
      <input
        v-show="false"
        ref="fileInput"
        :multiple="multiple"
        :accept="accept"
        type="file"
        @change="fileSelected"
      >
    </div>
    <div class="text-error file-dropper-error">
      {{ errorMessages }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'file-dropper',
  props: {
    accept: {
      type: String,
      default: '*'
    },
    color: {
      type: String,
      default: 'transparent'
    },
    errorMessages: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: [File, Array],
      default: undefined
    },
    fileDropText: {
      type: String,
      default: 'or drag and drop'
    },
    fileInputLinkText: {
      type: String,
      default: 'Upload a file'
    },
    fileTypeDetailText: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:model-value'],
  data() {
    return {
      highlight: false,
    }
  },
  computed: {
    innerClass() {
      if (this.expanded) {
        return 'expanded'
      }
      return this.highlight ? undefined : `bg-${this.color}`
    },
  },
  methods: {
    dragLeave() {
      this.highlight = false
    },
    dragOver(event) {
      event.preventDefault()
      this.highlight = true
    },
    drop(event) {
      event.preventDefault()
      const files = event.dataTransfer.files
      const objectToEmit = this.multiple ? Array.from(files) : files[0]
      this.$emit('update:model-value', objectToEmit)
      this.$emit('change', objectToEmit)
      this.highlight = false
    },
    fileSelected() {
      const files = this.$refs.fileInput.files
      const objectToEmit = this.multiple ? Array.from(files) : files[0]
      this.$emit('update:model-value', objectToEmit)
      this.$emit('change', objectToEmit)
    },
    selectFile() {
      this.$refs.fileInput.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.file-dropper {
  min-height: 85px;
  transition: background 0.25s;
  text-align: center;
}
.file-dropper.expanded {
  background-color: rgba(255, 255, 255, 0.85);;
  border: 3px dashed rgb(var(--v-theme-secondary));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.file-dropper-border {
  border: 2px dashed rgb(var(--v-theme-light-grey));
}

.file-dropper-error {
  height: 1.5rem;
  padding-left: 1rem;
  text-align: center;
}

.file-dropper-text {
  cursor: pointer;
}

.file-type-details {
  display: block
}
</style>
