<template>
  <div>
    <div v-for="attachment in attachments" :key="attachment.id">
      <a :download="attachment.name" :href="attachment.url" target="_blank">{{ attachment.name }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'attachment-links-list',
  props: {
    attachments: {
      type: Array,
      required: true
    }
  }
}
</script>
