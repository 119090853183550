<template>
  <v-avatar class="user-avatar" :class="disabled ? 'lighten-3' : ''" :color="dark ? color : 'white'" :size="avatarSize">
    <span :class="textColorClass" :style="fontSize">
      {{ user.first_name[0] }}{{ user.last_name[0] }}
    </span>
  </v-avatar>
</template>

<script>
const sizes = {
  md: { avatar: 24, font: 10 },
  lg: { avatar: 40, font: 16 },
  xl: { avatar: 48, font: 20 },
}

export default {
  name: 'user-avatar',
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String],
      default: 'md'
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    avatarSize() {
      const size = sizes[this.size] || sizes.md

      return size.avatar
    },
    fontSize() {
      const size = sizes[this.size] || sizes.md

      return `font-size: ${size.font}px`
    },
    textColorClass() {
      return this.dark ? 'text-white' : 'text-secondary'
    }
  }
}
</script>

<style lang="scss" scoped>
.user-avatar {
  font-weight: 700;
}
</style>
