<template>
  <v-btn v-bind="attrs" :class="btnClass">
    <slot />
  </v-btn>
</template>

<script>
const defaultWidth = 300

const primaryAttrs = {
  color: 'primary',
  variant: 'flat',
}

const secondaryAttrs = {
  ...primaryAttrs,
  variant: 'outlined',
}

const tertiaryAttrs = {
  color: 'interactive',
  variant: 'text',
}

export default {
  name: 'qtm-btn',
  props: {
    defaultWidth: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    attrs() {
      let attrs

      if (this.secondary) {
        attrs = secondaryAttrs
      }
      else if (this.tertiary) {
        attrs = tertiaryAttrs
      }
      else {
        attrs = primaryAttrs
      }

      return {
        ...attrs,
        ...this.$attrs,
        width: this.defaultWidth ? defaultWidth : this.$attrs.width,
      }
    },
    btnClass() {
      if (this.tertiary) {
        return 'qtm-h3'
      }

      return 'qtm-cta text-uppercase'
    }
  }
}
</script>
