import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_sVCuMR6hEC from "/opt/build/repo/plugins/axios.js";
import error_handler_VFH3VvK7yG from "/opt/build/repo/plugins/error-handler.js";
import materials_api_jGj9jNCU5O from "/opt/build/repo/plugins/materials-api.js";
import mixpanel_flxmgVYaFE from "/opt/build/repo/plugins/mixpanel.js";
import sentry_PZVGm6LH3G from "/opt/build/repo/plugins/sentry.js";
import toast_Zl2rZYKMaV from "/opt/build/repo/plugins/toast.js";
import vuetify_8NhHJigKc1 from "/opt/build/repo/plugins/vuetify.js";
import vuex_owYp5qnaH8 from "/opt/build/repo/plugins/vuex.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  axios_sVCuMR6hEC,
  error_handler_VFH3VvK7yG,
  materials_api_jGj9jNCU5O,
  mixpanel_flxmgVYaFE,
  sentry_PZVGm6LH3G,
  toast_Zl2rZYKMaV,
  vuetify_8NhHJigKc1,
  vuex_owYp5qnaH8
]