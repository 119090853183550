<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <div class="font-weight-bold">
          Requested Scope of Work
        </div>
        <div class="order-scope show-newlines" v-text="order.scope_of_work" />
      </v-col>
      <v-col cols="6">
        <div class="font-weight-bold mb-3">
          Scope of Work
        </div>
        <qtm-textarea
          v-model="quote.scope_of_work"
          auto-grow
          :maxlength="maxScopeOfWorkLength"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MAX_TEXTAREA_LENGTH } from '@/constants'

export default {
  name: 'quote-comment',
  props: {
    maxScopeOfWorkLength: {
      type: Number,
      default: MAX_TEXTAREA_LENGTH,
    },
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.order-scope {
  margin: 0.5rem;
  border-left: 3px solid rgb(var(--v-theme-light-grey));
  padding-left: 0.25rem;
}
</style>
