<template>
  <tr>
    <td>
      <delete-btn @click="$emit('remove', vendor)" />
    </td>
    <td>
      {{ vendor.name }}
    </td>
    <td>
      <vendor-contact-select
        :vendor="vendor"
        @update:model-value="$emit('contact-selected', $event)"
        @new-contact="$emit('contact-selected', $event)"
      />
    </td>
    <td>
      {{ vendor.city }}
    </td>
    <td>
      {{ vendor.province }}
    </td>
  </tr>
</template>

<script>
import DeleteBtn from '@/components/delete-btn'
import VendorContactSelect from '@/components/vendors/vendor-contact-select'

export default {
  name: 'vendor-invite-item',
  components: { DeleteBtn, VendorContactSelect },
  props: {
    vendor: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['contact-selected', 'remove'],
}
</script>
