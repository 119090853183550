<template>
  <div class="qtm-border wrapper">
    <v-icon class="paperclip" color="mid-light-grey">
      mdi-paperclip
    </v-icon>
    <v-hover v-slot="{ hover }" :disabled="!shouldDownload">
      <div class="attachment-preview-download pa-1" :title="name" @click="emit">
        <v-img
          v-if="isImage"
          :alt="name"
          contain
          max-height="98%"
          max-width="98%"
          :src="src"
        />
        <div v-else>
          {{ name }}
          <v-icon class="file-icon" color="mid-light-grey">
            {{ icon }}
          </v-icon>
          <v-overlay absolute color="white" opacity="0.8" :model-value="hover">
            <a class="text-decoration-none" :href="src" target="_blank">
              <div class="text-center">
                <v-icon color="interactive">
                  mdi-download
                </v-icon>
              </div>
              <div class="font-weight-bold text-interactive">
                Download
              </div>
            </a>
          </v-overlay>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
import AttachmentsMixin from '@/mixins/attachments-mixin'

const iconsByExtension = {
  docx: 'mdi-file-word-box',
  pdf: 'mdi-file-pdf-box',
  xlsx: 'mdi-file-excel-box',
}

export default {
  name: 'attachment-preview-download',
  mixins: [AttachmentsMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
  },
  emits: ['click'],
  computed: {
    icon() {
      const extension = this.name.split('.').pop()

      return iconsByExtension[extension] || 'mdi-file'
    },
    shouldDownload() {
      return !this.isImage && !this.isPDF
    }
  },
  methods: {
    emit() {
      if (!this.shouldDownload) {
        this.$emit('click')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$height: 104px;
$width: 80px;

.attachment-preview-download {
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  max-height: $height;
  max-width: $width;
  min-height: $height;
  min-width: $width;
  overflow-y: hidden;
}

.file-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.paperclip {
  position: absolute;
  left: -10px;
  top: -10px;
  transform: rotate(225deg);
  z-index: 10;
}

.wrapper {
  display: inline-block;
  max-height: $height;
  max-width: $width;
  min-height: $height;
  min-width: $width;
  position: relative;
}
</style>
