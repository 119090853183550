<template>
  <v-container>
    <v-row align-content="stretch">
      <v-col
        v-for="quote in quotes"
        :key="quote.id"
        align-self="stretch"
        cols="12"
        md="4"
        sm="6"
      >
        <quote-summary-card :quote="quote" :order="order" @click="$emit('select', $event)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuoteSummaryCard from '@/components/quotes/quote-summary-card'

export default {
  name: 'quotes-grid',
  components: { QuoteSummaryCard },
  props: {
    quotes: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['select'],
}
</script>
