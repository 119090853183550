<template>
  <div>
    <v-expansion-panels v-model="openPanels" multiple>
      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title class="font-weight-bold">
          Added Vendors
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="d-flex align-center justify-end">
            <div class="flex-grow-1" />
            <div class="flex-grow-1 pa-3">
              <supplier-search
                density="compact"
                :model-value="unmatchedVendor"
                @update:model-value="addUnmatchedVendor"
              />
            </div>
            <div>
              <qtm-btn secondary @click="showCreateVendorDialog = true">
                Add Vendor
              </qtm-btn>
              <create-vendor-dialog
                v-model="showCreateVendorDialog"
                vendor-details-required
                with-contact
                @vendor-created="addUnmatchedVendor"
              />
            </div>
          </div>
          <vendor-invite-table
            v-if="order.quotes.length"
            :contact-selected-handler="updateQuoteContact"
            :loading="loading"
            :vendors="addedVendors"
            @remove="removeVendor"
          />
          <v-alert v-else type="info">
            No vendors have been added. You can add them below.
          </v-alert>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title class="font-weight-bold">
          Order
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <order-detail :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog'
import OrderDetail from '@/components/orders/order-detail'
import SupplierSearch from '@/components/search/supplier-search'
import VendorInviteTable from '@/components/vendors/vendor-invite-table'

export default {
  name: 'review-vendors',
  components: { CreateVendorDialog, OrderDetail, SupplierSearch, VendorInviteTable },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openPanels: [0],
      loading: false,
      unmatchedVendor: {},
      showCreateVendorDialog: false
    }
  },
  computed: {
    addedVendors() {
      return this.order.quotes.map(q => (
        {
          ...q.vendor,
          contact: q.salesperson ? q.salesperson.id : undefined,
          quote: q.id,
        }
      ))
    },
  },
  methods: {
    async addVendor(vendor) {
      this.loading = true
      const vendorData = { vendor: vendor.id, contact: vendor.contact }
      try {
        const updatedOrder = await this.$api.v1.rfqs.vendors.add(this.order, vendorData)
        this.$store.commit('admin/updateOrder', updatedOrder)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async removeVendor(vendor) {
      this.loading = true
      const vendorData = { vendor: vendor.id }
      try {
        const updatedOrder = await this.$api.v1.rfqs.vendors.remove(this.order, vendorData)
        this.$store.commit('admin/updateOrder', updatedOrder)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async addUnmatchedVendor(vendor) {
      if (vendor.id) {
        await this.addVendor(vendor)
      }

      this.unmatchedVendor = {}
    },
    async updateQuoteContact(quoteId, contact) {
      this.loading = true
      const quoteData = { salesperson_id: contact.id ? contact.id : contact }
      try {
        const updatedQuote = await this.$api.v1.quotes.patch(quoteId, quoteData)
        const oldQuote = this.order.quotes.find(q => q.id === updatedQuote.id)

        Object.assign(oldQuote, updatedQuote)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    }
  }
}
</script>
