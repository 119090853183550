<template>
  <qtm-content-block collapsible title="Comment">
    <qtm-textarea
      v-bind="$attrs"
      auto-grow
      :maxlength="maxlength"
      rows="1"
    />
  </qtm-content-block>
</template>

<script>
import { MAX_TEXTAREA_LENGTH } from '@/constants'

export default {
  name: 'order-comment',
  props: {
    maxlength: {
      type: Number,
      default: MAX_TEXTAREA_LENGTH,
    },
  },
}
</script>
