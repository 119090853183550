<template>
  <tbody class="cart-items-row">
    <tr>
      <td class="name-cell" rowspan="2">
        <qtm-textarea
          v-if="canEdit"
          v-model.trim="v$.item.description.$model"
          data-test="description-input"
          density="compact"
          :error="!!errors.item.description"
          hide-details
          :maxlength="skuNameLength"
          no-resize
          placeholder="Item Description"
          rows="3"
          @focus="drawBoundingBox(item, 'description')"
          @blur="removeBoundingBox(item, 'description')"
        />
        <span v-else>
          {{ item.description }}
        </span>
      </td>

      <td class="quantity-cell">
        <qtm-number-field
          v-model.trim="v$.item.quantity.$model"
          data-test="quantity-input"
          density="compact"
          :error="!!errors.item.quantity"
          hide-details
          :maxlength="quantityLength"
          @focus="drawBoundingBox(item, 'quantity')"
          @blur="removeBoundingBox(item, 'quantity')"
        />
      </td>

      <td v-if="!rental" class="unit-cell">
        <qtm-text-field
          v-if="useTextInputForUnits"
          v-model.trim="v$.item.unit.$model"
          density="compact"
          :error="!!errors.item.unit"
          hide-details
          :maxlength="unitLength"
        />
        <qtm-select
          v-else
          v-model.trim="v$.item.unit.$model"
          density="compact"
          hide-details
          item-title="description"
          item-value="code"
          :items="units"
          :error="!!errors.item.unit"
        />
      </td>

      <td v-if="rental" class="rental-duration-cell">
        <div class="d-flex">
          <qtm-number-field
            v-model.trim="v$.item.rental_duration.$model"
            data-test="rental-duration-input"
            density="compact"
            :error="!!errors.item.rental_duration"
            hide-details
            :maxlength="durationLength"
            style="min-width: 40px; max-width: 50px"
          />
          <qtm-select
            v-model="item.rental_duration_unit"
            density="compact"
            hide-details
            item-value="value"
            :items="rentalDurationChoices"
            style="min-width: 100px; max-width: 100px"
          />
        </div>
      </td>

      <template v-if="rental && withPrices">
        <td v-for="(unit, i) in rentalUnits" :key="i" :class="`${unit}-rate-cell`">
          <qtm-number-field
            v-if="canEdit"
            v-model.trim="v$.item[`${unit}_rate`].$model"
            :data-test="`${unit}-rate-input`"
            density="compact"
            :error="!!errors.item[`${unit}_rate`]"
            hide-details
            :maxlength="priceLength"
          />
          <span v-else>
            {{ currency(item[`${unit}_rate`]) }}
          </span>
        </td>
      </template>

      <td v-if="!rental && withPrices" class="unit-price-cell">
        <qtm-number-field
          v-if="canEdit"
          v-model="v$.item.unit_price.$model"
          data-test="unit-price-input"
          density="compact"
          :error="!!errors.item.unit_price"
          hide-details
          :maxlength="priceLength"
        />
        <span v-else>
          {{ currency(item.unit_price, unitPriceDecimalPlaces) }}
        </span>
      </td>

      <td v-if="withCostCode" class="cost-code-cell">
        <cost-code-select
          v-model.trim="v$.item.cost_code.$model"
          :cost-codes="costCodes"
          density="compact"
          :error="!!errors.item.cost_code"
          :force-select="forceCostCodeSelect"
          @update:model-value="$emit('cost-code-changed', $event)"
        />
      </td>

      <td v-if="withTotalPriceHelper" class="unit-price-cell">
        <total-price-helper
          v-model="v$.item.unit_price.$model"
          :quantity="item.quantity"
          @focus="drawBoundingBox(item, 'price')"
          @blur="removeBoundingBox(item, 'price')"
        />
      </td>

      <td rowspan="2">
        <delete-btn @click="$emit('remove', item)" />
        <qtm-icon-btn
          v-if="item.boundingBox || item.confidence"
          :icon="`mdi-format-vertical-align-${ rental ? 'top' : 'bottom' }`"
          @click="transform"
        />
      </td>
    </tr>
    <tr>
      <td class="comment-cell" :colspan="commentColSpanWithTaxes">
        <div class="d-flex">
          <qtm-text-field
            v-model.trim="item.comment"
            density="compact"
            hide-details
            :maxlength="skuNameLength"
            placeholder="Comment"
          />
          <div v-if="showUnitInRentals" class="unit-in-rentals">
            <qtm-text-field
              v-if="useTextInputForUnits"
              v-model.trim="$v.item.unit.$model"
              density="compact"
              :error="!!errors.item.unit"
              hide-details
              placeholder="Unit"
              :maxlength="unitLength"
            />
            <qtm-select
              v-else
              v-model.trim="v$.item.unit.$model"
              density="compact"
              hide-details
              item-title="description"
              item-value="code"
              placeholder="Unit"
              :items="units"
              :error="!!errors.item.unit"
            />
          </div>
        </div>
      </td>
      <td v-if="showTaxes">
        <tax-select
          v-if="!isReadOnlyTaxesItem"
          v-model="item.tax"
          :taxes="taxes"
          density="compact"
          hide-details
          placeholder="Tax"
          @update:model-value="$emit('tax-changed', $event)"
        />
        <v-tooltip v-else location="bottom">
          <template v-slot:activator="{ props }">
            <span v-bind="props" v-text="taxLabel" />
          </template>
          Taxes can only be set on new items
        </v-tooltip>
      </td>
    </tr>
  </tbody>
</template>

<script>
import {
  decimal, integer, minValue, required, requiredIf
} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {
  DURATION_MAX_LENGTH,
  PRICE_MAX_LENGTH,
  QUANTITY_MAX_LENGTH,
  SKU_NAME_LENGTH,
  SKU_UNIT_LENGTH,
  UNIT_PRICE_DECIMAL_PLACES,
} from '@/constants'
import { decimalPlaces } from '@/validators'
import { currency } from '~/models/filters'
import CostCodeSelect from '@/components/cost-codes/cost-code-select'
import DeleteBtn from '@/components/delete-btn'
import TaxSelect from '@/components/orders/tax-select'
import TotalPriceHelper from '@/components/skus/total-price-helper'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'

export default {
  name: 'cart-items-row',
  components: { CostCodeSelect, DeleteBtn, TaxSelect, TotalPriceHelper },
  mixins: [ValidationErrorsMixin],
  validations() {
    return {
      item: {
        cost_code: {
          required: requiredIf(function () {
            return this.requireCostCodes
          }),
        },
        day_rate: {
          decimalPlaces: decimalPlaces(2),
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental && this.withPrices && !this.item.week_rate && !this.item.month_rate
          }),
        },
        description: {
          required,
        },
        month_rate: {
          decimalPlaces: decimalPlaces(2),
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental && this.withPrices && !this.item.day_rate && !this.item.week_rate
          }),
        },
        quantity: {
          decimal,
          decimalPlaces: decimalPlaces(2),
          required,
        },
        rental_duration: {
          integer,
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental
          }),
        },
        unit: {
          required: requiredIf(function () {
            return !this.rental
          }),
          validChoice: (item) => {
            return this.units.length === 0 || this.units.some(unit => unit.code === item)
          },
        },
        unit_price: {
          decimal,
          decimalPlaces: decimalPlaces(UNIT_PRICE_DECIMAL_PLACES),
          minValue: minValue(0),
          required: requiredIf(function () {
            return !this.rental && this.withPrices
          }),
        },
        week_rate: {
          decimalPlaces: decimalPlaces(2),
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental && this.withPrices && !this.item.day_rate && !this.item.month_rate
          }),
        },
      },
    }
  },
  props: {
    commentColSpan: {
      type: Number,
      default: 1
    },
    costCodes: {
      type: Array,
      default: () => []
    },
    durationLength: {
      type: Number,
      default: DURATION_MAX_LENGTH,
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    makeAdjustments: {
      type: Boolean,
      default: false
    },
    readOnlyTaxes: {
      type: Boolean,
      default: false
    },
    priceLength: {
      type: Number,
      default: PRICE_MAX_LENGTH,
    },
    quantityLength: {
      type: Number,
      default: QUANTITY_MAX_LENGTH,
    },
    rental: {
      type: Boolean,
      default: false
    },
    requireCostCodes: {
      type: Boolean,
      default: false
    },
    skuNameLength: {
      type: Number,
      default: SKU_NAME_LENGTH,
    },
    taxes: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    validateUnits: {
      type: Boolean,
      default: false
    },
    withCostCode: {
      type: Boolean,
      default: false
    },
    withPrices: {
      type: Boolean,
      default: false
    },
    withTotalPriceHelper: {
      type: Boolean,
      default: false
    },
    unitLength: {
      type: Number,
      default: SKU_UNIT_LENGTH,
    },
    unitPriceDecimalPlaces: {
      type: Number,
      default: UNIT_PRICE_DECIMAL_PLACES,
    },
  },
  emits: ['cost-code-changed', 'remove', 'tax-changed'],
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  computed: {
    canEdit() {
      return !this.makeAdjustments || this.item.isNew
    },
    commentColSpanWithTaxes() {
      return this.showTaxes ? (this.commentColSpan - 1) : this.commentColSpan
    },
    isReadOnlyTaxesItem() {
      return this.readOnlyTaxes && !this.item.isNew
    },
    rentalDurationChoices() {
      return [
        { text: 'Days', value: 'days' },
        { text: 'Weeks', value: 'weeks' },
        { text: '28-Day Months', value: 'months' },
      ]
    },
    rentalUnits() {
      return ['day', 'week', 'month']
    },
    showUnitInRentals() {
      if (!this.rental) return false

      if (!this.item.unit) return true

      if (!this.useTextInputForUnits) {
        const currentUnitExistsInUnitsList = this.units.find(u => u.code === this.item.unit)
        if (!currentUnitExistsInUnitsList) return true
      }

      return false
    },
    showTaxes() {
      return !!this.taxes.length
    },
    taxLabel() {
      const tax = this.taxes.find(t => t.id === this.item.tax)
      return tax ? tax.label : 'No tax'
    },
    useTextInputForUnits() {
      return !this.units || !this.units.length
    },
  },
  methods: {
    currency(value, decimals = 2) {
      return currency(value, decimals)
    },
    drawBoundingBox(item, field) {
      this.executeBoundingBoxOperation(item, field, 'addAttachmentBoundingBox')
    },
    removeBoundingBox(item, field) {
      this.executeBoundingBoxOperation(item, field, 'removeAttachmentBoundingBox')
    },
    executeBoundingBoxOperation(item, field, operation) {
      if (item && item.boundingBox && item.confidence) {
        const position = item.boundingBox[field]
        const confidence = item.confidence[field]
        if (position && confidence) {
          this.$store.commit(`admin/${operation}`, { position, confidence })
        }
      }
    },
    transform() {
      if (this.rental) {
        this.item.rental_duration_unit = null
        this.item.unit_price = this.item.unit_price ? this.item.unit_price : this.item.day_rate
        this.item.day_rate = null
        this.item.week_rate = null
        this.item.month_rate = null
        this.item.rental_duration = null
      }
      else {
        this.item.rental_duration_unit = 'days'
        this.item.day_rate = this.item.day_rate
          ? this.item.day_rate : Number.parseFloat(this.item.unit_price).toFixed(2)
        this.item.rental_duration = this.item.rental_duration ? this.item.rental_duration : 1
        this.item.unit_price = null
      }
    },
  }
}
</script>

<style lang="scss" scoped>
td {
  padding: 0.25rem;
}

.cart-items-row {
  background-color: rgb(var(--v-theme-background));
  border: 1px solid rgb(var(--v-theme-light-grey));
}

.name-cell {
  min-width: 10rem;
}

.quantity-cell {
  min-width: 7rem;
}

.unit-in-rentals {
  margin-left: 16px;
  width: 20%
}

.cost-code-cell {
  width: 10rem;
}

.unit-cell {
  min-width: 6rem;
  width: 13rem;
}

.day-rate-cell, .week-rate-cell {
  min-width: 6rem;
}

.month-rate-cell, .unit-price-cell {
  min-width: 7.5rem;
}

.rental-duration-cell {
  min-width: 10rem;
}

.rental-duration-unit-cell {
  min-width: 13rem;
}
</style>
