<template>
  <qtm-content-block
    v-bind="$attrs"
    :collapsed="po && po.isIssued"
    :collapsible="po && po.isIssued"
  >
    <template v-slot:title>
      <div class="qtm-h1 text-secondary text-uppercase pl-10 mb-n4">
        {{ title }}
        <v-chip
          v-if="!order.cancelled && po && po.isIssued"
          label
          class="ml-1 mt-n1 po-issued text-secondary"
          color="light-teal"
          text="PO ISSUED"
          variant="flat"
        />
      </div>
    </template>
    <v-list>
      <v-list-group
        v-if="approvals.length > 3"
        v-model="expanded"
        append-icon=""
        color="interactive"
      >
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="text-interactive">
              {{ expanded ? "View Less" : "View More" }}
              <v-icon color="interactive">
                {{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
        </template>
        <approval-activity-item
          v-for="approval in approvals.slice(0, -3)"
          :key="approval.id"
          :approval="approval"
        />
      </v-list-group>
      <approval-activity-item
        v-for="approval, i in abridged"
        :key="approval.id"
        :approval="approval"
        :final="i+1===abridged.length"
      />
    </v-list>
  </qtm-content-block>
</template>
<script>
import ApprovalActivityItem from '@/components/orders/approval-activity-item'

export default {
  name: 'approval-activity',
  components: { ApprovalActivityItem },
  props: {
    order: {
      type: Object,
      required: true,
    },
    po: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: 'PO Activity',
    },
  },
  data() {
    return {
      approvals: [],
      loading: true,
      expanded: false,
    }
  },
  computed: {
    abridged() {
      if (this.approvals.length <= 3) {
        return this.approvals
      }
      return this.approvals.slice(-3)
    },
  },
  mounted() {
    this.fetchApprovals()
  },
  methods: {
    async fetchApprovals() {
      try {
        this.approvals = this.parseApprovals(
          await this.$api.v1.rfqs.approvals(this.order)
        )
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    parseApprovals(data) {
      const approvals = []

      approvals.push({
        title: 'Created',
        date_created: this.order.date_created,
        action_user: `${this.order.owner.first_name} ${this.order.owner.last_name}`,
        tag: 'by',
        icon_class: 'check-icon',
      })

      for (let i = 0; i < data.length; i += 1) {
        const approval = data[i]

        if (approval.entry_type === 'REQ' && i + 1 < data.length) {
          continue
        }

        approval.title = 'Reviewed'
        approval.tag = 'by'
        approval.icon_class = 'check-icon'
        approval.action_user = `${approval.user.first_name} ${approval.user.last_name}`

        if (i + 1 === data.length) {
          if (approval.entry_type === 'APR' && this.po && this.po.isIssued) {
            approval.title = 'Approved'
          }
          else if (approval.entry_type === 'REQ') {
            approval.title = 'Waiting for review/approval'
            approval.tag = 'from'
            approval.icon_class = 'unchecked check-icon'
            if (approval.requested_approver) {
              approval.action_user = (
                `${approval.requested_approver.first_name} ${
                  approval.requested_approver.last_name}`
              )
            }
            else {
              approval.action_user = 'a team member'
            }
          }
        }
        approvals.push(approval)
      }
      return approvals
    },
  }
}
</script>
<style>
.po-issued {
  font-size: 0.8em !important;
  font-weight: normal;
}
.expand-btn {
    align-self: flex-start;
}
</style>
