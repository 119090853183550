<template>
  <qtm-text-field
    v-maska:[options]
    v-bind="$attrs"
    :error-messages="errorMessages"
    :placeholder="mask"
  />
</template>

<script>
import { vMaska } from 'maska'
import { PHONE_MASK } from '@/constants'

export default {
  name: 'phone-input',
  directives: { maska: vMaska },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'A 10 digit number is required'
    },
    mask: {
      type: String,
      default: PHONE_MASK
    }
  },
  data() {
    return {
      options: {
        mask: PHONE_MASK,
      }
    }
  },
  computed: {
    errorMessages() {
      return this.error ? this.errorMessage : undefined
    }
  }
}
</script>
