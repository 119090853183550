<template>
  <v-radio-group v-bind="$attrs" color="interactive">
    <slot>
      <qtm-radio
        v-for="item in items"
        v-bind="item"
        :key="item[itemKey]"
        :class="itemClass"
        :value="item[itemValue]"
      />
    </slot>
  </v-radio-group>
</template>

<script>
export default {
  name: 'qtm-radio-group',
  props: {
    label: {
      type: String,
      default: ''
    },
    itemClass: {
      type: String,
      default: undefined
    },
    itemKey: {
      type: String,
      default: 'label'
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'id'
    },
  },
}
</script>
