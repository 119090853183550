<template>
  <component
    :is="component"
    v-bind="$attrs"
    data-test="cost-code-select"
    :hide-details="hideDetails"
    item-value="value"
    :items="costCodeItems"
    maxlength="20"
    :return-object="false"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'cost-code-select',
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    forceSelect: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: true
    },
  },
  computed: {
    ...mapGetters('auth', ['allowCustomCostCodes']),
    component() {
      return !this.forceSelect && this.allowCustomCostCodes ? 'qtm-combobox' : 'qtm-autocomplete'
    },
    costCodeItems() {
      return this.costCodes.map(c => ({ text: `${c.cost_code} - ${c.description}`, value: c.cost_code }))
    }
  },
}
</script>
