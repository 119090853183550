<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="6">
        <qtm-input-label label="First Name" required>
          <qtm-text-field
            v-model="v$.contact.first_name.$model"
            data-test="contact-first-name-input"
            :disabled="disabled"
            :error-messages="errors.contact.first_name"
            hide-details="auto"
            :maxlength="contactMaxNameLength"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="12" md="6">
        <qtm-input-label label="Last Name">
          <qtm-text-field
            v-model="contact.last_name"
            data-test="contact-last-name-input"
            :disabled="disabled"
            hide-details
            :maxlength="contactMaxNameLength"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <qtm-input-label label="Phone" :required="phoneRequired">
          <phone-input
            v-model="v$.contact.phone_number.$model"
            data-test="contact-phone-input"
            :disabled="disabled"
            :error="!!errors.contact.phone_number"
            hide-details="auto"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="3">
        <qtm-input-label label="Phone Ext.">
          <qtm-text-field
            v-model="contact.phone_extension"
            :disabled="disabled"
            data-test="contact-phone-extension-input"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <qtm-input-label label="Email" :required="emailRequired">
          <qtm-text-field
            v-model="v$.contact.email.$model"
            data-test="contact-email-input"
            :disabled="disabled"
            :error-messages="errors.contact.email"
            hide-details="auto"
            style="max-width: 450px"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { email, required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import PhoneInput from '@/components/phone-input'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'
import { CONTACT_MAX_NAME_LENGTH, phoneNumber } from '@/constants'

export default {
  name: 'contact-form',
  components: { PhoneInput },
  mixins: [ValidationErrorsMixin],
  props: {
    contact: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emailRequired: {
      type: Boolean,
      default: false
    },
    phoneRequired: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    contact: {
      first_name: { required },
      email: {
        email,
        required: requiredIf(function () {
          return this.emailRequired
        })
      },
      phone_number: {
        phoneNumber,
        required: requiredIf(function () {
          return this.phoneRequired
        })
      },
    },
  },
  data() {
    return {
      contactMaxNameLength: CONTACT_MAX_NAME_LENGTH,
    }
  },
  computed: {
    errorsByKey() {
      return {
        contact: {
          email: 'Please use a valid email address',
        }
      }
    }
  },
  methods: {
    resetValidation() {
      this.v$.$reset()
    }
  }
}
</script>
