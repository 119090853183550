<script>
import moment from 'moment'
/*
  Mixin property naming convention:
  https://vuejs.org/v2/style-guide/#Private-property-names-essential

  Walks the vuelidate `v$` object to find fields that are in an error state.

  The generated `errors` object mirrors the values in the `v$` object.
  For example given:

  validations: {
    object: {
      childObject: {
        childField: {...},
      },
      objectField: {...},
    },
    field: {...},
  }

  Will result in the following errors object:

  {
    object: {
      childObject: {
        childField: error?,
      },
      objectField: error?,
    },
    field: error?,
  }

  The `errorsByKey` object must mirror the `v$` object.
  For example, to set the error message for `childField` only, implement the following:

  errorsByKey() {
    return {
      object: {
        childObject: {
          childField: 'Some error message'
        }
      }
    }
  }
*/
export default {
  name: 'validation-errors',
  data() {
    return {
      defaultError: 'This field is required'
    }
  },
  computed: {
    errors() {
      return this.$_validationErrors_getObjectErrors(this.v$)
    },
    errorsByKey() {
      return {}
    },
  },
  methods: {
    isValid() {
      this.v$.$touch()

      return !this.v$.$invalid
    },
    $_validationErrors_getObjectErrors(obj, keyHistory = []) {
      const errors = {}
      const keys = keyHistory

      Object.keys(obj)
        .filter(key => key[0] !== '$')
        .forEach(key => {
          keys.push(key)
          // Field errors
          if (!(typeof obj[key].$model === 'object') || obj[key].$model === null) {
            if (obj[key].$dirty && obj[key].$invalid) {
              errors[key] = this.$_validationErrors_getObjectErrorByKeys(keys)
            }
          }
          // Array errors
          else if (obj[key].$each) {
            errors[key] = []
            const iter = obj[key].$each.$iter

            Object.keys(iter)
              .filter(i => iter[i].$dirty && iter[i].$invalid)
              .forEach(i => {
                errors[key][i] = this.$_validationErrors_getObjectErrorByKeys(keys)
              })
          }
          // Date errors
          else if (moment.isMoment(obj[key].$model)) {
            if (obj[key].$dirty && obj[key].$invalid) {
              errors[key] = this.$_validationErrors_getObjectErrorByKeys(keys)
            }
          }
          // Object errors
          else {
            errors[key] = this.$_validationErrors_getObjectErrors(obj[key], keys)
          }
          keys.pop()
        })

      return errors
    },
    $_validationErrors_getObjectErrorByKeys(keys) {
      let errorsByKey = this.errorsByKey

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]

        if (errorsByKey[key]) {
          errorsByKey = errorsByKey[key]
        }
        else if (typeof errorsByKey === 'string') {
          return errorsByKey
        }
        else {
          return this.defaultError
        }
      }

      return errorsByKey || this.defaultError
    },
  }
}
</script>
