<template>
  <v-row>
    <v-col class="pr-16" cols="12" md="6">
      <order-delivery-info :address="address" :order="po" />
    </v-col>

    <v-divider v-if="lgAndUp" class="my-3" vertical />

    <v-col cols="12" md="6" :class="{ 'pl-16': lgAndUp }">
      <div class="qtm-h3">
        <span class="text-mid-grey">
          Fulfilled by
        </span>
        {{ po.supplier_name }}
      </div>
      <div class="text-mid-grey">
        <qtm-address :address="po.supplier_address" :city="po.supplier_city" :province="po.supplier_province" />
      </div>
      <div class="font-weight-bold mt-2" v-text="po.supplier_contact_name" />
      <div>
        <qtm-phone-number :ext="po.supplier_contact_phone_extension" :phone="po.supplier_contact_phone" />
      </div>
      <div>
        <a
          class="text-decoration-none"
          :href="`mailto:${po.supplier_contact_email}`"
          v-text="po.supplier_contact_email"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { useDisplay } from 'vuetify'
import OrderDeliveryInfo from '@/components/orders/order-delivery-info'

export default {
  name: 'purchase-order-detail-info',
  components: { OrderDeliveryInfo },
  props: {
    po: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { lgAndUp } = useDisplay()

    return { lgAndUp }
  },
  computed: {
    address() {
      const fields = ['address', 'city', 'province', 'postal_code']

      return fields.map(field => this.po[`shipto_${field}`]).join(' ')
    }
  },
}
</script>
