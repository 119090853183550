<template>
  <v-icon :color="color" :title="title">
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  name: 'quote-status-icon',
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      switch (this.quote.response_received) {
        case 'price given':
          return 'Price Given'
        case 'awaiting price':
          return 'Awaiting Price'
        case 'declined':
          return 'Declined'
        default:
          return 'Unknown'
      }
    },

    color() {
      switch (this.quote.response_received) {
        case 'price given':
          return 'success'
        case 'declined':
          return 'error'
        default:
          return undefined
      }
    },

    icon() {
      switch (this.quote.response_received) {
        case 'price given':
          return 'mdi-check-circle-outline'
        case 'awaiting price':
          return 'mdi-update'
        case 'declined':
          return 'mdi-close-circle-outline'
        default:
          return 'mdi-help-circle-outline'
      }
    }
  }
}
</script>

<!--<style scoped lang="scss">-->
<!--</style>-->
