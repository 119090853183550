import { createApi, OrderState } from '@quotetome/materials-api'
import orderStatesExtension from '@/models/order-states-extension'
// eslint-disable-next-line import/no-cycle
import store from '~/store'
import { axiosInstance } from '~/plugins/axios'

OrderState.extendStates(orderStatesExtension)

export const api = createApi(axiosInstance, () => store.state.auth.authToken)

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.provide('api', api)
})
