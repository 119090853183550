<template>
  <div class="container">
    <div class="font-weight-bold order-total-price-estimate qtm-h2">
      <div class="label px-2 py-1">
        TOTAL (excl. tax)
      </div>
      <div class="px-2 py-1">
        {{ currency(estimatedTotal) }}
      </div>
    </div>
    <div v-if="showTaxes">
      <div class="px-2 pt-2 taxes-title">
        Taxes
      </div>
      <div v-for="taxLineItem in taxLineItems" :key="taxLineItem.description" class="px-4 py-1">
        <div class="tax-label">
          {{ taxLineItem.description }}:
        </div>
        <span class="font-weight-bold">{{ currency(taxLineItem.total_price) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { currency } from '~/models/filters'

export default {
  name: 'purchase-order-total-price',
  props: {
    deliveryCharge: {
      type: [Number, String],
      default: 0
    },
    deliveryRequired: {
      type: Boolean,
      default: false
    },
    freightTax: {
      type: Number,
      default: undefined
    },
    pickupCharge: {
      type: [Number, String],
      default: 0
    },
    skus: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: 0
    },
  },
  emits: ['change', 'update:model-value'],
  data() {
    return {
      estimatedTotal: this.modelValue,
      taxLineItems: []
    }
  },
  computed: {
    showTaxes() {
      return !!this.taxLineItems.length
    },
  },
  watch: {
    deliveryCharge() {
      this.debouncedEstimate()
    },
    deliveryRequired() {
      this.debouncedEstimate()
    },
    freightTax() {
      this.debouncedEstimate()
    },
    pickupCharge() {
      this.debouncedEstimate()
    },
    skus: {
      deep: true,
      handler() {
        return this.debouncedEstimate()
      }
    },
  },
  mounted() {
    if (this.skus.some(sku => !!sku.tax)) {
      this.estimate()
    }
  },
  created() {
    this.debouncedEstimate = debounce(this.estimate.bind(this), 350)
  },
  methods: {
    currency(value) {
      return currency(value)
    },
    async estimate() {
      const deliveryCharge = this.deliveryRequired ? this.deliveryCharge : null
      const pickupCharge = this.deliveryRequired ? this.pickupCharge : null
      const estimateData = {
        delivery_charge: deliveryCharge || null,
        freight_tax: this.freightTax,
        pickup_charge: pickupCharge || null,
        poskus: this.skus.map((sku) => ({
          day_rate: sku.day_rate || null,
          month_rate: sku.month_rate || null,
          quantity: sku.quantity || null,
          rental_duration: sku.rental_duration || null,
          rental_duration_unit: sku.rental_duration_unit || null,
          tax: sku.tax || null,
          unit_price: sku.unit_price || null,
          week_rate: sku.week_rate || null,
        })),
      }

      try {
        const result = await this.$api.v1.purchaseOrders.estimate(estimateData)

        this.estimatedTotal = result.estimate
        this.taxLineItems = result.tax_line_items
        this.$emit('change', this.estimatedTotal)
        this.$emit('update:model-value', this.estimatedTotal)
      }
      catch (error) {
        this.$error.report(error, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: inline-block;
  width: 300px;
  text-align: left;
}

.label {
  background-color: rgb(var(--v-theme-light-teal));
}

.order-total-price-estimate {
  border: 1px solid rgb(var(--v-theme-light-grey));
}

.tax-label {
  display: inline-block;
  width: 40%;
}

.taxes-title {
  font-weight: bold;
}
</style>
