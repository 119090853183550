<template>
  <v-avatar class="vendor-avatar text-white" :color="color" size="48">
    <div v-if="vendor.logo">
      <v-img :alt="vendor.name" :src="vendor.logo" width="40" />
    </div>
    <span v-else>
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script>
export default {
  name: 'vendor-avatar',
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      return this.vendor.logo ? 'transparent' : 'mid-light-grey'
    },
    initials() {
      return this.vendor.name.split(' ').slice(0, 2).map((word) => word[0]).join('')
    },
  },
}
</script>

<style lang="scss" scoped>
.vendor-avatar {
  font-size: 20px;
  font-weight: 700;
  border: 1px solid rgb(var(--v-theme-light-grey)) !important;
}
</style>
