import { default as accounting_45failures1wqwSovtawMeta } from "/opt/build/repo/pages/accounting-failures.vue?macro=true";
import { default as indexrCDFILjKL3Meta } from "/opt/build/repo/pages/admin/dashboard/index.vue?macro=true";
import { default as indexWvbi1x4mA9Meta } from "/opt/build/repo/pages/admin/emails/index.vue?macro=true";
import { default as index7qcPXZQ3fgMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as adminAOa9i81ClJMeta } from "/opt/build/repo/pages/admin.vue?macro=true";
import { default as indexyYoRS52qDvMeta } from "/opt/build/repo/pages/companies/[id]/accounting/index.vue?macro=true";
import { default as setupPLALJ2aTP4Meta } from "/opt/build/repo/pages/companies/[id]/accounting/setup.vue?macro=true";
import { default as contactserwBiDGZhEMeta } from "/opt/build/repo/pages/companies/[id]/contacts.vue?macro=true";
import { default as cost_45codesyluEwrQjBiMeta } from "/opt/build/repo/pages/companies/[id]/cost-codes.vue?macro=true";
import { default as indexIDogGMEzQnMeta } from "/opt/build/repo/pages/companies/[id]/index.vue?macro=true";
import { default as purchase_45orderFM1AjJ8LN4Meta } from "/opt/build/repo/pages/companies/[id]/purchase-order.vue?macro=true";
import { default as rolesjF8L9My80vMeta } from "/opt/build/repo/pages/companies/[id]/roles.vue?macro=true";
import { default as contactslCLUCGnH1YMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/contacts.vue?macro=true";
import { default as indexPayNn7cWCLMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/index.vue?macro=true";
import { default as mapHgLjrpu7VsMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/map.vue?macro=true";
import { default as _91companyVendorId_93UGEpqNrJ5BMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId].vue?macro=true";
import { default as categoriesgoVnSGFn22Meta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/categories.vue?macro=true";
import { default as contacts1bOaigw3RQMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/contacts.vue?macro=true";
import { default as in_45storecSn9KilMW7Meta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/in-store.vue?macro=true";
import { default as indexAtJAzR4KYcMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/index.vue?macro=true";
import { default as newu5OpAtg5nEMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/new.vue?macro=true";
import { default as addoNe5OipBVjMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add.vue?macro=true";
import { default as indexKK4l1x9oTyMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/index.vue?macro=true";
import { default as suppliers0oqo87bY3KMeta } from "/opt/build/repo/pages/companies/[id]/suppliers.vue?macro=true";
import { default as _91id_931AV2ody235Meta } from "/opt/build/repo/pages/companies/[id].vue?macro=true";
import { default as componentsay62b1HMlmMeta } from "/opt/build/repo/pages/components.vue?macro=true";
import { default as activeV81bXqvhMOMeta } from "/opt/build/repo/pages/dashboard/active.vue?macro=true";
import { default as completeszGzCUPl34Meta } from "/opt/build/repo/pages/dashboard/complete.vue?macro=true";
import { default as my_45ordersbyc63iLtlYMeta } from "/opt/build/repo/pages/dashboard/my-orders.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexNA5kvJylAnMeta } from "/opt/build/repo/pages/error-test/index.vue?macro=true";
import { default as index4diJnNPCqrMeta } from "/opt/build/repo/pages/expenses/[id]/index.vue?macro=true";
import { default as _91id_93qe6ZCJiSH4Meta } from "/opt/build/repo/pages/expenses/[id].vue?macro=true";
import { default as resetymq0xjlkgBMeta } from "/opt/build/repo/pages/forgot-password/[uuid]/reset.vue?macro=true";
import { default as check_45emailxlf7QdUQyEMeta } from "/opt/build/repo/pages/forgot-password/check-email.vue?macro=true";
import { default as index3EcQ0vRq6EMeta } from "/opt/build/repo/pages/forgot-password/index.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as in_45storeqBWrl8GmioMeta } from "/opt/build/repo/pages/orders/[id]/in-store.vue?macro=true";
import { default as index6q3YeMG7apMeta } from "/opt/build/repo/pages/orders/[id]/index.vue?macro=true";
import { default as fullgFh7kCjHDNMeta } from "/opt/build/repo/pages/orders/[id]/receive/full.vue?macro=true";
import { default as indexBEBOFJ9D01Meta } from "/opt/build/repo/pages/orders/[id]/receive/index.vue?macro=true";
import { default as partialCWoruO6bRYMeta } from "/opt/build/repo/pages/orders/[id]/receive/partial.vue?macro=true";
import { default as submiteF2lp1t64dMeta } from "/opt/build/repo/pages/orders/[id]/receive/submit.vue?macro=true";
import { default as receiveG3qQAZ0Yu0Meta } from "/opt/build/repo/pages/orders/[id]/receive.vue?macro=true";
import { default as _91id_93TTgZ6DPPQGMeta } from "/opt/build/repo/pages/orders/[id].vue?macro=true";
import { default as indexi2fh0drxOOMeta } from "/opt/build/repo/pages/orders/index.vue?macro=true";
import { default as confirmation3AIbdoVy9UMeta } from "/opt/build/repo/pages/orders/new/confirmation.vue?macro=true";
import { default as deliveryq3qPFTmjn9Meta } from "/opt/build/repo/pages/orders/new/delivery.vue?macro=true";
import { default as indexWKnbTQUEO4Meta } from "/opt/build/repo/pages/orders/new/index.vue?macro=true";
import { default as invite_45supplierscnRfPbFZ3MMeta } from "/opt/build/repo/pages/orders/new/invite-suppliers.vue?macro=true";
import { default as review1FIAJvCl7TMeta } from "/opt/build/repo/pages/orders/new/review.vue?macro=true";
import { default as newzoomwQnfWxMeta } from "/opt/build/repo/pages/orders/new.vue?macro=true";
import { default as indexLJfq1P35t7Meta } from "/opt/build/repo/pages/profile/index.vue?macro=true";
import { default as notificationsg94mAVLjTyMeta } from "/opt/build/repo/pages/profile/notifications.vue?macro=true";
import { default as passwordsWDNYqO8wAMeta } from "/opt/build/repo/pages/profile/password.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as indexoOF6RfBS7CMeta } from "/opt/build/repo/pages/projects/[id]/index.vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as newUCNIqFdKkpMeta } from "/opt/build/repo/pages/projects/new.vue?macro=true";
import { default as change_45orderyvrmz21rF7Meta } from "/opt/build/repo/pages/purchase-orders/[id]/change-order.vue?macro=true";
import { default as confirmationC5eJgn4AMLMeta } from "/opt/build/repo/pages/purchase-orders/[id]/request-po/confirmation.vue?macro=true";
import { default as _91id_937ZZwklMh7EMeta } from "/opt/build/repo/pages/purchase-orders/[id].vue?macro=true";
import { default as new6iYug0E8qUMeta } from "/opt/build/repo/pages/purchase-orders/new.vue?macro=true";
import { default as setup_45modeL9qDT6k3uMMeta } from "/opt/build/repo/pages/setup-mode.vue?macro=true";
import { default as categories2J4CB32WCXMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/categories.vue?macro=true";
import { default as companiesUNZwHIwsw5Meta } from "/opt/build/repo/pages/suppliers/[id]/edit/companies.vue?macro=true";
import { default as _91contactId_93VSy8D4PwfOMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/[contactId].vue?macro=true";
import { default as indexpFVKuKWopIMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/index.vue?macro=true";
import { default as newJdiKwt02DDMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/new.vue?macro=true";
import { default as indexEhNdi2euANMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/index.vue?macro=true";
import { default as editslUuXPTL09Meta } from "/opt/build/repo/pages/suppliers/[id]/edit.vue?macro=true";
import { default as mergeFHPzZIgnd0Meta } from "/opt/build/repo/pages/suppliers/[id]/merge.vue?macro=true";
import { default as _91id_93pKgEiXZtZSMeta } from "/opt/build/repo/pages/suppliers/[id].vue?macro=true";
import { default as categories7sUc7C17xUMeta } from "/opt/build/repo/pages/suppliers/new/categories.vue?macro=true";
import { default as indexKlaMwNYGv4Meta } from "/opt/build/repo/pages/suppliers/new/contacts/index.vue?macro=true";
import { default as newJx8rLPkmb6Meta } from "/opt/build/repo/pages/suppliers/new/contacts/new.vue?macro=true";
import { default as indexCUk2cJRpLpMeta } from "/opt/build/repo/pages/suppliers/new/index.vue?macro=true";
import { default as newQZx9KifUAdMeta } from "/opt/build/repo/pages/suppliers/new.vue?macro=true";
import { default as suppliersLPOkiFovuWMeta } from "/opt/build/repo/pages/suppliers.vue?macro=true";
import { default as editjZAd5BA6vAMeta } from "/opt/build/repo/pages/users/[id]/edit.vue?macro=true";
import { default as indexO2KkEMigJMMeta } from "/opt/build/repo/pages/users/accept-invite/[uuid]/index.vue?macro=true";
import { default as indexIJSpSfJfmnMeta } from "/opt/build/repo/pages/users/index.vue?macro=true";
import { default as newttbkpK96RcMeta } from "/opt/build/repo/pages/users/new.vue?macro=true";
export default [
  {
    name: accounting_45failures1wqwSovtawMeta?.name ?? "accounting-failures",
    path: accounting_45failures1wqwSovtawMeta?.path ?? "/accounting-failures",
    meta: accounting_45failures1wqwSovtawMeta || {},
    alias: accounting_45failures1wqwSovtawMeta?.alias || [],
    redirect: accounting_45failures1wqwSovtawMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/accounting-failures.vue").then(m => m.default || m)
  },
  {
    path: adminAOa9i81ClJMeta?.path ?? "/admin",
    children: [
  {
    name: indexrCDFILjKL3Meta?.name ?? "admin-dashboard",
    path: indexrCDFILjKL3Meta?.path ?? "dashboard",
    meta: indexrCDFILjKL3Meta || {},
    alias: indexrCDFILjKL3Meta?.alias || [],
    redirect: indexrCDFILjKL3Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/admin/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexWvbi1x4mA9Meta?.name ?? "admin-emails",
    path: indexWvbi1x4mA9Meta?.path ?? "emails",
    meta: indexWvbi1x4mA9Meta || {},
    alias: indexWvbi1x4mA9Meta?.alias || [],
    redirect: indexWvbi1x4mA9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/admin/emails/index.vue").then(m => m.default || m)
  },
  {
    name: index7qcPXZQ3fgMeta?.name ?? "admin",
    path: index7qcPXZQ3fgMeta?.path ?? "",
    meta: index7qcPXZQ3fgMeta || {},
    alias: index7qcPXZQ3fgMeta?.alias || [],
    redirect: index7qcPXZQ3fgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/admin/index.vue").then(m => m.default || m)
  }
],
    name: adminAOa9i81ClJMeta?.name ?? undefined,
    meta: adminAOa9i81ClJMeta || {},
    alias: adminAOa9i81ClJMeta?.alias || [],
    redirect: adminAOa9i81ClJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/admin.vue").then(m => m.default || m)
  },
  {
    path: _91id_931AV2ody235Meta?.path ?? "/companies/:id",
    children: [
  {
    name: indexyYoRS52qDvMeta?.name ?? "companies-id-accounting",
    path: indexyYoRS52qDvMeta?.path ?? "accounting",
    meta: indexyYoRS52qDvMeta || {},
    alias: indexyYoRS52qDvMeta?.alias || [],
    redirect: indexyYoRS52qDvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/accounting/index.vue").then(m => m.default || m)
  },
  {
    name: setupPLALJ2aTP4Meta?.name ?? "companies-id-accounting-setup",
    path: setupPLALJ2aTP4Meta?.path ?? "accounting/setup",
    meta: setupPLALJ2aTP4Meta || {},
    alias: setupPLALJ2aTP4Meta?.alias || [],
    redirect: setupPLALJ2aTP4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/accounting/setup.vue").then(m => m.default || m)
  },
  {
    name: contactserwBiDGZhEMeta?.name ?? "companies-id-contacts",
    path: contactserwBiDGZhEMeta?.path ?? "contacts",
    meta: contactserwBiDGZhEMeta || {},
    alias: contactserwBiDGZhEMeta?.alias || [],
    redirect: contactserwBiDGZhEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/contacts.vue").then(m => m.default || m)
  },
  {
    name: cost_45codesyluEwrQjBiMeta?.name ?? "companies-id-cost-codes",
    path: cost_45codesyluEwrQjBiMeta?.path ?? "cost-codes",
    meta: cost_45codesyluEwrQjBiMeta || {},
    alias: cost_45codesyluEwrQjBiMeta?.alias || [],
    redirect: cost_45codesyluEwrQjBiMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/cost-codes.vue").then(m => m.default || m)
  },
  {
    name: indexIDogGMEzQnMeta?.name ?? "companies-id",
    path: indexIDogGMEzQnMeta?.path ?? "",
    meta: indexIDogGMEzQnMeta || {},
    alias: indexIDogGMEzQnMeta?.alias || [],
    redirect: indexIDogGMEzQnMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: purchase_45orderFM1AjJ8LN4Meta?.name ?? "companies-id-purchase-order",
    path: purchase_45orderFM1AjJ8LN4Meta?.path ?? "purchase-order",
    meta: purchase_45orderFM1AjJ8LN4Meta || {},
    alias: purchase_45orderFM1AjJ8LN4Meta?.alias || [],
    redirect: purchase_45orderFM1AjJ8LN4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/purchase-order.vue").then(m => m.default || m)
  },
  {
    name: rolesjF8L9My80vMeta?.name ?? "companies-id-roles",
    path: rolesjF8L9My80vMeta?.path ?? "roles",
    meta: rolesjF8L9My80vMeta || {},
    alias: rolesjF8L9My80vMeta?.alias || [],
    redirect: rolesjF8L9My80vMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/roles.vue").then(m => m.default || m)
  },
  {
    path: suppliers0oqo87bY3KMeta?.path ?? "suppliers",
    children: [
  {
    path: _91companyVendorId_93UGEpqNrJ5BMeta?.path ?? ":companyVendorId",
    children: [
  {
    name: contactslCLUCGnH1YMeta?.name ?? "companies-id-suppliers-companyVendorId-contacts",
    path: contactslCLUCGnH1YMeta?.path ?? "contacts",
    meta: contactslCLUCGnH1YMeta || {},
    alias: contactslCLUCGnH1YMeta?.alias || [],
    redirect: contactslCLUCGnH1YMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexPayNn7cWCLMeta?.name ?? "companies-id-suppliers-companyVendorId",
    path: indexPayNn7cWCLMeta?.path ?? "",
    meta: indexPayNn7cWCLMeta || {},
    alias: indexPayNn7cWCLMeta?.alias || [],
    redirect: indexPayNn7cWCLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/index.vue").then(m => m.default || m)
  },
  {
    name: mapHgLjrpu7VsMeta?.name ?? "companies-id-suppliers-companyVendorId-map",
    path: mapHgLjrpu7VsMeta?.path ?? "map",
    meta: mapHgLjrpu7VsMeta || {},
    alias: mapHgLjrpu7VsMeta?.alias || [],
    redirect: mapHgLjrpu7VsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/map.vue").then(m => m.default || m)
  }
],
    name: _91companyVendorId_93UGEpqNrJ5BMeta?.name ?? undefined,
    meta: _91companyVendorId_93UGEpqNrJ5BMeta || {},
    alias: _91companyVendorId_93UGEpqNrJ5BMeta?.alias || [],
    redirect: _91companyVendorId_93UGEpqNrJ5BMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId].vue").then(m => m.default || m)
  },
  {
    path: addoNe5OipBVjMeta?.path ?? "add",
    children: [
  {
    name: categoriesgoVnSGFn22Meta?.name ?? "companies-id-suppliers-add-categories",
    path: categoriesgoVnSGFn22Meta?.path ?? "categories",
    meta: categoriesgoVnSGFn22Meta || {},
    alias: categoriesgoVnSGFn22Meta?.alias || [],
    redirect: categoriesgoVnSGFn22Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/categories.vue").then(m => m.default || m)
  },
  {
    name: contacts1bOaigw3RQMeta?.name ?? "companies-id-suppliers-add-contacts",
    path: contacts1bOaigw3RQMeta?.path ?? "contacts",
    meta: contacts1bOaigw3RQMeta || {},
    alias: contacts1bOaigw3RQMeta?.alias || [],
    redirect: contacts1bOaigw3RQMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/contacts.vue").then(m => m.default || m)
  },
  {
    name: in_45storecSn9KilMW7Meta?.name ?? "companies-id-suppliers-add-in-store",
    path: in_45storecSn9KilMW7Meta?.path ?? "in-store",
    meta: in_45storecSn9KilMW7Meta || {},
    alias: in_45storecSn9KilMW7Meta?.alias || [],
    redirect: in_45storecSn9KilMW7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/in-store.vue").then(m => m.default || m)
  },
  {
    name: indexAtJAzR4KYcMeta?.name ?? "companies-id-suppliers-add",
    path: indexAtJAzR4KYcMeta?.path ?? "",
    meta: indexAtJAzR4KYcMeta || {},
    alias: indexAtJAzR4KYcMeta?.alias || [],
    redirect: indexAtJAzR4KYcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/index.vue").then(m => m.default || m)
  },
  {
    name: newu5OpAtg5nEMeta?.name ?? "companies-id-suppliers-add-new",
    path: newu5OpAtg5nEMeta?.path ?? "new",
    meta: newu5OpAtg5nEMeta || {},
    alias: newu5OpAtg5nEMeta?.alias || [],
    redirect: newu5OpAtg5nEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/new.vue").then(m => m.default || m)
  }
],
    name: addoNe5OipBVjMeta?.name ?? undefined,
    meta: addoNe5OipBVjMeta || {},
    alias: addoNe5OipBVjMeta?.alias || [],
    redirect: addoNe5OipBVjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: indexKK4l1x9oTyMeta?.name ?? "companies-id-suppliers",
    path: indexKK4l1x9oTyMeta?.path ?? "",
    meta: indexKK4l1x9oTyMeta || {},
    alias: indexKK4l1x9oTyMeta?.alias || [],
    redirect: indexKK4l1x9oTyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/index.vue").then(m => m.default || m)
  }
],
    name: suppliers0oqo87bY3KMeta?.name ?? undefined,
    meta: suppliers0oqo87bY3KMeta || {},
    alias: suppliers0oqo87bY3KMeta?.alias || [],
    redirect: suppliers0oqo87bY3KMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers.vue").then(m => m.default || m)
  }
],
    name: _91id_931AV2ody235Meta?.name ?? undefined,
    meta: _91id_931AV2ody235Meta || {},
    alias: _91id_931AV2ody235Meta?.alias || [],
    redirect: _91id_931AV2ody235Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: componentsay62b1HMlmMeta?.name ?? "components",
    path: componentsay62b1HMlmMeta?.path ?? "/components",
    meta: componentsay62b1HMlmMeta || {},
    alias: componentsay62b1HMlmMeta?.alias || [],
    redirect: componentsay62b1HMlmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/components.vue").then(m => m.default || m)
  },
  {
    name: dashboardB0S6bZ6pqpMeta?.name ?? "dashboard",
    path: dashboardB0S6bZ6pqpMeta?.path ?? "/dashboard",
    children: [
  {
    name: activeV81bXqvhMOMeta?.name ?? "dashboard-active",
    path: activeV81bXqvhMOMeta?.path ?? "active",
    meta: activeV81bXqvhMOMeta || {},
    alias: activeV81bXqvhMOMeta?.alias || [],
    redirect: activeV81bXqvhMOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/active.vue").then(m => m.default || m)
  },
  {
    name: completeszGzCUPl34Meta?.name ?? "dashboard-complete",
    path: completeszGzCUPl34Meta?.path ?? "complete",
    meta: completeszGzCUPl34Meta || {},
    alias: completeszGzCUPl34Meta?.alias || [],
    redirect: completeszGzCUPl34Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/complete.vue").then(m => m.default || m)
  },
  {
    name: my_45ordersbyc63iLtlYMeta?.name ?? "dashboard-my-orders",
    path: my_45ordersbyc63iLtlYMeta?.path ?? "my-orders",
    meta: my_45ordersbyc63iLtlYMeta || {},
    alias: my_45ordersbyc63iLtlYMeta?.alias || [],
    redirect: my_45ordersbyc63iLtlYMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/my-orders.vue").then(m => m.default || m)
  }
],
    meta: dashboardB0S6bZ6pqpMeta || {},
    alias: dashboardB0S6bZ6pqpMeta?.alias || [],
    redirect: dashboardB0S6bZ6pqpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexNA5kvJylAnMeta?.name ?? "error-test",
    path: indexNA5kvJylAnMeta?.path ?? "/error-test",
    meta: indexNA5kvJylAnMeta || {},
    alias: indexNA5kvJylAnMeta?.alias || [],
    redirect: indexNA5kvJylAnMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/error-test/index.vue").then(m => m.default || m)
  },
  {
    path: _91id_93qe6ZCJiSH4Meta?.path ?? "/expenses/:id",
    children: [
  {
    name: index4diJnNPCqrMeta?.name ?? "expenses-id",
    path: index4diJnNPCqrMeta?.path ?? "",
    meta: index4diJnNPCqrMeta || {},
    alias: index4diJnNPCqrMeta?.alias || [],
    redirect: index4diJnNPCqrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/expenses/[id]/index.vue").then(m => m.default || m)
  }
],
    name: _91id_93qe6ZCJiSH4Meta?.name ?? undefined,
    meta: _91id_93qe6ZCJiSH4Meta || {},
    alias: _91id_93qe6ZCJiSH4Meta?.alias || [],
    redirect: _91id_93qe6ZCJiSH4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/expenses/[id].vue").then(m => m.default || m)
  },
  {
    path: forgot_45password6Wrw3WyRqiMeta?.path ?? "/forgot-password",
    children: [
  {
    name: resetymq0xjlkgBMeta?.name ?? "forgot-password-uuid-reset",
    path: resetymq0xjlkgBMeta?.path ?? ":uuid/reset",
    meta: resetymq0xjlkgBMeta || {},
    alias: resetymq0xjlkgBMeta?.alias || [],
    redirect: resetymq0xjlkgBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/forgot-password/[uuid]/reset.vue").then(m => m.default || m)
  },
  {
    name: check_45emailxlf7QdUQyEMeta?.name ?? "forgot-password-check-email",
    path: check_45emailxlf7QdUQyEMeta?.path ?? "check-email",
    meta: check_45emailxlf7QdUQyEMeta || {},
    alias: check_45emailxlf7QdUQyEMeta?.alias || [],
    redirect: check_45emailxlf7QdUQyEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/forgot-password/check-email.vue").then(m => m.default || m)
  },
  {
    name: index3EcQ0vRq6EMeta?.name ?? "forgot-password",
    path: index3EcQ0vRq6EMeta?.path ?? "",
    meta: index3EcQ0vRq6EMeta || {},
    alias: index3EcQ0vRq6EMeta?.alias || [],
    redirect: index3EcQ0vRq6EMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/forgot-password/index.vue").then(m => m.default || m)
  }
],
    name: forgot_45password6Wrw3WyRqiMeta?.name ?? undefined,
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    alias: forgot_45password6Wrw3WyRqiMeta?.alias || [],
    redirect: forgot_45password6Wrw3WyRqiMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    path: _91id_93TTgZ6DPPQGMeta?.path ?? "/orders/:id",
    children: [
  {
    name: in_45storeqBWrl8GmioMeta?.name ?? "orders-id-in-store",
    path: in_45storeqBWrl8GmioMeta?.path ?? "in-store",
    meta: in_45storeqBWrl8GmioMeta || {},
    alias: in_45storeqBWrl8GmioMeta?.alias || [],
    redirect: in_45storeqBWrl8GmioMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/in-store.vue").then(m => m.default || m)
  },
  {
    name: index6q3YeMG7apMeta?.name ?? "orders-id",
    path: index6q3YeMG7apMeta?.path ?? "",
    meta: index6q3YeMG7apMeta || {},
    alias: index6q3YeMG7apMeta?.alias || [],
    redirect: index6q3YeMG7apMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    path: receiveG3qQAZ0Yu0Meta?.path ?? "receive",
    children: [
  {
    name: fullgFh7kCjHDNMeta?.name ?? "orders-id-receive-full",
    path: fullgFh7kCjHDNMeta?.path ?? "full",
    meta: fullgFh7kCjHDNMeta || {},
    alias: fullgFh7kCjHDNMeta?.alias || [],
    redirect: fullgFh7kCjHDNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/full.vue").then(m => m.default || m)
  },
  {
    name: indexBEBOFJ9D01Meta?.name ?? "orders-id-receive",
    path: indexBEBOFJ9D01Meta?.path ?? "",
    meta: indexBEBOFJ9D01Meta || {},
    alias: indexBEBOFJ9D01Meta?.alias || [],
    redirect: indexBEBOFJ9D01Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/index.vue").then(m => m.default || m)
  },
  {
    name: partialCWoruO6bRYMeta?.name ?? "orders-id-receive-partial",
    path: partialCWoruO6bRYMeta?.path ?? "partial",
    meta: partialCWoruO6bRYMeta || {},
    alias: partialCWoruO6bRYMeta?.alias || [],
    redirect: partialCWoruO6bRYMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/partial.vue").then(m => m.default || m)
  },
  {
    name: submiteF2lp1t64dMeta?.name ?? "orders-id-receive-submit",
    path: submiteF2lp1t64dMeta?.path ?? "submit",
    meta: submiteF2lp1t64dMeta || {},
    alias: submiteF2lp1t64dMeta?.alias || [],
    redirect: submiteF2lp1t64dMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/submit.vue").then(m => m.default || m)
  }
],
    name: receiveG3qQAZ0Yu0Meta?.name ?? undefined,
    meta: receiveG3qQAZ0Yu0Meta || {},
    alias: receiveG3qQAZ0Yu0Meta?.alias || [],
    redirect: receiveG3qQAZ0Yu0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id]/receive.vue").then(m => m.default || m)
  }
],
    name: _91id_93TTgZ6DPPQGMeta?.name ?? undefined,
    meta: _91id_93TTgZ6DPPQGMeta || {},
    alias: _91id_93TTgZ6DPPQGMeta?.alias || [],
    redirect: _91id_93TTgZ6DPPQGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi2fh0drxOOMeta?.name ?? "orders",
    path: indexi2fh0drxOOMeta?.path ?? "/orders",
    meta: indexi2fh0drxOOMeta || {},
    alias: indexi2fh0drxOOMeta?.alias || [],
    redirect: indexi2fh0drxOOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    path: newzoomwQnfWxMeta?.path ?? "/orders/new",
    children: [
  {
    name: confirmation3AIbdoVy9UMeta?.name ?? "orders-new-confirmation",
    path: confirmation3AIbdoVy9UMeta?.path ?? "confirmation",
    meta: confirmation3AIbdoVy9UMeta || {},
    alias: confirmation3AIbdoVy9UMeta?.alias || [],
    redirect: confirmation3AIbdoVy9UMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/new/confirmation.vue").then(m => m.default || m)
  },
  {
    name: deliveryq3qPFTmjn9Meta?.name ?? "orders-new-delivery",
    path: deliveryq3qPFTmjn9Meta?.path ?? "delivery",
    meta: deliveryq3qPFTmjn9Meta || {},
    alias: deliveryq3qPFTmjn9Meta?.alias || [],
    redirect: deliveryq3qPFTmjn9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/new/delivery.vue").then(m => m.default || m)
  },
  {
    name: indexWKnbTQUEO4Meta?.name ?? "orders-new",
    path: indexWKnbTQUEO4Meta?.path ?? "",
    meta: indexWKnbTQUEO4Meta || {},
    alias: indexWKnbTQUEO4Meta?.alias || [],
    redirect: indexWKnbTQUEO4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/new/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45supplierscnRfPbFZ3MMeta?.name ?? "orders-new-invite-suppliers",
    path: invite_45supplierscnRfPbFZ3MMeta?.path ?? "invite-suppliers",
    meta: invite_45supplierscnRfPbFZ3MMeta || {},
    alias: invite_45supplierscnRfPbFZ3MMeta?.alias || [],
    redirect: invite_45supplierscnRfPbFZ3MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/new/invite-suppliers.vue").then(m => m.default || m)
  },
  {
    name: review1FIAJvCl7TMeta?.name ?? "orders-new-review",
    path: review1FIAJvCl7TMeta?.path ?? "review",
    meta: review1FIAJvCl7TMeta || {},
    alias: review1FIAJvCl7TMeta?.alias || [],
    redirect: review1FIAJvCl7TMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/new/review.vue").then(m => m.default || m)
  }
],
    name: newzoomwQnfWxMeta?.name ?? undefined,
    meta: newzoomwQnfWxMeta || {},
    alias: newzoomwQnfWxMeta?.alias || [],
    redirect: newzoomwQnfWxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/orders/new.vue").then(m => m.default || m)
  },
  {
    path: profileNJNbyWXx9rMeta?.path ?? "/profile",
    children: [
  {
    name: indexLJfq1P35t7Meta?.name ?? "profile",
    path: indexLJfq1P35t7Meta?.path ?? "",
    meta: indexLJfq1P35t7Meta || {},
    alias: indexLJfq1P35t7Meta?.alias || [],
    redirect: indexLJfq1P35t7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsg94mAVLjTyMeta?.name ?? "profile-notifications",
    path: notificationsg94mAVLjTyMeta?.path ?? "notifications",
    meta: notificationsg94mAVLjTyMeta || {},
    alias: notificationsg94mAVLjTyMeta?.alias || [],
    redirect: notificationsg94mAVLjTyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: passwordsWDNYqO8wAMeta?.name ?? "profile-password",
    path: passwordsWDNYqO8wAMeta?.path ?? "password",
    meta: passwordsWDNYqO8wAMeta || {},
    alias: passwordsWDNYqO8wAMeta?.alias || [],
    redirect: passwordsWDNYqO8wAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/profile/password.vue").then(m => m.default || m)
  }
],
    name: profileNJNbyWXx9rMeta?.name ?? undefined,
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexoOF6RfBS7CMeta?.name ?? "projects-id",
    path: indexoOF6RfBS7CMeta?.path ?? "/projects/:id",
    meta: indexoOF6RfBS7CMeta || {},
    alias: indexoOF6RfBS7CMeta?.alias || [],
    redirect: indexoOF6RfBS7CMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects",
    path: indexxtMqaql9AYMeta?.path ?? "/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: newUCNIqFdKkpMeta?.name ?? "projects-new",
    path: newUCNIqFdKkpMeta?.path ?? "/projects/new",
    meta: newUCNIqFdKkpMeta || {},
    alias: newUCNIqFdKkpMeta?.alias || [],
    redirect: newUCNIqFdKkpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/projects/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_937ZZwklMh7EMeta?.name ?? "purchase-orders-id",
    path: _91id_937ZZwklMh7EMeta?.path ?? "/purchase-orders/:id",
    children: [
  {
    name: change_45orderyvrmz21rF7Meta?.name ?? "purchase-orders-id-change-order",
    path: change_45orderyvrmz21rF7Meta?.path ?? "change-order",
    meta: change_45orderyvrmz21rF7Meta || {},
    alias: change_45orderyvrmz21rF7Meta?.alias || [],
    redirect: change_45orderyvrmz21rF7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/purchase-orders/[id]/change-order.vue").then(m => m.default || m)
  },
  {
    name: confirmationC5eJgn4AMLMeta?.name ?? "purchase-orders-id-request-po-confirmation",
    path: confirmationC5eJgn4AMLMeta?.path ?? "request-po/confirmation",
    meta: confirmationC5eJgn4AMLMeta || {},
    alias: confirmationC5eJgn4AMLMeta?.alias || [],
    redirect: confirmationC5eJgn4AMLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/purchase-orders/[id]/request-po/confirmation.vue").then(m => m.default || m)
  }
],
    meta: _91id_937ZZwklMh7EMeta || {},
    alias: _91id_937ZZwklMh7EMeta?.alias || [],
    redirect: _91id_937ZZwklMh7EMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/purchase-orders/[id].vue").then(m => m.default || m)
  },
  {
    name: new6iYug0E8qUMeta?.name ?? "purchase-orders-new",
    path: new6iYug0E8qUMeta?.path ?? "/purchase-orders/new",
    meta: new6iYug0E8qUMeta || {},
    alias: new6iYug0E8qUMeta?.alias || [],
    redirect: new6iYug0E8qUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/purchase-orders/new.vue").then(m => m.default || m)
  },
  {
    name: setup_45modeL9qDT6k3uMMeta?.name ?? "setup-mode",
    path: setup_45modeL9qDT6k3uMMeta?.path ?? "/setup-mode",
    meta: setup_45modeL9qDT6k3uMMeta || {},
    alias: setup_45modeL9qDT6k3uMMeta?.alias || [],
    redirect: setup_45modeL9qDT6k3uMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/setup-mode.vue").then(m => m.default || m)
  },
  {
    name: suppliersLPOkiFovuWMeta?.name ?? "suppliers",
    path: suppliersLPOkiFovuWMeta?.path ?? "/suppliers",
    children: [
  {
    name: _91id_93pKgEiXZtZSMeta?.name ?? "suppliers-id",
    path: _91id_93pKgEiXZtZSMeta?.path ?? ":id",
    children: [
  {
    path: editslUuXPTL09Meta?.path ?? "edit",
    children: [
  {
    name: categories2J4CB32WCXMeta?.name ?? "suppliers-id-edit-categories",
    path: categories2J4CB32WCXMeta?.path ?? "categories",
    meta: categories2J4CB32WCXMeta || {},
    alias: categories2J4CB32WCXMeta?.alias || [],
    redirect: categories2J4CB32WCXMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/categories.vue").then(m => m.default || m)
  },
  {
    name: companiesUNZwHIwsw5Meta?.name ?? "suppliers-id-edit-companies",
    path: companiesUNZwHIwsw5Meta?.path ?? "companies",
    meta: companiesUNZwHIwsw5Meta || {},
    alias: companiesUNZwHIwsw5Meta?.alias || [],
    redirect: companiesUNZwHIwsw5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/companies.vue").then(m => m.default || m)
  },
  {
    name: _91contactId_93VSy8D4PwfOMeta?.name ?? "suppliers-id-edit-contacts-contactId",
    path: _91contactId_93VSy8D4PwfOMeta?.path ?? "contacts/:contactId",
    meta: _91contactId_93VSy8D4PwfOMeta || {},
    alias: _91contactId_93VSy8D4PwfOMeta?.alias || [],
    redirect: _91contactId_93VSy8D4PwfOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/[contactId].vue").then(m => m.default || m)
  },
  {
    name: indexpFVKuKWopIMeta?.name ?? "suppliers-id-edit-contacts",
    path: indexpFVKuKWopIMeta?.path ?? "contacts",
    meta: indexpFVKuKWopIMeta || {},
    alias: indexpFVKuKWopIMeta?.alias || [],
    redirect: indexpFVKuKWopIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: newJdiKwt02DDMeta?.name ?? "suppliers-id-edit-contacts-new",
    path: newJdiKwt02DDMeta?.path ?? "contacts/new",
    meta: newJdiKwt02DDMeta || {},
    alias: newJdiKwt02DDMeta?.alias || [],
    redirect: newJdiKwt02DDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/new.vue").then(m => m.default || m)
  },
  {
    name: indexEhNdi2euANMeta?.name ?? "suppliers-id-edit",
    path: indexEhNdi2euANMeta?.path ?? "",
    meta: indexEhNdi2euANMeta || {},
    alias: indexEhNdi2euANMeta?.alias || [],
    redirect: indexEhNdi2euANMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/index.vue").then(m => m.default || m)
  }
],
    name: editslUuXPTL09Meta?.name ?? undefined,
    meta: editslUuXPTL09Meta || {},
    alias: editslUuXPTL09Meta?.alias || [],
    redirect: editslUuXPTL09Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: mergeFHPzZIgnd0Meta?.name ?? "suppliers-id-merge",
    path: mergeFHPzZIgnd0Meta?.path ?? "merge",
    meta: mergeFHPzZIgnd0Meta || {},
    alias: mergeFHPzZIgnd0Meta?.alias || [],
    redirect: mergeFHPzZIgnd0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id]/merge.vue").then(m => m.default || m)
  }
],
    meta: _91id_93pKgEiXZtZSMeta || {},
    alias: _91id_93pKgEiXZtZSMeta?.alias || [],
    redirect: _91id_93pKgEiXZtZSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/[id].vue").then(m => m.default || m)
  },
  {
    path: newQZx9KifUAdMeta?.path ?? "new",
    children: [
  {
    name: categories7sUc7C17xUMeta?.name ?? "suppliers-new-categories",
    path: categories7sUc7C17xUMeta?.path ?? "categories",
    meta: categories7sUc7C17xUMeta || {},
    alias: categories7sUc7C17xUMeta?.alias || [],
    redirect: categories7sUc7C17xUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/new/categories.vue").then(m => m.default || m)
  },
  {
    name: indexKlaMwNYGv4Meta?.name ?? "suppliers-new-contacts",
    path: indexKlaMwNYGv4Meta?.path ?? "contacts",
    meta: indexKlaMwNYGv4Meta || {},
    alias: indexKlaMwNYGv4Meta?.alias || [],
    redirect: indexKlaMwNYGv4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/new/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: newJx8rLPkmb6Meta?.name ?? "suppliers-new-contacts-new",
    path: newJx8rLPkmb6Meta?.path ?? "contacts/new",
    meta: newJx8rLPkmb6Meta || {},
    alias: newJx8rLPkmb6Meta?.alias || [],
    redirect: newJx8rLPkmb6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/new/contacts/new.vue").then(m => m.default || m)
  },
  {
    name: indexCUk2cJRpLpMeta?.name ?? "suppliers-new",
    path: indexCUk2cJRpLpMeta?.path ?? "",
    meta: indexCUk2cJRpLpMeta || {},
    alias: indexCUk2cJRpLpMeta?.alias || [],
    redirect: indexCUk2cJRpLpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/new/index.vue").then(m => m.default || m)
  }
],
    name: newQZx9KifUAdMeta?.name ?? undefined,
    meta: newQZx9KifUAdMeta || {},
    alias: newQZx9KifUAdMeta?.alias || [],
    redirect: newQZx9KifUAdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers/new.vue").then(m => m.default || m)
  }
],
    meta: suppliersLPOkiFovuWMeta || {},
    alias: suppliersLPOkiFovuWMeta?.alias || [],
    redirect: suppliersLPOkiFovuWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/suppliers.vue").then(m => m.default || m)
  },
  {
    name: editjZAd5BA6vAMeta?.name ?? "users-id-edit",
    path: editjZAd5BA6vAMeta?.path ?? "/users/:id/edit",
    meta: editjZAd5BA6vAMeta || {},
    alias: editjZAd5BA6vAMeta?.alias || [],
    redirect: editjZAd5BA6vAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexO2KkEMigJMMeta?.name ?? "users-accept-invite-uuid",
    path: indexO2KkEMigJMMeta?.path ?? "/users/accept-invite/:uuid",
    meta: indexO2KkEMigJMMeta || {},
    alias: indexO2KkEMigJMMeta?.alias || [],
    redirect: indexO2KkEMigJMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/users/accept-invite/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexIJSpSfJfmnMeta?.name ?? "users",
    path: indexIJSpSfJfmnMeta?.path ?? "/users",
    meta: indexIJSpSfJfmnMeta || {},
    alias: indexIJSpSfJfmnMeta?.alias || [],
    redirect: indexIJSpSfJfmnMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: newttbkpK96RcMeta?.name ?? "users-new",
    path: newttbkpK96RcMeta?.path ?? "/users/new",
    meta: newttbkpK96RcMeta || {},
    alias: newttbkpK96RcMeta?.alias || [],
    redirect: newttbkpK96RcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/users/new.vue").then(m => m.default || m)
  }
]