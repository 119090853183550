<template>
  <qtm-dialog-card v-bind="attrs" width="520">
    <slot>
      <div class="qtm-h1 text-primary title-warning">
        WARNING
      </div>
      <div class="qtm-h1">
        SELECTING THIS QUOTE WILL CREATE A NEW PO
      </div>
      <p class="q-mt-2">
        This action will delete the draft PO for {{ order.pos[0].supplier_contact_name }} and
        create a new draft PO for {{ quote.vendor.name }}.
      </p>
      <p>
        Do you want to proceed?
      </p>
    </slot>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn
        data-test="confirmation-cancel-button"
        :loading="loading"
        tertiary
        @click="$emit('update:model-value', false)"
      >
        Go back
      </qtm-btn>
      <qtm-btn class="my-2" data-test="confirmation-ok-button" :loading="loading" @click="confirm">
        Yes, select it
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>

export default {
  name: 'change-awarded-quote-confirm-dialog',
  components: { },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  emits: ['confirm', 'update:model-value'],
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  }
}
</script>

<style scoped>
  .title-warning {
    font-size: 1.5rem;
  }
</style>
