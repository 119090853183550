<template>
  <qtm-content-block v-bind="$attrs" collapsible padding="0">
    <template v-slot:title>
      <div class="pl-10 qtm-h1 text-secondary">
        QUOTES
      </div>
    </template>
    <template v-slot:top-right>
      <v-btn-toggle
        class="quotes-display-toggle"
        color="interactive"
        density="compact"
        :model-value="quotesDisplay"
        @update:model-value="toggleQuotesDisplay"
      >
        <v-btn size="small">
          <v-icon :color="quotesDisplay === 0 ? 'white' : 'interactive'">
            mdi-dots-grid
          </v-icon>
        </v-btn>
        <v-btn size="small">
          <v-icon :color="quotesDisplay === 1 ? 'white' : 'interactive'">
            mdi-text-box-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
    <div v-show="quotesDisplay === 0" class="px-7">
      <quotes-grid :quotes="sortedQuotes" :order="order" @select="viewQuote" />
    </div>
    <quotes-carousel
      v-show="quotesDisplay === 1"
      ref="quotesCarousel"
      class="mt-5"
      :quotes="sortedQuotes"
      :order="order"
    />
  </qtm-content-block>
</template>

<script>
import QuotesCarousel from '@/components/quotes/quotes-carousel'
import QuotesGrid from '@/components/quotes/quotes-grid'

export default {
  name: 'quotes-review',
  components: { QuotesCarousel, QuotesGrid },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      quotesDisplay: this.$route.query.quote ? 1 : 0
    }
  },
  computed: {
    sortedQuotes() {
      const otherQuotes = this.order.quotes.filter(quote => quote.response_received !== 'price given')

      return this.order.readyQuotes.concat(otherQuotes)
    }
  },
  watch: {
    $route() {
      this.quotesDisplay = this.$route.query.quote ? 1 : 0
    }
  },
  methods: {
    toggleQuotesDisplay(value) {
      if (value) {
        this.$router.push({ ...this.$route, query: { quote: this.order.quotes[0].id } })
      }
      else {
        this.$router.push({ ...this.$route, query: {} })
      }
      this.quotesDisplay = value
    },
    viewQuote(quote) {
      this.quotesDisplay = 1
      this.$refs.quotesCarousel.viewQuote(quote)
      this.$router.push({ ...this.$route, query: { quote: quote.id } })
    }
  }
}
</script>

<style>
.quotes-display-toggle {
  border: 1px solid rgb(var(--v-theme-interactive));
}
.quotes-display-toggle .v-item--active {
  background-color: rgb(var(--v-theme-interactive)) !important;
}
.quotes-display-toggle .v-item--active .v-icon {
  color: white !important;
}

</style>
