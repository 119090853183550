<template>
  <div>
    <div v-if="open" class="order-btn">
      <v-btn color="white" variant="text" @click="close">
        VIEW ORDER
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <v-dialog
      v-model="open"
      content-class="d-flex flex-row split-dialog"
      :max-width="maxWidth"
      scrollable
    >
      <template v-slot:activator="{ attrs, on }">
        <slot name="activator" :attrs="attrs" :on="on" />
      </template>
      <attachment-viewer
        v-if="attachment"
        class="attachment-viewer mr-8"
        :name="attachment.name"
        :src="attachment.url"
        @close="attachment = undefined"
      />
      <purchase-order-card
        v-if="viewPo"
        class="mr-8"
        :invoices="invoices"
        :order="order"
        :po="po"
        @close="viewPo = false"
      />
      <invoice-card
        v-if="invoice"
        :can-approve="canApprove"
        :cost-codes="costCodes"
        :invoice="invoice"
        :order="order"
        :po="po"
        @approved="invoiceApproved"
        @close="close"
        @po-click="openPo"
        @preview="previewAttachment"
      >
        <template v-slot:actions-prepend>
          <qtm-btn v-if="!order.requiresInvoiceApproval && !viewPo" class="mr-3" secondary @click="openPo">
            View PO
          </qtm-btn>
        </template>
      </invoice-card>
      <reconciliator-snackbar
        v-if="invoice"
        :can-approve="canApprove"
        :collapsed="!viewPo"
        :current-invoice="invoice"
        :invoices="invoices"
        :order="order"
        :po="po"
      />
    </v-dialog>
  </div>
</template>

<script>
import AttachmentViewer from '@/components/attachments/attachment-viewer'
import FetchCostCodesMixin from '@/mixins/fetch-cost-codes-mixin'
import InvoiceCard from '@/components/invoices/invoice-card'
import PurchaseOrderCard from '@/components/purchase-orders/purchase-order-card'
import ReconciliatorSnackbar from '@/components/invoices/reconciliator-snackbar'

export default {
  name: 'invoice-dialog',
  components: {
    AttachmentViewer,
    InvoiceCard,
    PurchaseOrderCard,
    ReconciliatorSnackbar,
  },
  mixins: [FetchCostCodesMixin],
  props: {
    canApprove: {
      type: Boolean,
      default: false,
    },
    invoices: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      required: true,
    },
    po: {
      type: Object,
      required: true,
    },
  },
  emits: ['approved'],
  data() {
    return {
      attachment: undefined,
      viewPo: false,
    }
  },
  computed: {
    freightCostCodeRequired() {
      return !this.revision.freight_cost_code && (
        Number(this.revision.delivery_charge) || Number(this.revision.pickup_charge)
      )
    },
    invoice() {
      if (!this.$route.query.invoice) {
        return undefined
      }

      return this.invoices.find(invoice => invoice.id === Number(this.$route.query.invoice))
    },
    open: {
      get() {
        return !!this.invoice
      },
      set(value) {
        this.$router.push({ query: { ...this.$route.query, invoice: value || undefined } })
      },
    },
    maxWidth() {
      let width = 8.5

      if (this.attachment || this.viewPo) {
        width *= 2
      }

      return `${width}in`
    },
  },
  watch: {
    invoice() {
      this.attachment = undefined
    }
  },
  methods: {
    close() {
      this.open = false
      this.attachment = undefined
      this.viewPo = false
    },
    invoiceApproved(order) {
      this.$emit('approved', order)
      this.close()
    },
    previewAttachment(attachment) {
      this.viewPo = false
      this.attachment = attachment
    },
    openPo() {
      this.attachment = undefined
      this.viewPo = true
    },
  }
}
</script>

<style lang="scss" scoped>
.attachment-viewer {
  max-width: 8.5in;
}

.order-btn {
  position: fixed;
  top: 0;
  right: 0;
  text-align: right;
  z-index: 10000;
}
</style>

<style lang="scss">
.split-dialog {
  box-shadow: none !important;
  height: 90vh;
}
</style>
