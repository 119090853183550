<template>
  <div
    class="d-flex align-center flex-grow-1 justify-center text-primary qtm-h1"
    v-text="status"
  />
</template>

<script>
export default {
  name: 'quote-response',
  props: {
    response: {
      type: String,
      default: ''
    }
  },
  computed: {
    status() {
      if (this.response === 'declined') {
        return 'DECLINED TO QUOTE'
      }
      if (this.response === 'price_given') {
        return 'QUOTE RECEIVED'
      }
      return 'WAITING FOR QUOTE'
    },
  }
}
</script>
