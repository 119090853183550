<template>
  <div>
    <order-cart
      ref="orderCart"
      v-model:delivery-charge="quote.delivery_charge"
      v-model:items="quote.skus"
      v-model:pickup-charge="quote.pickup_charge"
      delivery-required
      order-by="sort_order"
      total-price-helper
      :taxes="taxes"
      :units="units"
      :validate="priceRequired"
      validate-units
      :with-cost-codes="false"
      with-prices
    />
    <v-row align="center" class="mt-3" justify="end">
      <v-col class="text-dark-grey text-right">
        <order-total-price-estimate
          :delivery-charge="quote.delivery_charge"
          :delivery-required="order.delivery_required"
          :freight-tax="order.freight_tax"
          :pickup-charge="quote.pickup_charge"
          :skus="quote.skus"
          :model-value="quote.total_price"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SKU_NAME_LENGTH } from '@/constants'
import OrderCart from '@/components/orders/order-cart'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate'

export default {
  name: 'quote-product-information',
  components: { OrderCart, OrderTotalPriceEstimate },
  props: {
    // Required for units
    order: {
      type: Object,
      required: true
    },
    quote: {
      type: Object,
      required: true
    },
    skuNameLength: {
      type: Number,
      default: SKU_NAME_LENGTH,
    },
    taxes: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      customSku: '',
    }
  },
  computed: {
    priceRequired() {
      return this.quote.response_received === 'price given'
    },
    showFreightFields() {
      return this.quote.delivery_available
    },
  },
  methods: {
    isValid() {
      return this.$refs.orderCart.isValid()
    },
  }
}
</script>
