<template>
  <qtm-text-field
    v-maska:[options]
    v-bind="$attrs"
  />
</template>

<script>
import { vMaska } from 'maska'

export default {
  name: 'postal-code-input',
  directives: { maska: vMaska },
  props: {
    country: {
      type: String,
      required: true
    },
  },
  computed: {
    isCanada() {
      return this.country === 'CA'
    },
    options() {
      return {
        mask: this.postalCodeMask,
        postProcess: (value) => value?.toUpperCase(),
      }
    },
    postalCodeMask() {
      if (this.isCanada) {
        return '@#@ #@#'
      }
      return '##########'
    },
  },
}
</script>
