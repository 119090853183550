<template>
  <div>
    <div class="d-flex align-center justify-space-between qtm-h1 text-uppercase text-white" :class="titleClass">
      <slot name="title">
        {{ title }}
      </slot>
      <qtm-icon-btn
        :color="buttonColor"
        :disabled="disabled"
        :icon="expandIcon"
        size="large"
        @click.stop="expanded = !expanded"
      />
    </div>
    <v-expand-transition>
      <div v-show="expanded">
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'expansion-block',
  props: {
    buttonColor: {
      type: String,
      default: 'light-teal'
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'mid-grey'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    paddingLeft: {
      type: String,
      default: '6'
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expanded: !this.collapsed
    }
  },
  computed: {
    expandIcon() {
      return this.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    titleClass() {
      return `bg-${this.color} pl-${this.paddingLeft}`
    },
  },
  methods: {
    collapse() {
      this.expanded = false
    },
    expand() {
      this.expanded = true
    },
  }
}
</script>
