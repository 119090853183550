<template>
  <v-dialog
    v-bind="$attrs"
    fullscreen
    hide-overlay
    no-click-animation
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card v-if="quote" class="non-scrollable-container">
      <v-toolbar color="secondary" dark>
        <v-btn dark icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ quote.vendor.name }}</v-toolbar-title>
      </v-toolbar>

      <div class="d-flex non-scrollable-container">
        <div v-if="showAttachment" class="attachmet-viewer">
          <attachment-viewer
            ref="pdfViewer"
            class="pa-2 mr-2"
            select-text-layer
            :src="documentToPreviewSrc"
            :name="documentToPreviewName"
            @close="showAttachment = false"
          />
        </div>
        <project-information v-else class="project-information" :order="order" />
        <v-card-text class="scrollable-container">
          <quote-form
            ref="quoteForm"
            class="quote-form"
            :quote="quote"
            :order="order"
            @preview="showDocumentPreview($event)"
          >
            <template v-slot:submit-btn>
              <v-row class="pa-5" justify="end">
                <qtm-btn :loading="loading" tertiary size="x-large" @click="$emit('cancel')">
                  Cancel
                </qtm-btn>
                <qtm-btn :loading="loading" size="x-large" @click="saveAndClose">
                  Save and Close
                </qtm-btn>
              </v-row>
            </template>
          </quote-form>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AttachmentViewer from '@/components/attachments/attachment-viewer'
import ProjectInformation from '@/components/admin/project-information'
import QuoteForm from '@/components/quotes/quote-form'

export default {
  name: 'enter-quote-form',
  components: { AttachmentViewer, ProjectInformation, QuoteForm },
  props: {
    quote: {
      type: Object,
      default: undefined
    },
    order: {
      type: Object,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  emits: ['cancel', 'submit'],
  data() {
    return {
      documentToPreviewSrc: null,
      documentToPreviewName: null,
      showAttachment: false,
    }
  },
  methods: {
    saveAndClose() {
      if (!this.$refs.quoteForm.isValid()) {
        this.$toast.error('Please correct the errors')
      }
      else {
        this.$emit('submit')
      }
    },
    showDocumentPreview(attachment) {
      this.documentToPreviewName = attachment.name
      this.documentToPreviewSrc = attachment.url
      this.showAttachment = true
    },
  }
}
</script>

<style lang="scss" scoped>
.attachmet-viewer {
  flex-shrink: 0;
  margin-top: 30px;
  overflow-y: auto;
  width:48%;
}

.non-scrollable-container {
  height: 100%;
  overflow-y: hidden;
}

.project-information {
  flex-shrink: 0;
  margin: 1rem 1rem 0 0;
  max-height: calc(100vh - 5rem);
  max-width: 20rem;
  overflow-y: auto;
  padding: 5px 10px;
}

.quote-form {
  max-width: calc(100vw - 22rem);
  overflow-y: auto;
}

.scrollable-container {
  overflow-y: auto;
}
</style>
