<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <qtm-input-label label="Invoice number" required>
          <qtm-text-field
            v-model="v$.invoice.invoice_number.$model"
            :error-messages="errors.invoice.invoice_number"
            hide-details="auto"
            style="max-width: 300px"
            @blur="checkForDuplicates"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="4">
        <qtm-input-label label="Issue date" required>
          <date-picker
            v-model="v$.invoice.date_issued.$model"
            clearable
            hide-details="auto"
            placeholder="Select date"
            :error-messages="errors.invoice.date_issued"
            :menu-props="{ closeOnContentClick: true }"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="4">
        <qtm-input-label label="Due date">
          <date-picker
            v-model="invoice.date_due"
            clearable
            hide-details="auto"
            placeholder="Select date"
            :menu-props="{ closeOnContentClick: true }"
          />
        </qtm-input-label>
      </v-col>
      <v-col cols="6">
        <order-reference-duplicates
          v-if="invoice.vendor"
          ref="duplicates"
          :order-id="order.id"
          :supplier-id="invoice.vendor.id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import DatePicker from '@/components/date-picker'
import OrderReferenceDuplicates from '@/components/orders/order-reference-duplicates'
import ValidationErrorsMixin from '@/mixins/validation-errors-mixin'

export default {
  name: 'invoice-information',
  components: { DatePicker, OrderReferenceDuplicates },
  mixins: [ValidationErrorsMixin],
  props: {
    invoice: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    invoice: {
      invoice_number: { required },
      date_issued: { required },
    },
  },
  methods: {
    checkForDuplicates() {
      this.$refs.duplicates?.checkForDuplicates(this.invoice.invoice_number)
    },
  }
}
</script>
