<template>
  <div class="pb-5 qtm-content-block" :class="{ 'qtm-border': !flat }" :style="style">
    <div
      class="d-flex justify-space-between align-start pr-2"
      :class="!expanded || (!title && !$slots.title) ? 'mb-0' : 'mb-4'"
    >
      <div class="d-flex align-center pt-3">
        <slot name="title">
          <div v-if="title" class="qtm-h1 text-secondary text-uppercase" :class="`pl-${padding}`" v-text="title" />
        </slot>
        <slot name="title:append" />
        <qtm-icon-btn
          v-if="to"
          :data-test="dataTest"
          icon="mid-pencil"
          size="small"
          :to="to"
        />
      </div>
      <div class="d-flex align-center pt-2">
        <slot v-if="expanded" name="top-right" />
        <qtm-icon-btn
          v-if="collapsible"
          class="ml-2"
          density="compact"
          :icon="collapseIcon"
          size="x-large"
          @click="expanded = !expanded"
        />
      </div>
    </div>
    <v-expand-transition>
      <div v-show="expanded" :class="contentClass">
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'qtm-content-block',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    dataTest: {
      type: String,
      default: undefined
    },
    divided: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    padding: {
      type: [Number, String],
      default: 10
    },
    maxHeight: {
      type: [Number, String],
      default: undefined
    },
    title: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: undefined
    },
  },
  data() {
    return {
      expanded: !this.collapsed
    }
  },
  computed: {
    contentClass() {
      const contentClass = this.divided ? 'divided' : ''

      return `${contentClass} px-${this.padding}`
    },
    collapseIcon() {
      return this.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    style() {
      return this.maxHeight ? `max-height: ${this.maxHeight}px; overflow-y: scroll;` : undefined
    },
  }
}
</script>

<style lang="scss" scoped>
$scrollBarWidth: 4px;

.qtm-content-block {
  background-color: white;
  overflow-wrap: anywhere;
  scrollbar-color: rgb(var(--v-theme-interactive)) rgb(var(--v-theme-background));
  scrollbar-width: thin;
  word-break: normal;
}

.divided > :not(:first-child)::before {
  background: rgb(var(--v-theme-light-grey));
  content: "";
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 1px;
}

.qtm-content-block::-webkit-scrollbar {
  background-color: rgb(var(--v-theme-background));
  width: $scrollBarWidth;
}
.qtm-content-block::-webkit-scrollbar-thumb {
  background-color: rgb(var(--v-theme-interactive));
  height: 60px;
  width: $scrollBarWidth;
}
</style>
