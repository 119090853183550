<template>
  <v-btn v-bind="$attrs" :color="color" :density="density" :icon="icon" :variant="variant" />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'interactive'
    },
    density: {
      type: String,
      default: 'comfortable'
    },
    icon: {
      type: [Boolean, String],
      default: true
    },
    variant: {
      type: String,
      default: 'text'
    },
  },
}
</script>
