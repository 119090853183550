<template>
  <qtm-icon-btn
    icon="mdi-brain"
    :loading="loading"
    title="Analyze"
    @click.prevent="analyze"
  />
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { QUANTITY_DECIMAL_PLACES, UNIT_PRICE_DECIMAL_PLACES } from '@/constants'

export default {
  name: 'attachment-analyzer',
  props: {
    attachment: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    },
    supplierReference: {
      type: String,
      default: ''
    },
    units: {
      type: Array,
      required: true
    },
  },
  emits: ['analyzing', 'update:supplier-reference', 'update:items'],
  data() {
    return {
      jobId: null,
      loading: false,
    }
  },
  computed: {
    parseTaxes() {
      return this.company?.country === 'US' && this.company.accounting_integration?.type === 'procore'
    },
    unit() {
      return this.units.find(unit => unit.is_default)?.code || 'Each'
    },
  },
  methods: {
    async analyze() {
      this.$emit('analyzing', this.attachment)

      this.loading = true
      try {
        const results = await this.$api.v1.attachments.analyze(this.attachment.id)

        this.parseResults(results)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    parseResults(results) {
      const items = []

      results.ExpenseDocuments?.forEach(doc => {
        doc.LineItemGroups?.forEach(group => {
          group.LineItems?.forEach(item => {
            const quoteItem = {
              id: uuidv4(),
              boundingBox: {},
              confidence: {},
              cost_code: '',
              description: '',
              quantity: 1,
              rental_duration: null,
              rental_duration_unit: null,
              unit: this.unit,
              unit_price: '',
            }
            let total

            item.LineItemExpenseFields?.forEach(field => {
              if (!field.ValueDetection?.Text || !field.Type.Text) {
                return
              }

              const confidence = field.ValueDetection?.Confidence?.toFixed(1)
              const boundingBox = field.ValueDetection?.Geometry?.BoundingBox

              if (field.Type.Text === 'ITEM') {
                quoteItem.description = field.ValueDetection.Text
                quoteItem.confidence.description = confidence
                quoteItem.boundingBox.description = boundingBox
              }
              else if (field.Type.Text === 'QUANTITY') {
                const quantity = field.ValueDetection.Text.replace(/[^0-9.]/g, '')
                const floatQuantity = Number.parseFloat(quantity)

                if (!Number.isNaN(floatQuantity)) {
                  const intQuantity = Number.parseInt(quantity, 10)
                  const decimals = intQuantity === floatQuantity ? 0 : QUANTITY_DECIMAL_PLACES

                  quoteItem.quantity = floatQuantity.toFixed(decimals)
                  quoteItem.confidence.quantity = confidence
                  quoteItem.boundingBox.quantity = boundingBox
                }
              }
              else if (field.Type.Text === 'PRICE') {
                total = field.ValueDetection.Text.replace(/[^0-9.]/g, '')
                quoteItem.confidence.price = confidence
                quoteItem.boundingBox.price = boundingBox
              }
            })

            if (quoteItem.quantity && total) {
              const unitPrice = total / quoteItem.quantity

              if (Number.isFinite(unitPrice)) {
                quoteItem.unit_price = unitPrice.toFixed(UNIT_PRICE_DECIMAL_PLACES)
              }
            }

            if (quoteItem.description) {
              quoteItem.sort_order = items.length
              items.push(quoteItem)
            }
          })
        })

        doc.SummaryFields?.forEach(field => {
          const quoteItem = {
            id: uuidv4(),
            boundingBox: {},
            confidence: {},
            cost_code: '',
            description: '',
            quantity: 1,
            rental_duration: null,
            rental_duration_unit: null,
            unit: this.unit,
            unit_price: '',
          }

          if (!field.ValueDetection?.Text || !field.Type.Text) {
            return
          }
          if (this.parseTaxes) {
            if ((field.Type?.Text === 'TAX')) {
              quoteItem.description = field.LabelDetection.Text
              quoteItem.confidence.description = field.LabelDetection?.Confidence?.toFixed(1)
              quoteItem.boundingBox.description = field.LabelDetection?.Geometry?.BoundingBox

              quoteItem.unit_price = field.ValueDetection.Text.replace(/[^0-9.]/g, '')
              quoteItem.confidence.price = field.ValueDetection?.Confidence?.toFixed(1)
              quoteItem.boundingBox.price = field.ValueDetection?.Geometry?.BoundingBox
            }

            if (quoteItem.description) {
              items.push(quoteItem)
            }
          }

          if (field.Type?.Text === 'INVOICE_RECEIPT_ID') {
            this.$emit('update:supplier-reference', field.ValueDetection.Text)
          }
        })
      })

      this.$emit('update:items', this.items.concat(items))
    },
  }
}
</script>
