<template>
  <qtm-dialog-card
    title="Resolve Problem"
    :model-value="true"
    width="600px"
    @updaet:model-value="$emit('update:model-value', $event)"
  >
    <v-row>
      <v-col class="text-mid-grey">
        {{ problem.message }}
      </v-col>
    </v-row>

    <div v-if="problem.problem_type === 'external'">
      <v-row>
        <v-col class="text-mid-grey">
          Please make this change in <span class="text-capitalize">{{ integrationType }}</span>
          then click Resolve.
        </v-col>
      </v-row>
    </div>

    <div v-if="hasResolutionChoices">
      <v-row>
        <v-col class="text-mid-grey">
          Please select a <span class="text-capitalize">{{ integrationType }}</span>
          {{ subjectType }} below and click Resolve to correct the mapping.
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <qtm-text-field
            v-model="search"
            class="mb-2"
            clearable
            hide-details
            :loading="loadingChoices"
            placeholder=""
            prepend-inner-icon="mdi-magnify"
          />

          <div
            v-if="!resolutionChoices.length"
            class="text-center"
          >
            No results
          </div>
          <qtm-radio-group
            v-else
            v-model="selection"
            class="pt-0 white"
            hide-details
            item-label="name"
            item-value="id"
            :items="resolutionChoices"
          >
            <qtm-radio
              v-for="choice in resolutionChoices"
              :key="choice.id"
              class="qtm-border pa-3 stacked-radio"
              :value="choice.id"
            >
              <template v-slot:label>
                <div class="text-secondary">
                  <div class="font-weight-bold" v-text="choice.name" />
                  <div v-if="choice.address" v-text="choice.address" />
                </div>
              </template>
            </qtm-radio>
          </qtm-radio-group>
          <qtm-pagination
            v-model="page"
            class="mt-4"
            :count="totalChoices"
            :per-page="pageSize"
          />
        </v-col>
      </v-row>
    </div>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary size="x-large" @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn
        primary
        size="large"
        :loading="loading"
        :disabled="hasResolutionChoices && !selection"
        @click="resolve"
      >
        Resolve
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: 'resolve-config-problem-dialog',
  props: {
    problem: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['resolved', 'update:model-value'],
  data() {
    return {
      loading: false,
      loadingChoices: false,
      resolutionChoices: [],
      totalChoices: 0,
      pageSize: 10,
      page: 1,
      selection: undefined,
      search: '',
    }
  },
  computed: {
    ...mapState('auth', ['user']),

    integrationType() {
      return this.user.company.accounting_integration?.type
    },

    subjectType() {
      switch (this.problem.subject_type) {
        case 'jobsite':
          return 'project'
        case 'vendor':
          return 'supplier'
        default:
          return this.problem.subject_type
      }
    },

    hasResolutionChoices() {
      if (!this.problem) {
        return false
      }
      return this.problem.problem_type !== 'external'
    }
  },
  watch: {
    page() {
      this.selection = undefined
      this.fetchResolutionChoices()
    },
    search() {
      this.page = 1
      this.selection = undefined
      this.fetchResolutionChoices()
    }
  },
  created() {
    this.fetchResolutionChoices = debounce(this.fetchResolutionChoicesNow.bind(this), 350)
  },
  mounted() {
    if (this.hasResolutionChoices) {
      this.fetchResolutionChoicesNow()
    }
  },
  methods: {
    async resolve() {
      this.loading = true
      try {
        let data
        if (this.selection) {
          data = { accounting_id: this.selection }
        }

        await this.$api.v1.accountingConfigProblems.resolve(this.problem.id, data)
        this.$emit('update:model-value', false)
        this.$emit('resolved', this.problem)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },

    async fetchResolutionChoicesNow() {
      this.loadingChoices = true
      try {
        const response = await this.$api.v1.accountingConfigProblems.listResources(this.problem.id, {
          search: this.search,
          pagination: {
            limit: this.pageSize,
            offset: (this.page - 1) * this.pageSize,
          }
        })
        this.resolutionChoices = response.data
        this.totalChoices = response.count
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loadingChoices = false
    }
  }
}
</script>

<style lang="scss" scoped>
.stacked-radio {
  margin-bottom: -1px !important;
}
</style>
