<script>
export default {
  name: 'fetch-cost-codes-mixin',
  data() {
    return {
      costCodes: []
    }
  },
  computed: {
    jobsiteId() {
      if (this.jobsite && this.jobsite.id) {
        return this.jobsite.id
      }

      if (this.order && this.order.jobsite && this.order.jobsite.id) {
        return this.order.jobsite.id
      }

      if (this.po && this.po.jobsite && this.po.jobsite.id) {
        return this.po.jobsite.id
      }

      return undefined
    }
  },
  watch: {
    jobsiteId() {
      this.fetchCostCodes()
    }
  },
  mounted() {
    this.fetchCostCodes()
  },
  methods: {
    async fetchCostCodes() {
      if (!this.jobsiteId) {
        this.costCodes = []
        return
      }
      try {
        this.costCodes = await this.$api.v1.jobsites.costCodes(this.jobsiteId)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
  }
}
</script>
